import React, { useContext, useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Context as AuthContext } from "./Context/AuthContext";
import { AuthProvider } from "./Context/AuthContext";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./Pages/Home/Home";
import Sobre from "./Pages/Sobre/Sobre";
import Produtos from "./Pages/Produtos/Produtos";
import Revendedor from "./Pages/Revendedor/Revendedor";
import LoginPage from "./Pages/Login/LoginPage";
import Cadastropage from "./Pages/Cadastro/Cadastropage";
//import Checkout from "./components/Checkout";
import Produto from "./Pages/Produto/Produto";
import Loading from "./components/Loading";
import Pefilpage from "./Pages/Perfil/Pefilpage";
import EmailVerificationAlert from "./components/EmailAlert";
import Checkout from "./components/Checkout/Checkout";
import ResetPassword from "./components/ResetPassword";
import AvisoLegal from "./Pages/Rodape/AvisoLegal";
import ComoRealizarSuaCompra from "./Pages/Rodape/ComoRealizarSuaCompra";
import PerguntasFrequentes from "./Pages/Rodape/PerguntasFrequentes";
import SiteSeguro from "./Pages/Rodape/SiteSeguro";
import PoliticaDeFrete from "./Pages/Rodape/PoliticaDeFrete";
import PoliticaDeCookies from "./Pages/Rodape/PoliticaDeCookies";
import PoliticaDePrivacidade from "./Pages/Rodape/PoliticaDePrivacidade";

function ProtectedRoute({ children }) {
  const { authenticated, loading } = useContext(AuthContext);

  if (loading) {
    // You can render a loading indicator here while checking authentication
    return <Loading />;
  }

  if (!authenticated) {
    // Save the intended location in local storage or state
    localStorage.setItem("intendedLocation", window.location.pathname);

    // Redirect them to the /login page
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected route
  return children;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/aviso-legal", element: <AvisoLegal /> },
      { path: "/como-realizar-sua-compra", element: <ComoRealizarSuaCompra /> },
      { path: "/perguntas-frequentes", element: <PerguntasFrequentes /> },
      { path: "/site-seguro", element: <SiteSeguro /> },
      { path: "/politica-de-frete", element: <PoliticaDeFrete /> },
      { path: "/politica-de-cookies", element: <PoliticaDeCookies /> },
      { path: "/politica-de-privacidade", element: <PoliticaDePrivacidade /> },
      {
        path: "produtos",
        element: <Produtos />,
        children: [{ path: ":id", element: <Produtos /> }],
      },
      { path: "produto/:name", element: <Produto /> },
      {
        path: "sobre",
        element: <Sobre />,
      },
      {
        path: "revendedor",
        element: <Revendedor />,
      },
      {
        path: "conta",
        element: (
          <ProtectedRoute>
            <Pefilpage />
          </ProtectedRoute>
        ),
      },
      {
        path: "checkout",
        element: (
          <ProtectedRoute>
            <Checkout />
          </ProtectedRoute>
        ),
      },
    ],
  },

  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/cadastro",
    element: <Cadastropage />,
  },
  { path: "/validar-email", element: <EmailVerificationAlert /> },
  {
    path: "*",
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
