import React from "react";
import "./Footer.css";
import InFooter from "./InFooter";
import { Link } from "react-router-dom";
import FooterItem from "./Components/FooterItem";
import { MenuItem } from "../../data/FooterItems";
import logo from "../../assets/logo.png";
import apps from "../../assets/apps.jpg";
import social from "../../assets/social.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const Footer = () => {
  const dataMenu = MenuItem;
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo justify-center items-center flex flex-col">
            <Link to="/">
              <img src={logo} alt="logo" className="w-full h-24 self-center" />
            </Link>
            <div className="flex items-center mt-4">
              {/* Link para o Instagram */}
              <Link
                to="https://www.instagram.com/zpcosmeticos/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <InstagramIcon />
              </Link>
              {/* Link para o Facebook */}
              <Link
                to="https://www.facebook.com/zpcosmeticos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
            </div>
          </div>
          <div className="footer-menu">
            <ul className="footer-menu-container">
              <FooterItem data={dataMenu} />
            </ul>
          </div>
        </div>
      </div>
      <InFooter />
    </footer>
  );
};

export default Footer;
