import React, { useState, useEffect, useContext } from "react";
import api from "../api/api";
import Loading from "./Loading";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import logo from "../assets/logo.png";
import Endereço from "./Card/Profile/Endereço/Endereço";
import Noticias from "./Card/Profile/Noticias/Noticias";
import Orders from "./Perfil/OrderList";
import { Context as AuthContext } from "../Context/AuthContext";
import GeneralContext from "../Context/ProductsContext";

const ProfilePage = () => {
  const { authenticated } = useContext(AuthContext);
  const [content, setContent] = useState("Compras");
  const { user, setUser } = useContext(GeneralContext);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (authenticated) {
      api.get("/me").then((res) => {
        setUser(res.data);
        setLoading(false);
      });
    }
  }, [authenticated]);

  useEffect(() => {
    if (window.innerWidth < 520) {
      setIsMobile(true);
    }
  }, []);

  const handleClick = (name) => {
    setContent(name);
  };

  const renderContent = () => {
    switch (content) {
      case "Endereços":
        if (user && user.billingAddress && user.shippingAddress) {
          return (
            <Endereço
              userId={user.id}
              billingAddress={user.billingAddress}
              shippingAddress={user.shippingAddress}
            />
          );
        }
        return <div>Nenhum endereço cadastrado!</div>;
      case "Compras":
        return <Orders user={user} isMobile={isMobile} />;
      default:
        return <Orders user={user} isMobile={isMobile} />;
    }
  };

  const UserInfo = ({ user }) => (
    <div className="flex justify-center mt-[10%] mb-[10%] w-1/4">
      <div className="flex flex-col">
        {/* This sets the user info to take up 30% of the screen width */}
        <div className="w-44 h-44 bg-gray-200 rounded-full mb-2 self-center flex items-center justify-center overflow-hidden">
          <img className="" src={logo} alt="Logo" />
        </div>
        <div className="text-base">
          <p className="font-bold text-lg">
            <PermIdentityOutlinedIcon className="mr-1" />
            {user.name}
          </p>
          <p>
            <MarkunreadOutlinedIcon className="mr-1" />
            {user.email}
          </p>
          <p>
            <PhoneAndroidOutlinedIcon className="mr-1" />
            {user.phone}
          </p>
        </div>
      </div>
    </div>
  );

  return loading ? (
    <Loading />
  ) : isMobile ? (
    <div className="flex flex-col h-full bg-gray-100 border mt-40">
      <div className="flex justify-center w-screen py-2">
        <div className="flex flex-col">
          {/* This sets the user info to take up 30% of the screen width */}
          <div className="w-44 h-44 bg-gray-200 rounded-full mb-2 self-center flex items-center justify-center overflow-hidden">
            <img className="" src={logo} alt="Logo" />
          </div>
          <div className="text-base">
            <p className="font-bold text-lg">
              <PermIdentityOutlinedIcon className="mr-1" />
              {user.name}
            </p>
            <p>
              <MarkunreadOutlinedIcon className="mr-1" />
              {user.email}
            </p>
            <p>
              <PhoneAndroidOutlinedIcon className="mr-1" />
              {user.phone}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <nav className="flex justify-center space-x-1 mt-[2%]">
          {["Endereços", "Compras"].map((item) => (
            <button
              key={item}
              name={item}
              className={`px-6 py-3 text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none ${
                content === item ? "border-b-2 border-indigo-500" : ""
              }`}
              onClick={() => handleClick(item)}
            >
              {item}
            </button>
          ))}
        </nav>
      </div>
      <div className="w-full min-h-full flex-2">{renderContent()}</div>
    </div>
  ) : (
    <div className="flex h-full bg-gray-100 border  xs:mt-40">
      {/* This creates a flex container for the entire page */}
      <UserInfo user={user} /> {/* This is the user info section on the left */}
      <div className="flex-1 w-3/4 p-5 bg-white border-l ">
        {" "}
        {/* This sets the content to take up the rest of the screen width */}
        <div className="mb-4">
          <nav className="flex justify-center space-x-1 mt-[2%]">
            {["Endereços", "Compras"].map((item) => (
              <button
                key={item}
                name={item}
                className={`px-6 py-3 text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none ${
                  content === item ? "border-b-2 border-indigo-500" : ""
                }`}
                onClick={() => handleClick(item)}
              >
                {item}
              </button>
            ))}
          </nav>
        </div>
        <div className="w-full min-h-full flex-2">{renderContent()}</div>
      </div>
    </div>
  );
};

export default ProfilePage;
