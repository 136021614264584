import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import GeneralContext from "../../Context/ProductsContext";
import Countdown from "./Countdown";
import api from "../../api/api";

const OrderSummary = ({ user, order, payment, step, setStep, cupom }) => {
  const redirectToInvoice = (invoiceUrl) => {
    window.open(invoiceUrl, "_blank");
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  return (
    <section className="flex justify-center w-full">
      <div className="w-full md:max-w-[60%] bg-white p-2 ">
        <div className="text-center">
          <h1 className="text-2xl font-semibold text-blue-600">
            Falta pouco! Seu pedido foi concluído.
          </h1>
          <p className="text-lg">
            O pagamento do seu pedido está sendo processado, você receberá via
            e-mail as atualizações no seu pedido.
          </p>
        </div>
        {order.paymentMethod === "PIX" && (
          <Countdown
            user={user}
            order={order}
            payment={payment}
            setStep={setStep}
            step={step}
          />
        )}
        {order.paymentMethod === "BANK_SLIP" && (
          <div className="text-center mt-10">
            <p className="font-bold text-2xl text-red mb-2">
              Vencimento:{" "}
              <span className="underline">
                {new Date(
                  Date.UTC(
                    parseInt(payment.bankSlip.dueDate.substring(0, 4)),
                    parseInt(payment.bankSlip.dueDate.substring(5, 7)) - 1,
                    parseInt(payment.bankSlip.dueDate.substring(8, 10))
                  )
                ).toLocaleDateString("pt-BR")}
              </span>
            </p>

            <button
              className="text-4xl text-blue-700 hover:underline"
              onClick={() => redirectToInvoice(payment.bankSlip.url)}
            >
              Visualizar Boleto
            </button>
          </div>
        )}
        <div className="mt-4">
          <div className="flex flex-col justify-between border-t border-gray-200 py-4">
            {order?.items?.map((item, index) => (
              <div
                key={item.id || index}
                className="flex flex-col md:flex-row items-center mb-4 bg-white border-b p-4"
              >
                {/* Envolve a imagem e os detalhes do item em um card com sombra e arredondado */}
                <img
                  src={item.imgUrl} // Substitua com o caminho da imagem do item ou imagem padrão
                  alt={item.name}
                  className="w-40 h-40 object-cover mr-4"
                />
                <div className="flex-grow">
                  {/* Flex-grow para que o conteúdo ocupe o espaço disponível */}
                  <h4>{item.name}</h4>
                  <p className="text-sm my-1">
                    {item.description || "Descrição do Item."}{" "}
                    {/* Use a descrição do item ou um texto padrão */}
                  </p>
                  <dl className="flex justify-start mt-2">
                    {/* Justify-start para alinhar os elementos à esquerda */}
                    <div className="text-sm ">Quantidade: {item.quantity}</div>
                    <span className="text-sm font-extralight text-gray-200 ml-4">
                      |
                    </span>
                    {/* Use a quantidade do item */}
                    <div className="text-sm  ml-4">
                      Preço: R${item.price.toFixed(2)}
                    </div>
                    {/* Use o preço do item e formate para duas casas decimais */}
                  </dl>
                </div>
              </div>
            ))}
          </div>

          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between items-center border-gray-200 pb-4">
            <div>
              <p className="font-medium">Endereço de Entrega</p>
              <h4>{order.user.name}</h4>
              <p className="text-sm">
                {order.orderShippingAddress.street},{" "}
                {order.orderShippingAddress.number}
                {order.orderShippingAddress.complement
                  ? `, ${order.orderShippingAddress.complement}`
                  : ""}
              </p>
              <p className="text-sm">
                {order.orderShippingAddress.neighborhood},{" "}
                {order.orderShippingAddress.city},{" "}
                {order.orderShippingAddress.state}
              </p>{" "}
              <p className="text-sm">
                {order.orderShippingAddress.country},{" "}
                {order.orderShippingAddress.zipCode}
              </p>{" "}
            </div>
            <div>
              <p className="font-medium">Endereço de Cobrança</p>
              <h4>{order.user.name}</h4>
              <p className="text-sm">
                {order.orderBillingAddress.street},{" "}
                {order.orderBillingAddress.number}
                {order.orderBillingAddress.complement
                  ? `, ${order.orderBillingAddress.complement}`
                  : ""}
              </p>
              <p className="text-sm">
                {order.orderBillingAddress.neighborhood},{" "}
                {order.orderBillingAddress.city},{" "}
                {order.orderBillingAddress.state}
              </p>{" "}
              <p className="text-sm">
                {order.orderBillingAddress.country},{" "}
                {order.orderBillingAddress.zipCode}
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row justify-between md:items-center border-t border-gray-200 py-4">
            <div>
              <p className="font-medium">Método de Pagamento</p>
              {order.paymentMethod === "CREDIT_CARD" && (
                <>
                  <p>Cartão de Crédito</p>
                  <p>
                    {payment.creditCard.creditCardBrand} ************
                    {payment.creditCard.creditCardNumber}
                  </p>
                  <p
                    className="text-sm text-blue-300 underline"
                    onClick={() => redirectToInvoice(payment.invoiceUrl)}
                  >
                    Visualizar fatura Nº {payment.invoiceNumber}
                  </p>
                </>
              )}
              {order.paymentMethod === "BANK_SLIP" && (
                <>
                  <p>Boleto</p>
                  <p>
                    Vencimento:{" "}
                    {new Date(payment.dueDate).toLocaleDateString("pt-BR")}
                  </p>
                  <button
                    className="text-base text-blue-300 underline"
                    onClick={() => redirectToInvoice(payment.bankSlipUrl)}
                  >
                    Visualizar Boleto
                  </button>
                </>
              )}
              {order.paymentMethod === "PIX" && (
                <>
                  <h3>Pix</h3>
                </>
              )}
            </div>
            <div>
              <p className="font-medium">Método de Entrega</p>
              <p>Correios</p>
              {order.shippingTime ? (
                <p className="text-sm">
                  {order.shippingTime} dias úteis após a postagem
                </p>
              ) : (
                <p className="text-sm">3-5 dias úteis após a postagem</p>
              )}
            </div>
          </div>

          <div className="border-t border-gray-200 py-4">
            <div className="flex justify-between">
              <p>Subtotal</p>
              <p>R${order.subTotal.toFixed(2)}</p>
            </div>
            {order.paymentMethod === "PIX" && (
              <div className="flex justify-between text-rose-400">
                <p>Pix 5%</p>
                <p>- R${((order.subTotal.toFixed(2) * 5) / 100).toFixed(2)}</p>
              </div>
            )}
            {order.discount > 0 && (
              <div className="flex justify-between text-rose-400">
                <p>Desconto</p>
                <p>- R${order.discount.toFixed(2)}</p>
              </div>
            )}
            <div className="flex justify-between">
              <p>Frete</p>
              <p>
                R$
                {order.shippingPrice === 0
                  ? "Frete Grátis"
                  : order.shippingPrice.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between font-bold">
              <p>Total</p>
              <p>R${order.total.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderSummary;
