import React from "react";
import { Link } from "react-router-dom";

const InFooter = () => {
  return (
    <section className="w-full flex flex-col md:flex-row justify-between items-center bg-gray-200 p-4">
      <div className="flex flex-col md:flex-row gap-2 mb-4 md:mb-0 md:max-w-fit text-center">
        <Link
          to="/termos-de-uso"
          target="_blank"
          className="no-underline text-brown-700 hover:underline"
        >
          <p className="text-xs md:text-sm">TERMOS DE USO</p>
        </Link>
        <Link
          to="/termos-de-privacidade"
          target="_blank"
          className="no-underline text-brown-700 hover:underline"
        >
          <p className="text-xs md:text-sm">POLÍTICA DE PRIVACIDADE</p>
        </Link>
      </div>
      <div className="mb-4 md:mb-0">
        <Link
          to="https://perpetualab.com.br"
          target="_blank"
          className="no-underline text-brown-700 hover:underline"
        >
          <p className="text-xs md:text-sm">POR PERPETUA LAB</p>
        </Link>
      </div>
      <div>
        <p className="text-xs md:text-sm">
          TODOS OS DIREITOS RESERVADOS ZP COSMÉTICOS
        </p>
      </div>
    </section>
  );
};

export default InFooter;
