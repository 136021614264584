import React from "react";
import { Typography, Container } from "@mui/material";
import "tailwindcss/tailwind.css";

const PoliticaDeCookies = () => {
  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <Container className=" p-8  max-w-2xl w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-4">
          POLÍTICA DE COOKIES
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Utilizamos cookies para tornar seu acesso personalizado de acordo com
          o seu gosto e suas escolhas e, com isso, garantir que a sua
          experiência em nossa loja seja a melhor possível.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          A presente Política tem como objetivo fornecer informações objetivas e
          claras sobre o que são cookies, qual papel desempenham e como
          configurá-los.
        </Typography>
        <Typography variant="h6" className="font-semibold mb-2">
          O que são Cookies?
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Cookies são pequenos arquivos de texto ou fragmentos de informação que
          são baixados em seu computador, smartphone ou qualquer outro
          dispositivo com acesso à internet quando você visita um site.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Eles contêm informações sobre a sua navegação em nossa loja e retêm
          apenas informações relacionadas a suas preferências.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Assim, essa página consegue armazenar e recuperar os dados sobre os
          seus hábitos de navegação, de forma a melhorar a experiência de uso,
          por exemplo.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          É importante frisar que eles não contêm informações pessoais
          específicas, como dados sensíveis ou bancários.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          O seu navegador armazena os cookies no disco rígido, mas ocupam um
          espaço de memória mínimo, que não afetam o desempenho do seu
          computador. A maioria das informações são apagadas logo ao encerrar a
          sessão, como você verá no próximo tópico.
        </Typography>
        <Typography variant="h6" className="font-semibold mb-2">
          Tipos de Cookies
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Os cookies, quanto a seus proprietários, podem ser:
        </Typography>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            Cookies proprietários: são cookies definidos por nós ou por
            terceiros em nosso nome
          </li>
          <li>
            Cookies de terceiros: são cookies definidos por terceiros confiáveis
            em nossa aplicação
          </li>
        </ul>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Os cookies, quanto ao seu tempo de vida, podem ser:
        </Typography>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            Cookies de sessão ou temporários: são cookies que expiram assim que
            você fecha o seu navegador, encerrando a sessão.
          </li>
          <li>
            Cookies persistentes ou permanentes: são cookies que permanecem no
            seu dispositivo durante um período determinado ou até que você os
            exclua.
          </li>
        </ul>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Os cookies, quanto a sua finalidade, podem ser:
        </Typography>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>
            Cookies necessários: são cookies essenciais que possibilitam a
            navegação em nossas aplicações e o acesso a todos os recursos; sem
            estes, nossos serviços podem apresentar mal desempenho ou não
            funcionar.
          </li>
          <li>
            Cookies de desempenho: são cookies que otimizam a forma que nossas
            aplicações funcionam, coletando informações anônimas sobre as
            páginas acessadas.
          </li>
          <li>
            Cookies de funcionalidade: são cookies que memorizam suas
            preferências e escolhas (como seu nome de usuário)
          </li>
          <li>
            Cookies de publicidade: são cookies que direcionam anúncios em
            função dos seus interesses e limitam a quantidade de vezes que o
            anúncio aparece.
          </li>
        </ul>
        <Typography variant="h6" className="font-semibold mb-2">
          Por que usamos Cookies?
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Através dos cookies, buscamos otimizar sua navegação em nossa página a
          partir de suas preferências e, com essas informações, ajustar o
          conteúdo para torná-lo mais relevante para você.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Os cookies participam deste processo porquanto armazenam, leem e
          executam os dados necessários para cumprir com o nosso objetivo.
        </Typography>
        <Typography variant="h6" className="font-semibold mb-2">
          Gerenciamento dos Cookies
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          A instalação dos cookies está sujeita ao seu consentimento. Apesar da
          maioria dos navegadores estar inicialmente configurada para aceitar
          cookies de forma automática, você pode rever suas permissões a
          qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar
          notificações para quando alguns cookies forem enviados ao seu
          dispositivo.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Atualmente, na primeira vez que você acessar nosso site, será
          solicitada a sua concordância com a instalação destes. Apenas após a
          sua aceitação eles serão ativados.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Para tanto, utilizamos um sistema de (banner de informações ou outro
          mecanismo que alerta e solicita o consentimento) em nossa página
          inicial. Dessa maneira, não apenas solicitamos sua concordância, mas
          também informamos que a navegação continuada em nosso site será
          entendida como consentimento.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar
          as permissões, bloquear ou recusar os cookies. Você também pode
          configurá-los caso a caso. Todavia, a revogação do consentimento de
          determinados cookies pode inviabilizar o funcionamento correto de
          alguns recursos da plataforma.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente
          nas configurações do navegador, na área de gestão de Cookies.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
        </Typography>
        <ul className="list-disc list-inside text-gray-700 mb-4">
          <li>Se você usa o Internet Explorer.</li>
          <li>Se você usa o Firefox.</li>
          <li>Se você usa o Safari.</li>
          <li>Se você usa o Google Chrome.</li>
          <li>Se você usa o Microsoft Edge.</li>
          <li>Se você usa o Opera.</li>
        </ul>
        <Typography variant="h6" className="font-semibold mb-2">
          Disposições finais
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Para nós, a privacidade e confiança são fundamentais para a nossa
          relação com você. Estamos sempre nos atualizando para manter os mais
          altos padrões de segurança.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Assim, reservamo-nos o direito de alterar esta Política de Cookies a
          qualquer tempo.
        </Typography>
      </Container>
    </div>
  );
};

export default PoliticaDeCookies;
