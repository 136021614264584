import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../api/api";

const Edit = ({
  address,
  userId,
  addressId,
  addressType,
  setWarning,
  handleOpenSnackbar,
  closeModal,
  isOpen,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: address });

  useEffect(() => {
    setValue("name", address.name);
    setValue("complement", address.complement);
    setValue("number", address.number.toString());
    setValue("street", address.street);
    setValue("neighborhood", address.neighborhood);
    setValue("city", address.city);
    setValue("state", address.state);
    setValue("country", address.country);
    setValue("zipCode", address.zipCode);
    setValue("userId", userId);
  }, [address]);

  const ZipCode = watch("zipCode");

  // Função para limpar o valor do CEP
  const cleanZipCode = (zipCode) => {
    return zipCode.replace("-", "");
  };

  // Function to handle form submission
  const onSubmitHandler = async (formData) => {
    const cleanedZipCode = cleanZipCode(formData.zipCode);
    try {
      if (addressType === "shipping") {
        api
          .post("/users/shippingAddresses", {
            ...formData,
            id: addressId,
            number: parseInt(formData.number, 10),
            zipCode: cleanedZipCode,
            userId: userId,
          })
          .then((response) => {
            if (response.status === 201) {
              setWarning({
                type: "success",
                message: "Endereço atualizado.",
              });
              handleOpenSnackbar();
              closeModal();
            } else {
              // For any other response, show an error message
              setWarning({
                type: "error",
                message:
                  "Não foi possivel atualizar o endereço de entrega no momento. Tente novamente mais tarde.",
              });
              handleOpenSnackbar();
            }
          });
      } else {
        api

          .post("/users/billingAddresses", {
            ...formData,
            id: addressId,
            number: parseInt(formData.number, 10),
            zipCode: cleanedZipCode,
            userId: userId,
          })
          .then((response) => {
            if (response.status === 201) {
              setWarning({
                type: "success",
                message: "Endereço atualizado.",
              });
              handleOpenSnackbar();
              closeModal();
            } else {
              // For any other response, show an error message
              setWarning({
                type: "error",
                message:
                  "Não foi possivel atualizar o endereço de cobrança no momento. Tente novamente mais tarde.",
              });
              handleOpenSnackbar();
            }
          });
      }
    } catch (error) {
      console.error("Failed to submit address:", error);
      // Handle submission error
      setWarning({
        type: "error",
        message: "Failed to submit address. Please try again.",
      });
      handleOpenSnackbar();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Editar de Endereço
                </Dialog.Title>
                <form
                  className="space-y-6 mt-6"
                  onSubmit={handleSubmit(onSubmitHandler)}
                >
                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                    {/* New input for Title */}
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="name"
                        className="block text-md text-gray-700 font-normal"
                      >
                        Título do endereço
                      </label>
                      <input
                        {...register("name")}
                        type="text"
                        name="name"
                        className="mt-1 border block w-full rounded-md shadow-sm p-3"
                        placeholder="Ex: Casa, Trabalho..."
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-center">
                        <div className="flex flex-col mx-auto">
                          <label
                            htmlFor="zipCode"
                            className="block text-md text-gray-700 font-normal"
                          >
                            CEP
                          </label>
                          <input
                            {...register("zipCode", {
                              required: "CEP é obrigatório",
                            })}
                            type="text"
                            name="zipCode"
                            className="mt-1 border block w-full rounded-md shadow-sm p-3"
                            placeholder="XXXXX-XXX"
                          />
                          {errors.zipCode && (
                            <p className="text-red-500">
                              {errors.zipCode.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Complemento */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="complement"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Complemento
                    </label>
                    <div>
                      <input
                        type="text"
                        name="complement"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("complement", {
                          required: "Complemento é obrigatório",
                        })}
                        placeholder="Apto 101, Casa A, Ao Lado do Mercado Bar"
                      />
                      {errors.complement && (
                        <p style={{ color: "red" }}>
                          {errors.complement.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Numero */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Numero
                    </label>
                    <div>
                      <input
                        type="text"
                        name="number"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("number", {
                          required: "Número do endereço é obrigatório",
                        })}
                        placeholder="123"
                      />
                      {errors.number && (
                        <p style={{ color: "red" }}>{errors.number.message}</p>
                      )}
                    </div>
                  </div>
                  {/* Rua */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="street"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Rua
                    </label>
                    <div>
                      <input
                        type="text"
                        name="street"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("street", {
                          required: "Complemento é obrigatório",
                        })}
                        placeholder="Rua da Sua Casa"
                      />
                      {errors.street && (
                        <p style={{ color: "red" }}>{errors.name.message}</p>
                      )}
                    </div>
                  </div>
                  {/* Bairro */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="neighborhood"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bairro
                    </label>
                    <div>
                      <input
                        type="text"
                        name="neighborhood"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("neighborhood", {
                          required: "Bairro é obrigatório",
                        })}
                        placeholder="Seu Bairro"
                      />
                      {errors.neighborhood && (
                        <p style={{ color: "red" }}>
                          {errors.neighborhood.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/*Cidade */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cidade
                    </label>
                    <div>
                      <input
                        type="text"
                        name="city"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("city", {
                          required: "Bairro é obrigatório",
                        })}
                        placeholder="Sua Cidade"
                      />
                      {errors.city && (
                        <p style={{ color: "red" }}>{errors.city.message}</p>
                      )}
                    </div>
                  </div>
                  {/* Estado */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Estado
                    </label>
                    <div>
                      <input
                        type="text"
                        name="state"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("state", {
                          required: "Bairro é obrigatório",
                        })}
                        placeholder="Seu Estado"
                      />
                      {errors.state && (
                        <p style={{ color: "red" }}>{errors.state.message}</p>
                      )}
                    </div>
                  </div>
                  {/* País */}
                  <div className="mb-3 row">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      País
                    </label>
                    <div>
                      <input
                        type="text"
                        name="country"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                        {...register("country", {
                          required: "Bairro é obrigatório",
                        })}
                        placeholder="Seu País"
                      />
                      {errors.country && (
                        <p style={{ color: "red" }}>{errors.country.message}</p>
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blackfocus:ring-offset-2"
                  >
                    Salvar Endereço
                  </button>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Edit;
