import React from "react";

const Stepper = ({ steps, currentStep, setStep }) => {
  // Função para lidar com a mudança de passo permitindo apenas para passos anteriores
  const handleStepChange = (index) => {
    // Verifica se o índice clicado é menor ou igual ao passo atual
    if (index <= currentStep) {
      setStep(index); // Atualiza o passo atual apenas se for um passo anterior
    }
  };

  return (
    <div className="flex justify-between items-center w-1/2 border-b pb-4 ">
      {steps.map((step, index) => (
        <div key={index} className="flex flex-col items-center ">
          {/* Ícone do passo */}
          <div
            onClick={() => handleStepChange(index)} // Adiciona o evento de clique
            className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-semibold 
                        ${
                          index <= currentStep
                            ? "bg-black text-white cursor-pointer"
                            : "bg-gray-200 text-gray-500 cursor-default"
                        }`}
          >
            {index + 1}
          </div>
          {/* Nome do passo */}
          <div
            onClick={() => handleStepChange(index)} // Adiciona o evento de clique
            className={`text-xs mt-2 ${
              index <= currentStep
                ? "text-black cursor-pointer"
                : "text-gray-500 cursor-default"
            }`}
          >
            {step}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
