import React, { useContext, useState } from "react";
import api from "../../api/api";
import GeneralContext from "../../Context/ProductsContext";

const Address = ({
  user,
  subtotal,
  order,
  setOrder,
  step,
  setStep,
  handleFrete,
}) => {
  const getTextClass = (index) => {
    return "text-gray-600";
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <p className="text-xl text-black my-4">
        Os valores do frete serão exibidos na próxima etapa.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 gap-4">
        {user && user.shippingAddress
          ? user.shippingAddress.map((endereco, index) => (
              <div
                key={index}
                className={`m-2 p-4 max-w-sm border-2 rounded-lg shadow-md transition-all duration-300 ease-in-out ${"border-gray-300"}`}
              >
                <div className="flex justify-between h-[20px] items-center mb-4">
                  <h3 className={`${getTextClass(index)} font-semibold`}>
                    {endereco.name ? endereco.name : `Endereço ${index + 1}`}
                  </h3>
                </div>
                <p className={getTextClass(index)}>
                  {endereco.street}, {endereco.number}
                  {endereco.complement ? `, ${endereco.complement}` : ""}
                </p>
                <p className={getTextClass(index)}>
                  {endereco.neighborhood}, {endereco.city}
                </p>
                <p className={getTextClass(index)}>
                  {endereco.state}, {endereco.country}
                </p>
                <p className={getTextClass(index)}>{endereco.zipCode}</p>
                <button
                  type="button"
                  className="flex items-center mt-2 justify-center rounded-3xl border border-transparent bg-black w-[200px] h-[40px] hover:bg-gray-900 px-6 py-1 text-base font-medium text-white shadow-sm"
                  onClick={() => {
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      shippingAddressId: user.shippingAddress[index]?.id,
                      billingAddressId: user.billingAddress?.id,
                    }));
                    handleFrete(endereco.zipCode);
                    setStep(step + 1);
                  }}
                >
                  Use este endereço
                </button>
              </div>
            ))
          : "Carregando..."}
      </div>
    </div>
  );
};

export default Address;
