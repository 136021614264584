import { useState, useEffect } from "react";
import api from "../../api/api";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Função para definir um cookie
  const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${expires}; path=/; SameSite=None; Secure`;
  };

  // Função para obter um cookie
  const getCookie = (name) => {
    return document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = getCookie("refreshToken");

    if (token) {
      const parsedToken = JSON.parse(token);
      api.defaults.headers.Authorization = `Bearer ${parsedToken}`;
      setAuthenticated(true);
    }

    if (!refreshToken && authenticated) {
      handleLogout();
    }

    setLoading(false);
  }, [authenticated]);

  const refreshToken = async () => {
    try {
      const { data } = await api.patch("/token/refresh");
      const updatedToken = data.token;

      // Armazenar o token de acesso no localStorage
      localStorage.setItem("token", JSON.stringify(updatedToken));

      // Armazenar o refresh token no cookie
      setCookie("refreshToken", updatedToken, 7); // Expira em 7 dias

      // Configurar o cabeçalho Authorization para futuras requisições
      api.defaults.headers.Authorization = `Bearer ${updatedToken}`;

      setAuthenticated(true);
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleLogout();
    }
  };

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.error("Response error:", error);
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        authenticated
      ) {
        originalRequest._retry = true;
        await refreshToken();

        return api(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  async function handleLogin(token, refreshToken) {
    localStorage.setItem("token", JSON.stringify(token));
    setCookie("refreshToken", refreshToken, 7); // Expira em 7 dias
    api.defaults.headers.Authorization = `Bearer ${token}`;
    setAuthenticated(true);
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    document.cookie =
      "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Remove o cookie
    api.defaults.headers.Authorization = undefined;
  }

  return { authenticated, loading, handleLogin, handleLogout };
}
