import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Paper,
  InputBase,
  IconButton,
  Box,
  List,
  ListItem,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { debounce } from "lodash";
import api from "../../../api/api";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useNavigate } from "react-router-dom";

const debouncedSearchFunction = (fetchProducts) =>
  debounce(
    (nextValue, nextPage = 0) => fetchProducts(nextValue, nextPage),
    500
  );

const SearchField = ({ filters, setFilters }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [page, setPage] = useState(0); // Estado para controlar a paginação
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref para o dropdown

  function formatUrl(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/-/g, " ")
      .replace(/\s+/g, "-")
      .trim()
      .toLowerCase();
  }

  const handleCategoryClick = (nameMod) => {
    setOpenDropdown(false);
    navigate(`/produto/${formatUrl(nameMod)}`);
  };

  const fetchProducts = useCallback((searchTerm, nextPage) => {
    api
      .get(`/products?name=${searchTerm}&page=${nextPage}`)
      .then((res) => {
        if (nextPage > 0) {
          // Atualiza os resultados evitando duplicatas
          setSearchResults((prevResults) => {
            const existingIds = new Set(
              prevResults.map((product) => product.id)
            );
            const newProducts = res.data.products.filter(
              (product) => !existingIds.has(product.id)
            );
            return [...prevResults, ...newProducts];
          });
        } else {
          setSearchResults(res.data.products);
        }
        if (res.data.products.length > 0) {
          setOpenDropdown(true);
        } else {
          setOpenDropdown(false);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the products:", error);
      });
  }, []); // Remove `searchResults` das dependências para evitar recriações desnecessárias

  // Use a função debouncedSearchFunction e passe fetchProducts como argumento
  const debouncedSearch = useMemo(
    () => debouncedSearchFunction(fetchProducts),
    [fetchProducts]
  );
  useEffect(() => {
    if (filters.name !== "") {
      debouncedSearch(filters.name);
    } else {
      setOpenDropdown(false);
      setSearchResults([]);
      setPage(0); // Reseta a paginação quando a busca é limpa
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [filters.name, debouncedSearch]);

  const handleSearchChange = (event) => {
    setFilters({ ...filters, name: event.target.value });
  };

  const handleClickAway = () => {
    setOpenDropdown(false);
  };

  const handleScroll = useCallback(
    (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      if (scrollHeight - scrollTop === clientHeight) {
        setPage((prevPage) => prevPage + 1);
        debouncedSearch(filters.name, page + 1);
      }
    },
    [debouncedSearch, filters.name, page]
  );

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const handleViewAllClick = () => {
    // Redireciona para a página de produtos com o filtro aplicado
    setOpenDropdown(false);
    navigate(`/produtos?search=${filters.name}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleViewAllClick();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="rounded-full bg-gray-300">
        <Paper
          sx={{
            display: "flex",
            position: "relative",
            boxShadow: "none",
            borderRadius: "9999px",
          }}
          component="div"
          className="w-[90vw] sm:w-96 md:w-72 lg:w-[600px] bg-gray-300"
        >
          <InputBase
            className="ml-1 flex-1 rounded-full text-black bg-gray-300 py-1 px-4"
            placeholder="O que você busca?"
            inputProps={{ "aria-label": "procurar produto" }}
            value={filters.name}
            onChange={handleSearchChange}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleKeyPress(e);
            }}
            onFocus={() => setOpenDropdown(true)}
          />
          <IconButton
            type="button"
            sx={{ marginLeft: "-50px" }}
            aria-label="search"
            onClick={handleViewAllClick}
          >
            <SearchIcon />
          </IconButton>
          {openDropdown && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                zIndex: 1,
                maxHeight: "300px",
                overflow: "auto",
              }}
              className="bg-white rounded-md shadow-lg mt-2"
              ref={dropdownRef}
            >
              <List component="nav" aria-label="search results">
                <ListItem button onClick={handleViewAllClick}>
                  Visualizar todos os produtos com "{filters.name}"
                </ListItem>
                {searchResults.map((product) => (
                  <ListItem
                    key={product.id}
                    button
                    className="hover:bg-blue-500"
                    onClick={() => handleCategoryClick(product.nameMod)}
                  >
                    {product.name}
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

export default SearchField;
