import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import ProductList from "./ProductList";
import api from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../Context/hooks/useAuth";
import Loading from "./Loading";
const children = [{ name: "" }];

const SideBar = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("category");
  const [prevCategoryId, setPrevCategoryId] = useState(categoryId);
  const type = queryParams.get("type");
  const searchQuery = queryParams.get("search"); // Nome do parâmetro de pesquisa na URL
  const [categories, setCategories] = useState([]);
  const [isCompany, setIsCompany] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [selectedCategoryIDs, setSelectedCategoryIDs] = useState(
    categoryId ? [categoryId] : []
  );
  const [selectedCount, setSelectedCount] = useState("25");
  const { authenticated } = useAuth();
  const navigate = useNavigate();

  const handleClearFilters = () => {
    setSelectedCategoryIDs([]);
  };

  useEffect(() => {
    setLoaded(false);
    let endpoint = "products?take=300";
    api
      .get(endpoint)
      .then((res) => {
        const products = res.data.products;

        products.sort((a, b) =>
          isCompany
            ? b.vendorPrice - a.vendorPrice
            : b.customerPrice - a.customerPrice
        );
        setAllProducts(products);
        setLoaded(true);
        scrollToTop();
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .get("/categories/nested")
      .then((res) => setCategories(res.data.categories))
      .catch((e) => console.log("Erro ao buscar categorias:", e));
    if (authenticated) {
      api
        .get("/me")
        .then((res) => setIsCompany(res.data.isCompany))
        .catch((e) => console.log("Erro ao verificar o tipo de usuário:", e));
    } else {
      setIsCompany(false);
    }
  }, [authenticated]);

  useEffect(() => {
    // Filtrar produtos com base nos IDs de categoria selecionados ou mostrar todos se nenhum estiver selecionado
    const filteredItems =
      selectedCategoryIDs.length === 0
        ? type
          ? allProducts.sort((a, b) => {
              // Ordenar produtos do tipo "KIT" antes dos outros produtos
              if (a.type === "KIT" && b.type !== "KIT") {
                return -1; // Coloca 'a' antes de 'b'
              } else if (a.type !== "KIT" && b.type === "KIT") {
                return 1; // Coloca 'b' antes de 'a'
              }
              // Se os tipos forem iguais ou nenhum for "KIT", ordenar por preço
              return isCompany
                ? b.vendorPrice - a.vendorPrice
                : b.customerPrice - a.customerPrice;
            })
          : allProducts.sort((a, b) =>
              isCompany
                ? b.vendorPrice - a.vendorPrice
                : b.customerPrice - a.customerPrice
            )
        : allProducts
            .sort((a, b) =>
              isCompany
                ? b.vendorPrice - a.vendorPrice
                : b.customerPrice - a.customerPrice
            )
            .filter((product) =>
              product.categoryIds.some((subcategory) =>
                selectedCategoryIDs.includes(subcategory)
              )
            );
    setItemsFiltered(filteredItems);
    setLoaded(true);
  }, [selectedCategoryIDs]);

  useEffect(() => {
    let endpoint = "products?take=300";
    setLoaded(false);
    // Adiciona o filtro de categoria se existir
    if (categoryId) {
      endpoint += `&categoryIds=${categoryId}`;
      setSelectedCategoryIDs([...selectedCategoryIDs, categoryId]);
    }

    if (categoryId !== prevCategoryId) {
      window.location.reload();
    }

    // Adiciona o filtro de pesquisa se existir
    if (searchQuery) {
      endpoint += `&name=${searchQuery}`;
    }

    // Adiciona o filtro do kit se existir
    if (type) {
      endpoint += `&type=${type}`;
    }

    api
      .get(endpoint)
      .then((res) => {
        const products = res.data.products;
        products.sort((a, b) =>
          isCompany
            ? b.vendorPrice - a.vendorPrice
            : b.customerPrice - a.customerPrice
        );
        setItemsFiltered(products);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
  }, [categoryId, searchQuery, type]);

  const verifyIds = (category) => {
    // Verifica se a categoria está presente nos selectedCategoryIDs
    if (selectedCategoryIDs.includes(category.id)) {
      return true;
    }

    // Verifica se alguma das subcategorias está presente nos selectedCategoryIDs
    if (category.children && category.children.length > 0) {
      for (const childCategory of category.children) {
        if (selectedCategoryIDs.includes(childCategory.id)) {
          return true;
        }
      }
    }

    // Se nenhum dos casos anteriores for verdadeiro, retorna falso
    return false;
  };

  const handleName = () => {
    const itensOrdenados = [...itemsFiltered].sort((a, b) =>
      a.name.localeCompare(b.name, "pt-BR")
    );
    setItemsFiltered(itensOrdenados);
  };

  const handlePrice = (order) => {
    const sortedItems = [...itemsFiltered].sort((a, b) =>
      order === "low"
        ? a.customerPrice - b.customerPrice
        : b.customerPrice - a.customerPrice
    );
    setItemsFiltered(sortedItems);
  };

  const handleSelect = (count) => {
    setSelectedCount(count);
  };

  const renderCategories = (categories) => {
    const handleAction = (actionType, categoryID, isChecked, e) => {
      if (actionType === "toggle") {
        setIsOpen(!isOpen);
      } else if (actionType === "checkbox") {
        if (isChecked) {
          setSelectedCategoryIDs([...selectedCategoryIDs, categoryID]);
          e.stopPropagation();
          e.preventDefault(); // Impede o comportamento padrão do evento
        } else {
          setSelectedCategoryIDs(
            selectedCategoryIDs.filter((id) => id !== categoryID)
          );
          e.stopPropagation();
          e.preventDefault(); // Impede o comportamento padrão do evento
        }
      }
    };

    // Função para ordenar as categorias alfabeticamente
    const sortByCategoryName = (a, b) => {
      return a.name.localeCompare(b.name, "pt-BR");
    };

    // Ordenar categorias alfabeticamente
    categories.sort(sortByCategoryName);

    // Renderizar as categorias
    return categories.map((category) => (
      <>
        {category.productIds &&
        category.productIds.length > 0 &&
        category.children.length === 0 ? (
          <div className="flex items-center">
            <input
              id={`category-${category.id}`}
              type="checkbox"
              className="h-4 w-4 rounded text-dark-blue border-gray-300 focus:ring-black"
              checked={selectedCategoryIDs.includes(category.id)}
              onChange={(e) =>
                handleAction("checkbox", category.id, e.target.checked, e)
              }
            />
            <label
              htmlFor={`category-${category.id}`}
              className="ml-3 text-sm text-gray-600 cursor-pointer"
            >
              {category.name}
            </label>
          </div>
        ) : (
          <Disclosure
            as="div"
            key={category.id}
            className="border-t border-gray-200 px-4 py-6"
            open={isOpen}
            defaultOpen={verifyIds(category)}
          >
            {({ open }) => (
              <>
                <h3 className="-mx-2 -my-3 flow-root">
                  <Disclosure.Button
                    className="flex w-full items-center text-left justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500"
                    onClick={(e) => handleAction("toggle", null, null, e)}
                  >
                    <span className="font-medium text-black">
                      {category.name}
                    </span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <MinusIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">
                  <div className="space-y-4">
                    {category.children &&
                    category.children.length > 0 &&
                    category.productIds.length === 0 ? (
                      renderCategories(category.children)
                    ) : category.productIds &&
                      category.productIds.length > 0 &&
                      category.children.length === 0 ? (
                      <div className="flex items-center">
                        <input
                          id={`category-${category.id}`}
                          type="checkbox"
                          className="h-4 w-4 rounded text-dark-blue border-gray-300 focus:ring-black"
                          checked={selectedCategoryIDs.includes(category.id)}
                          onChange={(e) =>
                            handleAction(
                              "checkbox",
                              category.id,
                              e.target.checked,
                              e
                            )
                          }
                        />
                        <label
                          htmlFor={`category-${category.id}`}
                          className="ml-3 text-sm text-gray-600 cursor-pointer"
                        >
                          {category.name}
                        </label>
                      </div>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <input
                            id={`category-${category.id}`}
                            type="checkbox"
                            className="h-4 w-4 rounded text-dark-blue border-gray-300 focus:ring-black"
                            checked={selectedCategoryIDs.includes(category.id)}
                            onChange={(e) =>
                              handleAction(
                                "checkbox",
                                category.id,
                                e.target.checked,
                                e
                              )
                            }
                          />
                          <label
                            htmlFor={`category-${category.id}`}
                            className="ml-3 text-sm text-gray-600 cursor-pointer"
                          >
                            {category.name}
                          </label>
                        </div>
                        {renderCategories(category.children)}
                      </>
                    )}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </>
    ));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filtro
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Fechar</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categorias</h3>
                    <ul className="px-2 py-3 font-medium text-gray-900">
                      {children.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {renderCategories(categories)}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto  px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row sm:items-baseline items-center justify-between pt-6 pb-6">
            <h1 className="text-center md:text-start text-4xl font-bold tracking-tight text-black sm:text-center mb-2">
              Todos os Produtos
            </h1>

            <div className="flex items-center">
              <Menu
                as="div"
                className="relative inline-block text-left sm:text-center"
              >
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-black hover:underline">
                    Filtrar
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-black"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        <p
                          onClick={() => handleName(allProducts)}
                          className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-900 hover:text-white"
                        >
                          A-Z
                        </p>
                      </Menu.Item>
                      <Menu.Item id="low">
                        <p
                          onClick={(e) => handlePrice("low")}
                          id="low"
                          className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-900 hover:text-white"
                        >
                          Menor Preço
                        </p>
                      </Menu.Item>
                      <Menu.Item id="high">
                        <p
                          onClick={(e) => handlePrice("high")}
                          id="high"
                          className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-900 hover:text-white"
                        >
                          Maior Preço
                        </p>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Clear Filters */}
              <Menu as="div" className="relative inline-block text-left ml-3">
                <div>
                  <Menu.Button
                    className="group inline-flex justify-center text-sm font-medium text-black hover:underline"
                    onClick={handleClearFilters}
                  >
                    Limpar Filtros
                  </Menu.Button>
                </div>
              </Menu>
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filtros</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24">
            <h2 id="products-heading" className="sr-only">
              Produtos
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <div>
                <form className="hidden lg:block sticky top-0">
                  <h3 className="sr-only">Categorias</h3>

                  <Menu
                    as="div"
                    className="relative inline-block text-left ml-3 mb-4"
                  >
                    <div>
                      <Menu.Button
                        className="group inline-flex justify-center text-sm font-medium text-black hover:underline"
                        onClick={handleClearFilters}
                      >
                        Limpar Filtros
                      </Menu.Button>
                    </div>
                  </Menu>

                  {renderCategories(categories)}
                  <Menu
                    as="div"
                    className="relative inline-block text-left ml-3"
                  >
                    <div>
                      <Menu.Button
                        className="group inline-flex justify-center text-sm font-medium text-black hover:underline"
                        onClick={handleClearFilters}
                      >
                        Limpar Filtros
                      </Menu.Button>
                    </div>
                  </Menu>
                </form>
              </div>

              {/* Product grid */}
              <div className="lg:col-span-3 lg:pb-48  ">
                {loaded ? (
                  <ProductList
                    allProducts={
                      itemsFiltered.length > 0 ? itemsFiltered : allProducts
                    }
                    selectedCount={selectedCount}
                    isCompany={isCompany}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default SideBar;
