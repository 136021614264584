import React from "react";
import { Typography, Container } from "@mui/material";
import "tailwindcss/tailwind.css";

const SiteSeguro = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center p-4 mt-0">
      <Container className=" p-8 rounded  max-w-md w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-4">
          SITE SEGURO
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Para melhor conveniência e segurança do comprador, os pagamentos da
          loja são realizados, por cartão de crédito ou boleto bancário, através
          de uma das maiores empresas de pagamentos eletrônicos seguros do
          Brasil e do mundo: Asaas.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          A empresa de pagamento eletrônico seguro, como Asaas, utiliza diversos
          protocolos de segurança para manter suas informações de pagamento
          protegidas. Na compra de um produto ou serviço, o vendedor não tem
          acesso a seus dados financeiros, tais como: número do cartão de
          crédito, CPF, dados bancários.
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Além disso, a plataforma aumenta a segurança nas transações entre
          compradores e vendedores, garantindo a devolução do valor pago ao
          comprador, ou pagamento ao vendedor, em caso de problemas com uma
          compra online.
        </Typography>
        <Typography variant="body1" className="text-gray-700">
          Compre tranquilo! Nós utilizamos tecnologia de ponta para garantir
          toda segurança para você.
        </Typography>
      </Container>
    </div>
  );
};

export default SiteSeguro;
