import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/api";

const Tags = () => {
  const [mosaico, setMosaico] = useState([]);

  useEffect(() => {
    api.get("/mosaics").then((res) => {
      if (res.status === 200) {
        setMosaico(res.data.mosaics);
      }
    });
  }, []);

  useEffect(() => {
    // Adiciona o script da API do Vimeo ao carregar o componente
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const renderMosaicItem = (position) => {
    const item = mosaico.find((m) => m.position === position);
    if (!item) return null;

    return item.imgUrl !== "" ? (
      <Link to={item.href} key={item.id}>
        <img src={item.imgUrl} alt={item.title} className="w-full h-full" />
      </Link>
    ) : (
      <>
        <iframe
          src="https://player.vimeo.com/video/987120663?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&mute=1&loop=1"
          allow="autoplay; encrypted-media"
          className="w-full h-full  min-h-[700px] md:w-full md:h-full"
          title={item.title}
        />
      </>
    );
  };

  return (
    <section className="py-10 flex flex-col items-center px-1 lg:px-4 xl:px-12 2xl:px-44">
      <div className="self-start sm:mb-6 sm:pl-44 md:px-1 ">
        <h1 className="text-2xl font-bold text-gray-800 hidden md:flex">
          CATEGORIAS POR PRODUTOS
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:grid-rows-2 sm:px-44 md:grid-cols-2 md:gap-2 md:gap-x-28  md:px-1 lg:gap-4 lg:gap-x-4 lg:grid-cols-3  ">
        {/* LEFT */}
        <div className="sm:col-span-1 sm:row-span-2">
          {renderMosaicItem("LEFT")}
        </div>
        {/* RIGHT */}
        <div className="hidden md:grid w-full sm:col-span-2 md:col-span-1 lg:pl-24 xl:pl-12 2xl:pl-0 lg:col-span-2">
          {renderMosaicItem("RIGHT")}
        </div>

        <div className="hidden w-full h-full md:grid md:grid-cols-2 md:gap-x-2 lg:col-span-2 lg:pl-24  xl:pl-12 2xl:pl-0 lg:gap-x-4 2xl:hidden 3xl:pl-0">
          {/* BOTTOM_LEFT */}
          <div className="w-full">{renderMosaicItem("BOTTOM_LEFT")}</div>
          {/* BOTTOM_RIGHT */}
          <div className="w-full">{renderMosaicItem("BOTTOM_RIGHT")}</div>
        </div>
        {/* BOTTOM_LEFT */}
        <div className="w-full hidden 2xl:grid">
          {renderMosaicItem("BOTTOM_LEFT")}
        </div>
        {/* BOTTOM_RIGHT */}
        <div className="w-full md:hidden 2xl:grid">
          {renderMosaicItem("BOTTOM_RIGHT")}
        </div>
      </div>
    </section>
  );
};

export default Tags;
