import React from "react";
import { Typography, Container } from "@mui/material";
import "tailwindcss/tailwind.css";

const PoliticaDeFrete = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center p-4 mt-44">
      <Container className="p-8 rounded  max-w-2xl w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-4">
          POLÍTICA DE FRETE
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Aqui na ZP Cosméticos o nosso objetivo é garantir que os itens cheguem
          o mais rápido possível para nossos clientes, nós garantimos que todos
          os itens sejam enviados o mais rápido possível. Para isso, existe uma
          série de medidas que garantem um envio ágil e seguro.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Garantimos um código de rastreio para o cliente poder acompanhar o
          progresso da entrega de seu pedido.
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Horário limite para realizar o pedido
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          O horário limite para realizar o pedido é às 14:00 (GMT-03:00) Horário
          Padrão de Brasília (São Paulo). É importante respeitar esse horário,
          pois após ele, o processo de envio já estará em andamento. Isso
          garante que o tempo de manuseio e trânsito sejam otimizados,
          proporcionando aos clientes uma entrega mais rápida e eficiente.
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Tempo de manuseio e trânsito (CRONOGRAMAS ESTIMADOS PARA ENTREGA)
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Todos os produtos disponíveis na ZP Cosméticos são enviados
          diretamente de nossos fornecedores no exterior (Suíça, Suécia, Estados
          Unidos e China) para o endereço informado no momento da compra.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          O tempo de manuseio é estimado em 3 a 5 dias úteis (segunda a sexta).
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          O tempo de trânsito pode levar de 12 a 21 dias úteis (segunda a sexta)
          para Frete Grátis (econômico) ou;
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          O tempo de trânsito pode levar de 7 a 15 dias úteis (segunda a sexta).
          É importante destacar que esses prazos não incluem feriados ou
          interrupções programadas de serviço, que podem atrasar a chegada do
          pedido.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Os produtos serão enviados pelos Correios ou outra transportadora,
          sempre haverá um Código de Rastreio que possibilitará ao cliente
          acompanhar o progresso da entrega, o código para acesso ao
          rastreamento será enviado para o seu e-mail assim que estiver
          disponível para loja ZP Cosméticos, o código de rastreamento pode
          demorar de 5 a 10 dias para começar a constar informações de envio no
          site dos correios.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          *Observação: os prazos estimados de envio não incluem o tempo de
          manuseio/separação!
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Cálculo do frete:
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Para consultar o valor e prazo do frete, basta escolher os itens
          desejados e clicar em “Adicionar ao Carrinho”. Em seguida, informe o
          CEP e o sistema fará o cálculo automático do custo e prazo de entrega.
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Envio em vários pacotes:
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Em alguns casos, é possível que o pedido chegue em vários pacotes e
          remessas. Dependendo do tipo, quantidade e peso dos itens em seu
          pedido. Não se preocupe, você não será cobrado pelos pacotes e
          remessas adicionais além do valor que aparece na finalização da
          compra.
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Como garantir a segurança e rapidez na entrega:
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          A ZP Cosméticos trabalha com parceiros confiáveis para garantir a
          segurança e rapidez na entrega de seus itens. Além disso, é importante
          ficar atento às restrições regionais e feriados, que podem atrasar o
          recebimento da remessa. Para evitar imprevistos, verifique sempre com
          o seu serviço postal local sobre possíveis interrupções ou restrições.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Em resumo, a ZP Cosméticos tem como objetivo garantir a chegada dos
          itens em tempo recorde, com segurança e comodidade para nossos
          clientes. Seguindo nossas orientações, você terá a certeza de uma
          entrega eficiente e satisfatória.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          A loja garante a entrega do pedido, nos responsabilizamos a restituir
          o valor integral pago pelos produtos que forem extraviados ou se
          perderem durante o processo de entrega. Todos os produtos são postados
          com seguro contra extravio e com rastreamento pela internet gratuito,
          a entrega de seu produto é 100% garantida.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          "Caso o produto atrase e não seja entregue em 60 dias úteis da
          postagem, fora casos que fogem do controle da loja, nessa data o valor
          pago será 100% devolvido".
        </Typography>
        <Typography variant="h6" className="font-semibold pb-2">
          Destinatário Ausente:
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Certifique-se de que haverá alguém no endereço para receber seus
          produtos. Os Correios realizam três tentativas de entrega dos
          produtos, caso não haja ninguém no endereço para receber a encomenda
          em nenhuma das tentativas, o pacote será levado para a agência dos
          Correios mais próxima ao endereço, e, neste caso, será necessário que
          o destinatário do pacote realize a retirada do mesmo na agência dos
          Correios, em até 7 dias corridos. Caso o destinatário da encomenda não
          realize a retirada, por norma dos Correios, o pacote retornará ao
          remetente, sendo necessário o pagamento de um novo frete para o
          reenvio do produto.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Todos os detalhes relacionados às tentativas de entrega, bem como o
          endereço da agência dos Correios na qual o destinatário deverá buscar
          seu pacote no caso de ausência no endereço, ficam registrados no
          rastreio do pedido, que pode ser acompanhado no site dos Correios,
          utilizando o código de rastreio fornecido pela loja.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4 underline">
          Em caso da entrega não ser realizada devido a divergência no endereço
          informado pelo cliente, o cliente não tem direito ao reembolso, será
          necessário o pagamento de um novo frete, no valor de 55 reais (valor
          do envio + taxa dos correios), para reenvio do produto.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Ao efetuar uma compra em nosso site, o cliente estará de acordo com as
          condições e normas aqui determinadas pela loja ZP Cosméticos.
        </Typography>
        <Typography variant="body1" className="text-black">
          Equipe ZP Cosméticos
        </Typography>
      </Container>
    </div>
  );
};

export default PoliticaDeFrete;
