import React, { useState, useEffect } from "react";
import Alerts from "./Alerts";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/Logo.svg";
import api from "../api/api";
import EmailVerificationAlert from "./EmailAlert";
import CircularProgress from "@mui/material/CircularProgress";

const RegisterForm = (props) => {
  const [step, setStep] = useState(0);
  const [underSend, setUnderSend] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    dNumber: "",
    phone: "",
  });
  const [confirmedPassword, setConfirmedPassword] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    dNumber: "",
    phone: "",
    error: "",
    type: "",
  });
  const [isChecked, setIsChecked] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [passErrors, setPassErrors] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePasswordValidation = () => {
    if (
      data.password &&
      data.confirmPassword &&
      data.password === data.confirmPassword
    ) {
      setConfirmedPassword(true);
      setPassErrors([]);
    } else {
      setPassErrors(["Senhas são diferentes!"]);
      setConfirmedPassword(false);
    }
  };
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const isEmptyValid = validateEmptyFields();
    handlePasswordValidation();

    if (isEmptyValid && confirmedPassword) {
      setUnderSend(true);
      api
        .post("/users", {
          name: data.name,
          email: data.email,
          password: data.password,
          docNumber: data.dNumber,
          phone: data.phone,
        })
        .then((res) => {
          if (res.status === 201) {
            setIsModalOpen(true);
            setUnderSend(false);
          }
        })
        .catch((err) => {
          // Verificar se o erro é uma resposta do servidor (erro de validação, etc.)
          if (err.response && err.response.data.errors) {
            const errorRes = err.response.data.errors;
            const errorArr = [];

            for (const key of Object.keys(errorRes)) {
              errorArr.push(errorRes[key].message);
            }
            setErrors(errorArr);
            setUnderSend(false);
          } else if (err.response) {
            // Erro de servidor, mas não no formato esperado
            const status = err.response.status;
            switch (status) {
              case 400:
                const message = err.response.data.message;
                if (message === "Invalid document number.") {
                  setErrors({
                    ...errors,
                    dNumber: "CPF/CNPJ inválido.",
                  });
                  setWarning({
                    type: "error",
                    message: "CPF/CNPJ inválido.",
                  });
                  setOpenSnackbar(true);
                  setUnderSend(false);

                  break;
                }
                setWarning({
                  type: "error",
                  message:
                    "Requisição inválida. Por favor, verifique os dados e tente novamente.",
                });
                setOpenSnackbar(true);
                setUnderSend(false);

                break;

              case 409:
                // Conflito, por exemplo, usuário já cadastrado
                setErrors({
                  ...errors,
                  email: "Usuário já cadastrado!",
                });
                setWarning({
                  type: "error",
                  message: "Usuário já cadastrado!",
                });
                setOpenSnackbar(true);
                setUnderSend(false);
                break;

              case 500:
                // Erro interno do servidor

                setWarning({
                  type: "error",
                  message:
                    "Ocorreu um erro no servidor. Por favor, tente novamente mais tarde. Cod: 500",
                });
                setOpenSnackbar(true);
                setUnderSend(false);
                break;

              default:
                // Outros erros de servidor
                setWarning({
                  type: "error",
                  message:
                    "Ocorreu um erro no servidor. Por favor, tente novamente mais tarde.",
                });
                setOpenSnackbar(true);
                setUnderSend(false);
                break;
            }
          } else if (err.request) {
            // A requisição foi feita, mas não houve resposta do servidor
            setErrors([
              "Não foi possível conectar ao servidor. Verifique sua conexão.",
            ]);
            setWarning({
              type: "error",
              message:
                "Não foi possível conectar ao servidor. Verifique sua conexão.",
            });
            setOpenSnackbar(true);
            setUnderSend(false);
          } else {
            // Algum outro erro ocorreu durante a configuração da requisição
            setErrors([
              "Ocorreu um erro inesperado. Por favor, tente novamente.",
            ]);
            setWarning({
              type: "error",
              message:
                "Ocorreu um erro inesperado. Por favor, tente novamente.",
            });
            setOpenSnackbar(true);
            setUnderSend(false);
          }
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Password validation
    if (name === "password") {
      if (value.length < 8) {
        setErrors({
          ...errors,
          password: "Senha deve conter pelo menos 8 caracteres",
        });
        setData({ ...data, [name]: value });
      } else {
        setData({ ...data, [name]: value });
        setErrors({
          ...errors,
          password: "",
        });
      }
    } else if (name === "phone") {
      const rawNumber = value.replace(/\D/g, ""); // Remove non-digit characters
      const formattedNumber = formatPhoneNumberForDisplay(rawNumber);
      setData({
        ...data,
        [name]: rawNumber, // Store raw number for submission
        formattedPhone: formattedNumber, // Display formatted number
      });
    } else if (name === "dNumber") {
      const rawNumber = value.replace(/\D/g, ""); // Remove non-digit characters
      const formattedNumber = formatDocumentNumber(rawNumber);
      setData({
        ...data,
        [name]: rawNumber, // Store raw number for submission
        formattedDNumber: formattedNumber, // Display formatted number
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const validateEmptyFields = () => {
    // Zerando os erros anteriores dos campos que serão validados
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: "",
      email: "",
      password: "",
      dNumber: "",
      phone: "",
    }));

    // Lista de campos para validar
    const fieldsToValidate = ["name", "email", "password", "dNumber", "phone"];

    fieldsToValidate.forEach((field) => {
      if (!data[field].trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `O campo ${field} é obrigatório.`,
        }));
      }
    });

    // Verificar se algum erro foi adicionado
    const hasErrors = fieldsToValidate.some((field) => !data[field].trim());
    return !hasErrors; // Retorna true se não houver erros
  };

  return (
    <div className="bg-white ">
      <main className="flex-col justify-center items-center h-screen mt-10">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8 m-auto">
          <div className="text-center">
            <a href="/" className="flex justify-center">
              <img src={logo} className="w-half h-full" alt="Logo" />
            </a>
            <form className="space-y-6" onSubmit={onSubmitHandler}>
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
                Cadastro de conta
              </h2>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome Completo
                </label>
                <div>
                  <input
                    type="text"
                    name="name"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    value={data.name}
                    placeholder="Nome Sobrenome"
                  />
                  {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div>
                  <input
                    type="text"
                    name="email"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    value={data.email}

                    placeholder="exemplo@dominio.com"

                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email}</p>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div>
                  <input
                    type="password"
                    name="password"
                    onBlur={handlePasswordValidation}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    placeholder="****************"
                    value={data.password}
                  />
                  {errors.password && (
                    <p style={{ color: "red" }}>{errors.password}</p>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirme sua senha
                </label>
                <div>
                  <input
                    type="password"
                    name="confirmPassword"
                    onBlur={handlePasswordValidation}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    placeholder="****************"
                    value={data.confirmPassword}
                  />
                  {errors.confirmPassword && (
                    <p style={{ color: "red" }}>{errors.confirmPassword}</p>
                  )}
                  {passErrors.map((error, idx) => (
                    <p style={{ color: "red" }} key={error + idx}>
                      {error}
                    </p>
                  ))}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Documento (CPF/CNPJ)
                </label>
                <div>
                  <input
                    type="text"
                    name="dNumber"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    maxLength={18}
                    value={data.formattedDNumber}
                    placeholder="XXX.XXX.XXX-XX / XX.XXX.XXX/XXXX-XX"
                  />
                  {errors.dNumber && (
                    <p style={{ color: "red" }}>{errors.dNumber}</p>
                  )}
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Contato
                </label>
                <div>
                  <input
                    type="text"
                    name="phone"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={handleChange}
                    value={data.formattedPhone}
                    maxLength={15}
                    placeholder="(XX) XXXXX-XXXX"
                  />
                  {errors.phone && (
                    <p style={{ color: "red" }}>{errors.phone}</p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={underSend}
                className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blackfocus:ring-offset-2"
              >
                {underSend ? (
                  <CircularProgress size={24} thickness={4} />
                ) : (
                  "Criar Conta"
                )}
              </button>
            </form>

            <a href="/login">
              <h3 className="mt-6 text-sm font-medium text-dark-blue hover:text-gray-900">
                Já tem uma conta? Acesse aqui &rarr;
              </h3>
            </a>
          </div>
        </div>
        <Alerts
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          type={warning.type}
          message={warning.message}
        />
        <EmailVerificationAlert
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      </main>
    </div>
  );
};

export default RegisterForm;

const formatPhoneNumberForDisplay = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  let formatted = "";

  if (cleaned.length <= 2) {
    formatted = `(${cleaned}`;
  } else if (cleaned.length <= 6) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
  } else if (cleaned.length <= 10) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
      2,
      6
    )}-${cleaned.slice(6)}`;
  } else if (cleaned.length === 11) {
    formatted = `(${cleaned.slice(0, 2)}) ${cleaned.slice(
      2,
      7
    )}-${cleaned.slice(7)}`;
  } else {
    // If the number exceeds 10 digits, return the cleaned number as is
    formatted = cleaned;
  }

  return formatted;
};
const formatDocumentNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, "");
  // Truncate to a maximum of 14 digits
  const truncated = cleaned.slice(0, 14);
  let formatted = "";

  // Formatting for CPF
  if (truncated.length <= 11) {
    formatted = truncated.slice(0, 3);
    if (truncated.length >= 4) {
      formatted += `.${truncated.slice(3, 6)}`;
    }
    if (truncated.length >= 7) {
      formatted += `.${truncated.slice(6, 9)}`;
    }
    if (truncated.length >= 10) {
      formatted += `-${truncated.slice(9, 11)}`;
    }
  }
  // Formatting for CNPJ
  else {
    formatted = truncated.slice(0, 2);
    if (truncated.length >= 3) {
      formatted += `.${truncated.slice(2, 5)}`;
    }
    if (truncated.length >= 6) {
      formatted += `.${truncated.slice(5, 8)}`;
    }
    if (truncated.length >= 9) {
      formatted += `/${truncated.slice(8, 12)}`;
    }
    if (truncated.length >= 13) {
      formatted += `-${truncated.slice(12, 14)}`;
    }
  }

  return formatted;
};
