import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
const TiposCabelo = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520);
    };

    // Executa uma vez para definir o estado inicial
    handleResize();

    // Adiciona um listener de resize para atualizar o estado quando a janela for redimensionada
    window.addEventListener("resize", handleResize);

    // Remove o listener de resize quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} tipos`}
        onClick={onClick}
        style={{ ...style, left: "15px", zIndex: 10 }}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} tipos`}
        onClick={onClick}
        style={{
          right: "35px",
          zIndex: 10,
        }}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Mostra as setas de navegação
    prevArrow: <PrevArrow />, // Componente ou ícone para a seta anterior
    nextArrow: <NextArrow />, // Componente ou ícone para a seta seguinte
  };

  return isMobile ? (
    <section className="flex justify-center items-center w-full h-full my-5 flex-col">
      <div className="w-11/12 h-full">
        <Slider {...settings}>
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center w-full h-full"
            >
              <div className="w-full h-full">
                <Link to={`/produtos${item.link}`} className="block">
                  <img
                    src={item.card}
                    alt={item.name}
                    className="w-full h-full object-fit"
                  />
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  ) : (
    <section className="flex justify-center items-center w-full my-5 flex-col">
      <div className="w-11/12 sm:w-4/5 lg:w-3/4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center w-full h-auto lg:h-128 bg-transparent"
          >
            <div className="w-full h-full">
              <Link to={`/produtos${item.link}`} className="block">
                <img
                  src={item.card}
                  alt={item.name}
                  className="w-full h-full object-cover"
                />
              </Link>
            </div>
            <Link
              to={`/produtos${item.link}`}
              className="block text-center text-white"
            >
              <div className="mt-[-34px] sm:mt-[-26px] md:mt-[-32px] lg:mt-[-22px] xl:mt-[-26px] 2xl:mt-[-32px]">
                {item.name}
              </div>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TiposCabelo;
