import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HeroSection.css"; // Importe o arquivo de estilos personalizados

const HeroSection = ({ banners, mobile }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 760);
    };

    // Executa uma vez para definir o estado inicial
    handleResize();

    // Adiciona um listener de resize para atualizar o estado quando a janela for redimensionada
    window.addEventListener("resize", handleResize);

    // Remove o listener de resize quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Adiciona o script da API do Vimeo ao carregar o componente
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, left: "15px", zIndex: 10 }}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          right: "35px",
          zIndex: 10,
        }}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Mostra as setas de navegação
    prevArrow: <PrevArrow />, // Componente ou ícone para a seta anterior
    nextArrow: <NextArrow />, // Componente ou ícone para a seta seguinte
  };

  return (
    <div className="hero-section">
      <Slider {...settings}>
        {isMobile
          ? mobile.map((item, index) => (
              <img
                key={index}
                src={item.imgUrl}
                alt="Banner Principal"
                className="w-full h-full object-cover"
              />
            ))
          : banners.map((item, index) => (
              <div className="w-full h-full" key={index}>
                {item.imgUrl.startsWith("https") ? (
                  <img
                    src={item.imgUrl}
                    alt="Banner Principal"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full md:h-[300px] 2md:h-[450px] lg:h-[440px] 2xl:h-[700px]">
                    <iframe
                      src="https://player.vimeo.com/video/975187389?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&mute=1&loop=1"
                      allow="autoplay; encrypted-media"
                      className="w-full h-full"
                      title="Linha Vulcanic Mazzon - ZP Cosmeticos"
                    />
                  </div>
                )}
              </div>
            ))}
      </Slider>
    </div>
  );
};

export default HeroSection;
