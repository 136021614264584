import { useState, useContext, useEffect } from "react";
import "./Searchbar.css";
import SearchField from "./components/SearchField";
import * as Aicon from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import logoHor from "../../assets/Logo Zp Horizontal.svg";
import GeneralContext from "../../Context/ProductsContext";
import SlidingCart from "../../components/SlidingCart";
import { Context as AuthContext } from "../../Context/AuthContext";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { AiOutlineUser } from "react-icons/ai";
import Badge from "@mui/material/Badge";

const Searchbar = ({
  setLoaded,
  setUserSession,
  authenticated,
  userSession,
  numInCart,
  loaded,
  handleLogout,
  openCart,
}) => {
  const [filters, setFilters] = useState({ name: "" });

  return (
    <section className="bg-white  w-full h-auto sm:h-24">
      <div className="w-full container mx-auto px-6 py-1 h-full flex flex-col sm:flex-row justify-center sm:justify-between items-center md:px-4">
        {/* Logo */}
        <div className="hidden md:flex justify-center items-center sm:h-full">
          <Link to="/">
            <img src={logoHor} alt="Logo" className="w-44 h-full md:w-full" />
          </Link>
        </div>

        {/* Campo de Pesquisa */}
        <div className="w-full flex-1 mx-6 py-1 flex items-center justify-center">
          <SearchField filters={filters} setFilters={setFilters} />
        </div>

        {/* Ícones e Botão de Logout */}
        <div className="hidden md:flex items-center space-x-4 mt-4 sm:mt-0">
          {/* Profile */}
          {authenticated ? (
            <Link
              className="flex items-center space-x-1 text-sm text-gray-700"
              to="/conta"
            >
              <AiOutlineUser className="text-2xl" />
              <span>{userSession.name}</span>
            </Link>
          ) : (
            <Link
              className="flex items-center text-base text-gray-700"
              to="/login"
            >
              Entrar
              <AiOutlineUser className="text-2xl ml-2" />
            </Link>
          )}

          {/* Favorites */}
          {/*<Link className="text-gray-700" to="/favoritos">
          <Aicon.AiOutlineHeart />
        </Link>*/}

          {/* Cart */}
          <div
            onClick={() => openCart(true)}
            className="text-gray-700 hover:cursor-pointer"
          >
            <Badge badgeContent={numInCart} color="primary">
              <LocalMallOutlinedIcon fontSize="medium" />
            </Badge>
          </div>
          <SlidingCart openCart={openCart} loaded={loaded} />
          {/* Logout */}
          {authenticated ? (
            <button
              onClick={handleLogout}
              className="text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 rounded-lg text-sm px-4 py-1 md:text-sm md:px-3 md:py-0.5"
            >
              Sair
            </button>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Searchbar;
