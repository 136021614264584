import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";

const ShareButton = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType, setAlertType] = useState("success"); // "success" ou "error"

  const handleShare = async () => {
    try {
      const url = window.location.href; // Ou a URL específica do seu produto
      await navigator.clipboard.writeText(url);
      setSnackbarMessage("Endereço copiado!");
      setAlertType("success");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Falha ao copiar o endereço: ", error);
      setSnackbarMessage(
        "Não foi possível copiar o endereço. Tente novamente."
      );
      setAlertType("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Tooltip title="Compartilhar">
        <IconButton color="primary" onClick={handleShare}>
          <IosShareIcon />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ShareButton;
