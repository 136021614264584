import { Tab } from "@headlessui/react";

function MyTabs({ Desc, Rec }) {
  return (
    <div className="w-full py-2 self-center">
      <Tab.Group>
        <Tab.List className="flex justify-between border-b-2 border-black">
          {Desc.map((section, index) => (
            <Tab
              key={section.title}
              className={({ selected }) =>
                `w-1/3 py-2 leading-none text-center line-clamp-2 text-wrap md:text-nowrap md:line-clamp-1 text-sm 2xl:text-lg
                ${selected ? "text-black font-semibold" : "text-gray-500"} 
                hover:text-black focus:outline-none focus:border-none`
              }
            >
              {section.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Desc.map((section) => (
            <Tab.Panel key={section.title} className="p-4">
              {/* Aqui usamos `dangerouslySetInnerHTML` para renderizar o HTML */}
              <div
                ref={Rec}
                className="text-sm text-gray-700"
                dangerouslySetInnerHTML={{ __html: section.body }}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default MyTabs;
