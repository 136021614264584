import React, { useState } from "react";
import img from "../../../assets/pretty_woman_putting_on_makeup_at_home_with_light_2022_04_06_05_03_20_utc.webp";
import img2 from "../../../assets/two_women_friends_together_2022_01_22_00_05_11_utc.webp";
import Alerts from "../../Alerts";
import api from "../../../api/api";

const NewsLetter = () => {
  const [aceite, setAceite] = useState(false);
  const [newsLetter, setNewsLetter] = useState({
    email: "",
    name: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({ type: "", msg: "" });

  const handlerNewsLetter = (e) => {
    e.preventDefault();

    // Regular expression for validating email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if email is not empty and is in valid format
    if (
      newsLetter.email &&
      emailRegex.test(newsLetter.email) &&
      newsLetter.name &&
      aceite
    ) {
      api
        .post("/newsletters", {
          email: newsLetter.email,
          name: newsLetter.name,
        })
        .then((res) => {
          if (res.status === 201) {
            setWarning({
              type: "success",
              msg: "Cadastro Realizado com sucesso! Em breve você receberá nossas novidades.",
            });
            handleOpenSnackbar();
            setNewsLetter({
              email: "",
              name: "",
            });
            setAceite(false);
          }
        })
        .catch((err) => {
          let errorMessage;
          if (
            err.response.data.message ===
            "Newsletter subscription already exists"
          ) {
            errorMessage = "Este email já está cadastrado na nossa newsletter.";
          } else if (err.response) {
            // O servidor respondeu com um status diferente de 2xx
            switch (err.response.status) {
              case 400:
                errorMessage =
                  "Requisição inválida. Verifique os dados e tente novamente.";
                break;
              case 401:
                errorMessage =
                  "Não autorizado. Por favor, faça login e tente novamente.";
                break;
              case 403:
                errorMessage =
                  "Acesso negado. Você não tem permissão para realizar esta ação.";
                break;
              case 404:
                errorMessage =
                  "Recurso não encontrado. Por favor, tente novamente.";
                break;
              case 500:
                errorMessage = "Erro no servidor. Tente novamente mais tarde.";
                break;
              default:
                errorMessage = "Algo deu errado. Por favor, tente novamente.";
            }
          } else if (err.request) {
            // A solicitação foi feita, mas nenhuma resposta foi recebida
            errorMessage =
              "Sem resposta do servidor. Verifique sua conexão com a internet.";
          } else {
            // Outro tipo de erro ocorreu na configuração da solicitação
            errorMessage =
              "Erro na configuração da solicitação. Tente novamente.";
          }

          setWarning({
            type: "error",
            msg: errorMessage,
          });
          handleOpenSnackbar();
        });
    } else if (!newsLetter.email || !emailRegex.test(newsLetter.email)) {
      // Email is empty or not in valid format
      setWarning({ type: "error", msg: "Email inválido!" });
      handleOpenSnackbar();
    } else if (!newsLetter.name) {
      // Email is empty or not in valid format
      setWarning({ type: "error", msg: "Precisamos saber seu nome!" });
      handleOpenSnackbar();
    } else {
      setWarning({
        type: "error",
        msg: "Aceite receber nossas novidades antes de se cadastrar!",
      });
      handleOpenSnackbar();
    }
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <>
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.msg}
      />
      <div className="flex flex-col flex-shrink px-4 my-5 sm:px-44">
        <div className="flex justify-center items-center gap-4">
          {/* Images Section */}
          <div className="sm:w-full sm:flex gap-2 hidden">
            <div className="w-full h-full">
              <img src={img} alt="Mulher Bonita" />
            </div>
            <div className="w-full h-full">
              <img src={img2} alt="Mulheres Bonitas" />
            </div>
          </div>
          {/* Text Area */}
          <div className="w-full">
            <div className="sm:w-4/5 mx-auto">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Receba ofertas, novidades e dicas
              </h3>
              <p className="text-sm text-gray-700 mb-4">
                Assine nossa newsletter e não perca nenhuma oportunidade, faça
                parte da família que mais cresce no Brasil.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="w-full sm:w-3/4">
                  <input
                    type="text"
                    id="name-newsletter"
                    placeholder="Nome Completo"
                    name="name-newsletter"
                    value={newsLetter.name}
                    onChange={(e) =>
                      setNewsLetter({ ...newsLetter, name: e.target.value })
                    }
                    className="w-full h-12 mb-4 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  />
                  <input
                    type="text"
                    id="email-newsletter"
                    placeholder="Seu E-mail"
                    name="email-newsletter"
                    value={newsLetter.email}
                    onChange={(e) =>
                      setNewsLetter({ ...newsLetter, email: e.target.value })
                    }
                    className="w-full h-12 px-4 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  />
                </div>
                <div className="w-full sm:w-1/4 flex items-center">
                  <input
                    id={`aceite-newsletter`}
                    name={`aceite-newsletter`}
                    type="checkbox"
                    checked={aceite}
                    onChange={(e) => setAceite(e.target.checked)}
                    className="h-6 w-6 rounded-md border border-gray-300 focus:ring-1 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`aceite-newsletter`}
                    className="ml-2 text-sm text-gray-700"
                  >
                    Aceito receber novidades
                  </label>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-4 items-center mt-4">
                <button
                  className="w-full sm:w-auto bg-black text-white py-2 px-12 rounded-md text-sm font-medium text-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                  onClick={(e) => handlerNewsLetter(e)}
                >
                  Inscrever-se
                </button>
                <p className="text-xs text-gray-500">
                  Ao inscrever-se você estará concordando com nossos termos de
                  políticas e privacidade. Leia políticas de privacidade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
