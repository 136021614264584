import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import Navbar from "./global/navbar/Navbar";
import Footer from "./global/footer/Footer";
import { GeneralProvider } from "./Context/ProductsContext";
import { AuthProvider } from "./Context/AuthContext";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  // Componente para rolar para o topo da página
  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  };
  return (
    <AuthProvider>
      <GeneralProvider>
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main>
            <ScrollToTop />
            <Outlet />
          </main>
          <Footer />
        </div>
        <Analytics />
        <SpeedInsights />
      </GeneralProvider>
    </AuthProvider>
  );
}

export default App;
