import React from "react";
import { Link } from "react-router-dom";

const FooterItem = ({ data }) => {
  const MenuItem = data;

  return (
    <>
      {MenuItem.map((items) => (
        <li key={items.Title}>
          <h3 className="text-md font-bold">{items.Title}</h3>
          {items.items?.map((item) => (
            <Link className="hover:underline text-sm" to={item.link}>
              <p className="mb-2">{item.Title}</p>
            </Link>
          ))}
        </li>
      ))}
    </>
  );
};

export default FooterItem;
