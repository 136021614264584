import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ShoppingCartIcon } from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/api";
import * as TBicon from "react-icons/tb";
import defaultImage from "../../assets/noImage.jpg";
import { Rating } from "@mui/material";
import Loading from "../Loading";
import CardProdutos from "../Card/Produtos/CardProdutos";
import GeneralContext from "../../Context/ProductsContext";

const Resume = ({
  setItemsInCart,
  itemsInCart,
  setStep,
  step,
  setNumInCart,
  numInCart,
  cupomApply,
  setCupomApply,
  setWarning,
  handleOpenSnackbar,
  setFrete,
  frete,
  subtotal,
  total,
  desconto,
  isCompany,
  clearCupom,
  cupom,
  user,
}) => {
  const [inputCupom, setInputCupom] = useState("");
  const [recomend, setRecomend] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/products/featured");
        setRecomend(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error); // É bom manter o log de erros
      }
    };

    fetchFeaturedProducts();
  }, []);

  const removeFromCart = (e, idx) => {
    e.preventDefault();

    // Cria uma nova array sem o item removido
    const newItemsInCart = itemsInCart.filter((_, index) => index !== idx);
    const quantityRemoved = itemsInCart[idx].quantity;

    // Atualiza o estado e o localStorage
    setItemsInCart(newItemsInCart);
    localStorage.setItem("itemsInCart", JSON.stringify(newItemsInCart));

    // Atualiza o número total de itens no carrinho
    const newNumInCart = numInCart - quantityRemoved;
    setNumInCart(newNumInCart);
    localStorage.setItem("numInCart", newNumInCart);

    // Exibe mensagem de sucesso
    setWarning({ type: "success", message: "Produto removido da sacola!" });
    handleOpenSnackbar();
  };

  const formatCurrency = (value) => {
    console.log("Valor do Total:", value);

    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const checkCupom = () => {
    const foundCupom = cupom.find(
      (item) => item.code.toUpperCase() === inputCupom?.toUpperCase()
    );
    if (foundCupom) {
      setCupomApply(foundCupom);
    } else {
      setCupomApply({});
    }
  };

  const renderFeaturedProducts = (position) => {
    return recomend
      .filter(
        (featuredProduct) =>
          featuredProduct.position === position &&
          ((!isCompany && featuredProduct.type === "FOR_YOU") ||
            (isCompany && featuredProduct.type === "FOR_BUSINESS"))
      )
      .map((filteredProduct) => (
        <CardProdutos
          key={filteredProduct.id} // Adicione uma key única para cada item renderizado
          title={filteredProduct.title}
          data={filteredProduct.products}
          href={filteredProduct.href}
          isHome={true}
        />
      ));
  };

  const increaseQuantity = (product) => {
    setItemsInCart((currentItems) => {
      return currentItems.map((item) => {
        if (item.id === product.id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
    });

    // Update numInCart using functional update
    setNumInCart((prevNumInCart) => prevNumInCart + 1);
  };

  const decreaseQuantity = (product) => {
    setItemsInCart((currentItems) => {
      return currentItems.map((item) => {
        if (item.id === product.id && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
    });

    // Update numInCart using functional update
    setNumInCart((prevNumInCart) =>
      prevNumInCart > 0 ? prevNumInCart - 1 : 0
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="flex flex-col md:flex-row w-full justify-evenly max-w-[1000px] px-auto">
        <div className="flex flex-col w-full md:w-[70%] p-4">
          {/* Cupoms */}
          <div className="h-[20%] bg-[#dff0d8] rounded-md p-4 text-center">
            <p>
              <span>Ganhe</span>{" "}
              <span className="text-[#00a470] font-bold">5%</span>{" "}
              <span>de desconto pagando via</span>{" "}
              <span className="text-[#00a470] font-bold">PIX!</span>
            </p>
          </div>

          {/* Produtos */}
          <div className="h-[60%] m-4">
            {/* Verifica se o carrinho está vazio e exibe a mensagem */}
            {itemsInCart.length === 0 ? (
              <div className="text-center my-4 flex flex-col">
                <span>Nenhum produto no carrinho!</span>
                <Link to="/produtos">
                  <span className="text-bold text-blue-500">
                    Adicione produtos ao carrinho
                  </span>
                </Link>
              </div>
            ) : (
              itemsInCart?.map((produto, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row items-start md:items-center justify-between border-b mb-4 p-4"
                >
                  {/* Imagem e Detalhes do Produto */}
                  <div className="flex items-start md:items-center">
                    <img
                      src={produto.imgUrl}
                      alt={produto.name}
                      className="w-20 h-20 rounded-lg mr-4 object-cover"
                    />
                    <div className="flex flex-col">
                      <h3 className="text-lg font-semibold mb-1">
                        {produto.name}
                      </h3>
                      <div className="flex items-center text-sm text-gray-500 mb-2">
                        <p className="mr-4">Qtd: {produto.quantity}</p>
                        <div className="flex items-center gap-x-4">
                          <button
                            type="button"
                            className={`${
                              produto.quantity === 1 ? "hidden" : ""
                            } text-2xl md:text-base font-medium text-dark-blue hover:text-gray-900`}
                            onClick={(e) => decreaseQuantity(produto)}
                            disabled={numInCart === 1}
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="text-2xl md:text-base font-medium text-dark-blue hover:text-gray-900"
                            onClick={(e) => increaseQuantity(produto)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="text-base font-medium text-red-500 hover:text-red-700"
                        onClick={(e) => removeFromCart(e, index)}
                      >
                        Remover
                      </button>
                    </div>
                  </div>

                  {/* Preço e Status */}
                  <div className="flex flex-col md:flex-row items-center mt-4 md:mt-0">
                    {!isCompany && produto.customerPrice === 0 ? (
                      <span className="text-md text-red-500">
                        Produto disponível apenas para salão
                      </span>
                    ) : isCompany && produto.vendorPrice === 0 ? (
                      <span className="text-md text-red-500">
                        Produto indisponível
                      </span>
                    ) : (
                      <span className="text-md font-semibold">
                        {`${formatCurrency(
                          isCompany
                            ? produto.vendorPriceNew > 0
                              ? produto.vendorPriceNew
                              : produto.vendorPrice
                            : produto.customerPriceNew > 0
                            ? produto.customerPriceNew
                            : produto.customerPrice
                        )}`}
                      </span>
                    )}
                    <button
                      id="remove_from_cart"
                      onClick={(e) => removeFromCart(e, index)}
                      className="ml-4 text-red-500 hover:text-red-700"
                    >
                      Remover
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="w-full md:w-[30%]  flex-col p-4">
          <div className="w-full p-4 bg-white rounded-lg">
            <div className="flex justify-between ">
              <span>Subtotal</span>
              <span>{formatCurrency(subtotal)}</span>
            </div>

            {cupomApply?.discount > 0 ? (
              <div className="flex flex-col">
                <div className="flex justify-between text-base text-gray-900">
                  <p>Cupom </p>
                  <p>{cupomApply.code}</p>
                </div>
                <div className="flex justify-between text-base text-rose-400">
                  <p>Desconto </p>
                  <p>{cupomApply.discount}%</p>
                </div>
                <div className="flex justify-between text-base text-rose-400">
                  <p>Desconto Total</p>
                  <p>{formatCurrency(desconto)}</p>
                </div>
                <button
                  onClick={clearCupom}
                  className="py-2 px-4 bg-red-500 text-black rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Limpar Cupom
                </button>
              </div>
            ) : (
              <Disclosure>
                {({ open }) => (
                  <div
                    className={`rounded-lg
                  ${
                    open ? "pb-4" : ""
                  } bg-blue-100 text-left text-sm font-medium text-blue-500 mb-2`}
                  >
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-500 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 mb-2">
                      <span>Cupom?</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-blue-500 `}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-500 bg-blue-100">
                      <div className="flex flex-col p-2 justify-between ">
                        <input
                          type="text"
                          onChange={(e) => setInputCupom(e.target.value)}
                          value={inputCupom}
                          className="w-half border-2 mb-2 border-blue-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none focus:border-blue-500"
                          placeholder="Cupom"
                        />

                        <button
                          type="button"
                          className="flex items-center justify-center rounded-md border border-transparent bg-blue-600 hover:bg-blue-700 px-6 py-1 text-base font-medium text-white shadow-sm "
                          onClick={checkCupom}
                        >
                          Aplicar
                        </button>
                      </div>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            )}

            {frete ? (
              <>
                <div className="flex justify-between mb-3">
                  <span>Frete</span>
                  <span>
                    {isCompany
                      ? subtotal < 399
                        ? formatCurrency(frete)
                        : "Frete Grátis"
                      : subtotal < 199
                      ? formatCurrency(frete)
                      : "Frete Grátis"}
                  </span>
                </div>
                <p className="mt-0.5 text-xs text-gray-500">
                  Poderá alterar o endereço de entrega no próximo passo.
                </p>
              </>
            ) : null}

            <div className="border-t mt-3 pt-3 flex justify-between">
              <span className="font-semibold">Total</span>
              <span className="font-semibold">{formatCurrency(total)}</span>
            </div>
          </div>
          <div className="px-8 py-4 rounded-b-lg justify-center flex">
            <button
              className={`${
                itemsInCart.length === 0
                  ? "bg-gray-500 hover:bg-gray-500 cursor-not-allowed"
                  : "bg-black hover:bg-gray-900"
              } w-full max-w-md text-white font-bold py-2 px-4 rounded-3xl transition-colors`}
              type="button"
              onClick={() => setStep(step + 1)}
              disabled={itemsInCart.length === 0}
            >
              {itemsInCart.length === 0 ? "Carrinho Vazio" : "Confirmar Pedido"}
            </button>
          </div>
        </div>
      </div>
      {/*Recomendações de Produtos */}
      {recomend && renderFeaturedProducts("CHECKOUT")}
    </>
  );
};

export default Resume;
