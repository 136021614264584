import { Fragment, useEffect, useState, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import GeneralContext from "../Context/ProductsContext";
import { Context as AuthContext } from "../Context/AuthContext";
import Alerts from "./Alerts";
import defaultImage from "../assets/noImage.jpg";
import api from "../api/api";
import { isPromotionActive } from "../utils/ValidatePromotion";

const SlidingCart = (props) => {
  const {
    itemsInCart,
    setItemsInCart,
    numInCart,
    setNumInCart,
    cupomApply,
    cupom,
    setCupomApply,
    selectedRate,
    clearCupom,
  } = useContext(GeneralContext);
  const { authenticated } = useContext(AuthContext);
  const [isCompany, setIsCompany] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputCupom, setInputCupom] = useState("");
  const [cupomApplyied, setCupomApplyied] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const fretePrice = parseFloat(selectedRate?.price);
  const openCart = props.openCart;
  const cartRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCheckoutNew = () => {
    const token = localStorage.getItem("token");

    // Extrair apenas id e quantidade dos itens do carrinho
    const cartData = itemsInCart.map((item) => ({
      id: item.id,
      qt: item.quantity,
    }));

    // Definir o cookie para o cartData
    document.cookie =
      "cartData=" +
      JSON.stringify(cartData) +
      ";domain=.zpcosmeticos.com.br;path=/;secure;samesite=none";

    // Definir o cookie para o token do usuário
    document.cookie =
      "token=" +
      token +
      ";domain=.zpcosmeticos.com.br;path=/;secure;samesite=none";

    // Abrir o checkout na mesma aba
    window.location.href = "https://checkout.zpcosmeticos.com.br";
  };

  function formatUrl(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/-/g, " ")
      .replace(/\s+/g, "-")
      .trim()
      .toLowerCase();
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        openCart(false); // Fecha o carrinho se o clique estiver fora
      }
    };

    // Adiciona o listener de clique fora
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove o listener quando o componente é desmontado
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openCart]);

  useEffect(() => {
    setOpen(props.loaded);
  }, [props.loaded]);

  useEffect(() => {
    let price = 0;

    // Calcula o subtotal baseado apenas no preço dos produtos e quantidade
    const sum = itemsInCart.reduce((acc, item) => {
      price = isCompany
        ? isPromotionActive(item.discountVendorDueDate) &&
          item.vendorPriceNew > 0
          ? item.vendorPriceNew
          : item.vendorPrice
        : isPromotionActive(item.discountCustomerDueDate) &&
          item.customerPriceNew > 0
        ? item.customerPriceNew
        : item.customerPrice;

      return acc + price * item.quantity;
    }, 0);

    setSubtotal(sum.toFixed(2)); // Atualiza o estado do subtotal

    // Calcula o total inicial como sendo o subtotal
    let total = sum;

    // Adiciona o frete ao total, se aplicável
    if (selectedRate && !isNaN(fretePrice)) {
      total += fretePrice;
    }

    // Aplica desconto de cupom, se houver
    if (cupomApplyied) {
      const discount = (total * cupomApply.discount) / 100; // Assume que o valor do cupom é uma porcentagem
      setDesconto(discount.toFixed(2)); // Atualiza o estado com o valor do desconto
      total -= discount; // Diminui o total pelo valor do desconto
    }

    // Atualiza o estado do total
    setTotal(total.toFixed(2));
  }, [
    props.loaded,
    itemsInCart,
    cupomApplyied,
    selectedRate,
    fretePrice,
    isCompany,
    cupomApply,
  ]);

  useEffect(() => {
    if (authenticated) {
      api.get("/me").then((res) => {
        setIsCompany(res.data.isCompany);
      });
    }
  }, [authenticated]);

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const closeCart = () => {
    setOpen(!open);
    openCart(false);
  };

  const checkCupom = () => {
    const foundCupom = cupom.find(
      (item) => item.code === inputCupom.toUpperCase()
    );
    if (foundCupom) {
      setCupomApply(foundCupom);
      setCupomApplyied(true);
      localStorage.setItem("cupomInStorage", JSON.stringify(foundCupom));
    } else {
      setCupomApply({});
      setCupomApplyied(false);
      localStorage.removeItem("cupomInStorage", JSON.stringify(foundCupom));
    }
  };

  const increaseQuantity = (product) => {
    setItemsInCart((currentItems) => {
      return currentItems.map((item) => {
        if (item.id === product.id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
    });

    // Update numInCart using functional update
    setNumInCart((prevNumInCart) => prevNumInCart + 1);
  };

  const decreaseQuantity = (product) => {
    setItemsInCart((currentItems) => {
      return currentItems.map((item) => {
        if (item.id === product.id && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
    });

    // Update numInCart using functional update
    setNumInCart((prevNumInCart) =>
      prevNumInCart > 0 ? prevNumInCart - 1 : 0
    );
  };

  const removeFromCart = (e, idx) => {
    e.preventDefault();

    // Cria uma nova array sem o item removido
    const newItemsInCart = itemsInCart.filter((_, index) => index !== idx);
    const quantityRemoved = itemsInCart[idx].quantity;

    // Atualiza o estado e o localStorage
    setItemsInCart(newItemsInCart);

    // Atualiza o número total de itens no carrinho
    const newNumInCart = numInCart - quantityRemoved;
    setNumInCart(newNumInCart);

    // Exibe mensagem de sucesso
    setWarning({ type: "success", message: "Produto removido da sacola!" });
    handleOpenSnackbar();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeCart}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex  pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <Alerts
                    open={openSnackbar}
                    handleClose={handleCloseSnackbar}
                    type={warning.type}
                    message={warning.message}
                  />
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Sacola
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-900"
                            onClick={() => {
                              setOpen(!open);
                              openCart(false);
                            }}
                          >
                            <span className="sr-only">Fechar Sacola</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul className="-my-6 divide-y divide-gray-200">
                            {itemsInCart?.map((product, productIdx) => {
                              return (
                                <li
                                  key={product.id + productIdx}
                                  className="flex py-6"
                                >
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={product.imgUrl || defaultImage}
                                      alt={product.name}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <h3>
                                          <a
                                            href={`/produto/${formatUrl(
                                              product.nameMod
                                            )}`}
                                          >
                                            {product.name}
                                          </a>
                                        </h3>

                                        {isCompany ? (
                                          product.vendorPrice === 0 ? (
                                            <p className="mt-2 font-medium text-dark-blue">
                                              Produto indisponível
                                            </p>
                                          ) : product.vendorPriceNew > 0 &&
                                            isPromotionActive(
                                              product.discountVendorDueDate
                                            ) ? (
                                            <p className="mt-2 font-medium text-dark-blue">
                                              {formatCurrency(
                                                product.vendorPriceNew
                                              )}
                                            </p>
                                          ) : (
                                            <p className="mt-2 font-medium text-dark-blue">
                                              {formatCurrency(
                                                product.vendorPrice
                                              )}
                                            </p>
                                          )
                                        ) : product.customerPrice === 0 ? (
                                          <p className="mt-2 font-medium text-red">
                                            Produto disponível apenas para salão
                                          </p>
                                        ) : product.customerPriceNew > 0 &&
                                          isPromotionActive(
                                            product.discountCustomerDueDate
                                          ) ? (
                                          <p className="mt-2 font-medium text-dark-blue">
                                            {formatCurrency(
                                              product.customerPriceNew
                                            )}
                                          </p>
                                        ) : (
                                          <p className="mt-2 font-medium text-dark-blue">
                                            {formatCurrency(
                                              product.customerPrice
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <p className="mt-1 text-sm text-gray-500"></p>
                                    </div>
                                    <div className="flex flex-1 md:gap-x-0 items-end justify-between text-sm">
                                      <p className="text-gray-500">
                                        Qtd: {product.quantity}
                                      </p>
                                      <div className="flex gap-x-6 md:gap-x-0">
                                        <div className="flex gap-x-2">
                                          <button
                                            type="button"
                                            className={`${
                                              product.quantity === 1
                                                ? "hidden"
                                                : ""
                                            } text-4xl md:text-base font-medium text-dark-blue hover:text-gray-900 mr-2`}
                                            onClick={(e) =>
                                              decreaseQuantity(product)
                                            }
                                            disabled={numInCart === 1}
                                          >
                                            -
                                          </button>
                                          <button
                                            type="button"
                                            className="text-4xl md:text-base font-medium text-dark-blue hover:text-gray-900 mr-2"
                                            onClick={(e) =>
                                              increaseQuantity(product)
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                        <button
                                          type="button"
                                          className="text-lg md:text-base font-medium text-dark-blue hover:text-gray-900"
                                          onClick={(e) =>
                                            removeFromCart(e, productIdx)
                                          }
                                        >
                                          Remover
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between flex-col text-base font-medium text-gray-900">
                        <div className="flex justify-between mb-5">
                          <input
                            type="text"
                            onChange={(e) => setInputCupom(e.target.value)}
                            value={inputCupom}
                            className="w-half border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none focus:border-blue-500"
                            placeholder="Cupom"
                          />

                          <button
                            type="button"
                            className="flex items-center justify-center rounded-md border border-transparent bg-black px-6 py-1 text-base font-medium text-white shadow-sm hover:bg-gray-900"
                            onClick={checkCupom}
                          >
                            Aplicar
                          </button>
                        </div>
                      </div>
                      <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                        <div className="flex justify-between text-base  text-gray-900">
                          <p>SubTotal</p>
                          <p>{formatCurrency(subtotal)}</p>
                        </div>
                        {cupomApply?.discount > 0 ? (
                          <>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <p>Cupom </p>
                              <p>{cupomApply.code}</p>
                            </div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <p>Desconto </p>
                              <p>{cupomApply.discount}%</p>
                            </div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <p>Desconto Total</p>
                              <p>{formatCurrency(desconto)}</p>
                            </div>
                            <button
                              onClick={clearCupom}
                              className="py-2 px-4 bg-red-500 text-black rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                            >
                              Limpar Cupom
                            </button>
                          </>
                        ) : null}

                        {fretePrice ? (
                          <div className="flex justify-between text-base  text-gray-900">
                            <p>Frete</p>
                            <p>{formatCurrency(fretePrice)}</p>
                          </div>
                        ) : null}

                        {fretePrice ? (
                          <>
                            <p className="mt-0.5 text-xs text-gray-500">
                              Poderá alterar o endereço de entrega na
                              finalização da compra!
                            </p>
                            <div className="flex justify-between text-base  text-gray-900">
                              <p>Total</p>
                              <p>{formatCurrency(total)}</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex justify-between text-base  text-gray-900">
                              <p>Total</p>
                              <p>{formatCurrency(total)}</p>
                            </div>
                            <p className="mt-0.5 text-xs text-gray-500">
                              Frete será calculado na finalização da compra!
                            </p>
                          </>
                        )}
                        <div className="mt-6">
                          <Link
                            id="begin_checkout"
                            onClick={() => {
                              handleCheckoutNew();
                              closeCart();
                            }}
                            className="flex items-center justify-center rounded-3xl border border-transparent bg-black px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-900"
                          >
                            Finalizar Compra
                          </Link>
                        </div>
                        <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                          <p>
                            <button
                              type="button"
                              className="font-medium text-dark-blue hover:text-gray-900"
                              onClick={() => {
                                setOpen(!open);
                                openCart(false);
                              }}
                            >
                              Continuar Comprando
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlidingCart;
