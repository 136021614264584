import React, { useState, useContext, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Alerts from "./Alerts";
import { Link } from "react-router-dom";
import defaultImage from "../assets/noImage.jpg";
import GeneralContext from "../Context/ProductsContext";
import { Context as AuthContext } from "../Context/AuthContext";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import api from "../api/api";
import { isPromotionActive } from "../utils/ValidatePromotion";

const ProductList = (props) => {
  const { authenticated } = useContext(AuthContext);
  const [isCompany, setIsCompany] = useState(false);
  const itemsInCart = useContext(GeneralContext).itemsInCart;
  const setItemsInCart = useContext(GeneralContext).setItemsInCart;
  const setNumInCart = useContext(GeneralContext).setNumInCart;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [slideOpen, setSlideOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 30; // Define a quantidade de produtos por página

  function formatUrl(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/-/g, " ")
      .replace(/\s+/g, "-")
      .trim()
      .toLowerCase();
  }

  const openCart = (slide) => {
    setLoaded(slide);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  useEffect(() => {
    if (authenticated) {
      api.get("/me").then((res) => {
        setIsCompany(res.data.isCompany);
      });
    }
    scrollToTop();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const addToCart = (productToAdd) => {
    let currentProducts = itemsInCart;

    // Flag to check if product exists in the cart
    let productExists = false;

    // Check if the productToAdd is already in the cart
    currentProducts = currentProducts.map((product) => {
      if (product.id === productToAdd.id) {
        // Assuming 'id' is the unique identifier
        productExists = true;
        return { ...product, quantity: (product.quantity || 0) + 1 };
      }
      return product;
    });

    // If the product is not in the cart, add it
    if (!productExists) {
      currentProducts.push({ ...productToAdd, quantity: 1 });
    }

    // Update localStorage and state
    localStorage.setItem("itemsInCart", JSON.stringify(currentProducts));
    localStorage.setItem(
      "numInCart",
      currentProducts.reduce((acc, product) => acc + product.quantity, 0)
    );
    setItemsInCart(currentProducts);
    setNumInCart(
      currentProducts.reduce((acc, product) => acc + product.quantity, 0)
    );
    setWarning({ type: "success", message: "Produto adicionado a sacola!" });
    handleOpenSnackbar();
    openCart(true);
  };

  // Calcular o índice inicial e final dos produtos a serem exibidos na página atual
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = props.allProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    scrollToTop();
  };

  function calculateDiscountPercentage(originalPrice, discountedPrice) {
    if (originalPrice <= 0) return 0;
    return Math.round(
      ((originalPrice - discountedPrice) / originalPrice) * 100
    );
  }

  const formatPromo = (props) => {
    return isCompany &&
      props.vendorPriceNew > 0 &&
      isPromotionActive(props.discountVendorDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.vendorPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.vendorPrice,
              props.vendorPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.vendorPriceNew)} `}
          </span>
          <span className="text-sm text-black">{`ou 4x de ${formatCurrency(
            props.vendorPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : !isCompany &&
      props.customerPriceNew > 0 &&
      isPromotionActive(props.discountCustomerDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.customerPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.customerPrice,
              props.customerPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.customerPriceNew)} `}
          </span>
          <span className="text-sm text-black">{` ou 4x de ${formatCurrency(
            props.customerPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : (
      <div className="p-2">
        <span className="text-xl font-bold ">{`${formatCurrency(
          isCompany ? props.vendorPrice : props.customerPrice
        )}`}</span>
        <span className="text-sm text-black">{` ou 4 x de ${formatCurrency(
          isCompany ? props.vendorPrice / 4 : props.customerPrice / 4
        )}`}</span>
      </div>
    );
  };

  return (
    <div className="bg-white lg:h-full">
      {/* Alertas */}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
      <div className="mx-auto px-4 sm:py-4 sm:px-6 lg:px-4">
        {currentProducts.length > 0 && (
          <Stack spacing={2} direction="row" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(props.allProducts.length / productsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        )}

        <div className="grid grid-cols-2 gap-y-10 gap-x-1 md:gap-x-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8 2xl:grid-cols-4 ">
          {currentProducts.map((product, i) => {
            return (
              <div
                key={product.id}
                className="w-full rounded-lg overflow-hidden  "
              >
                {/* Card de produto */}
                <div className="p-4 flex flex-col items-center w-full">
                  {/* Botão de "Curtir" */}
                  {/*  <div className="self-end">
                  <button
                    onClick={() => {
                      // Cria uma cópia do array atual de likes
                      const updatedLikes = [...isLikedArray];
                      // Inverte o valor para o produto especificado no índice
                      updatedLikes[index] = !updatedLikes[index];
                      // Atualiza o estado com o novo array
                      setIsLikedArray(updatedLikes);
                    }}
                    className="text-red hover:text-red transition duration-300"
                  >
                    {isLikedArray[index] ? (
                      <FavoriteIcon />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                  </button>
                </div>*/}

                  <div className="group block w-full overflow-hidden transition-shadow duration-300">
                    {/* Imagem do Produto */}
                    <Link
                      to={`/produto/${formatUrl(product.nameMod)}`}
                      className="block w-full"
                    >
                      <img
                        src={product.imgUrl ? product.imgUrl : defaultImage}
                        alt={product.name}
                        className="w-full h-full md:h-[300px] object-cover transform transition-transform duration-300 group-hover:scale-[1.3]"
                      />
                    </Link>

                    {/* Nome e preço do produto */}
                    <div className="mt-4 text-center relative ">
                      <Link to={`/produto/${formatUrl(product.nameMod)}`}>
                        <h4 className="text-lg font-semibold line-clamp-3 md:line-clamp-2 h-14 bg-white">
                          {product.name}
                        </h4>
                      </Link>
                      <Link
                        to={`/produto/${formatUrl(product.nameMod)}`}
                        className="mt-1 fle flex-col md:flex-row items-center justify-center space-x-2"
                      >
                        {!isCompany && product.customerPrice === 0 ? (
                          <span className="text-md font-bold text-red">
                            Produto disponível apenas para salão
                          </span>
                        ) : isCompany && product.vendorPrice === 0 ? (
                          <span className="text-md font-bold text-red">
                            Produto indisponível
                          </span>
                        ) : (
                          formatPromo(product)
                        )}
                      </Link>
                    </div>
                    {/* Descrição do Produto */}
                    <div className="flex justify-center">
                      {/* Botão "Adicionar a Sacola", visível apenas em hover */}
                      <button
                        id="AddToCart"
                        onClick={() => addToCart(product)}
                        className={`add_to_cart AddToCart mt-4 sm:mt-0 inline-flex items-center justify-center rounded-lg md:rounded-3xl border border-transparent ${
                          isCompany && product.vendorPrice === 0
                            ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                            : !isCompany && product.customerPrice === 0
                            ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                            : ""
                        } px-4 text-nowrap xl:px-5 py-2 text-base hover:bg-gray-900 font-medium w-full md:w-[200px] h-[40px] bg-black text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2`}
                        disabled={
                          (isCompany && product.vendorPrice === 0) ||
                          (!isCompany && product.customerPrice === 0)
                        }
                      >
                        <ShoppingCartIcon
                          className="h-5 w-5 mr-1 hidden md:flex"
                          aria-hidden="true"
                        />
                        Adicionar à sacola
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {currentProducts.length > 0 && (
          <Stack spacing={2} direction="row" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(props.allProducts.length / productsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default ProductList;
