import React from "react";
import { Typography, Container } from "@mui/material";
import "tailwindcss/tailwind.css";

const AvisoLegal = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center p-4">
      <Container className=" p-8 rounded  max-w-md w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-4">
          AVISO LEGAL
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Protegida pela lei de direitos autorais, a ZP Cosméticos não autoriza
          o uso total ou parcial dos conteúdos aqui expostos. Você está
          autorizado apenas a acessar nosso site por meio do navegador de sua
          escolha.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Este site não é afiliado ao Facebook, Google ou nenhuma de suas
          entidades.
        </Typography>
        <Typography variant="body1" className="text-gray-700 pb-4">
          Os conteúdos aqui expostos são de responsabilidade da ZP Cosméticos.
        </Typography>
        <Typography variant="body1" className="text-gray-700">
          Equipe ZP Cosméticos.
        </Typography>
      </Container>
    </div>
  );
};

export default AvisoLegal;
