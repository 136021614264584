import { Fragment, useState, useContext, useEffect } from "react";
import "./Navbar.css";
import "./Topbar.css";
import { Link, useNavigate } from "react-router-dom";
import Searchbar from "../searchbar/Searchbar";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { IoReturnDownBackOutline } from "react-icons/io5";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import api from "../../api/api";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  Divider,
} from "@mui/material";
import { MdFileDownloadDone } from "react-icons/md";
import DOMPurify from "dompurify";
import { FaRegCopy } from "react-icons/fa";
import logoHor from "../../assets/Logo Zp Horizontal.svg";
import { AiOutlineUser } from "react-icons/ai";
import Badge from "@mui/material/Badge";
import SlidingCart from "../../components/SlidingCart";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import GeneralContext from "../../Context/ProductsContext";
import { Context as AuthContext } from "../../Context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [navigationPath, setNavigationPath] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [categories, setCategories] = useState([]);
  const [faixaTexto, setFaixaTexto] = useState(null);
  const [featured, setFeatured] = useState([]);
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false); // estado para controlar o feedback ao usuário

  const [loaded, setLoaded] = useState(false);
  const { authenticated, handleLogout } = useContext(AuthContext);
  const [userSession, setUserSession] = useState({});
  const { user, numInCart } = useContext(GeneralContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const openCart = (slide) => {
    setLoaded(slide);
  };

  useEffect(() => {
    const userSessionData = localStorage.getItem("user");
    if (userSessionData) {
      setUserSession(JSON.parse(userSessionData));
    }
  }, []);

  useEffect(() => {
    try {
      api.get("/categoriesFeatured").then((res) => {
        setFeatured(res.data.categoryFeaturedProduct);
      });
    } catch (error) {}
    try {
      api.get("/categories/nested").then((res) => {
        setCategories(res.data.categories);
      });
    } catch (e) {
      console.log(e);
    }
    if (authenticated) {
      try {
        api.get("/me").then((res) => {
          setIsCompany(res.data.isCompany);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [authenticated]);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("banners/line");
        // Encontra o primeiro objeto com position "TOP"
        const topBanner = response.data.inLineBanners.find(
          (banner) => banner.position === "TOP"
        );

        // Define o estado apenas com esse objeto, ou null se não encontrado
        setFaixaTexto(topBanner);
      } catch (error) {
        console.error("Erro ao buscar produtos destacados:", error);
      }
    };

    fetchFeaturedProducts();
  }, []);

  const handleCategoryClick = (categoryId) => {
    setIsPopoverOpen(false);
    navigate(`/produtos?category=${categoryId}`);
  };

  const handleCategoryType = () => {
    setIsPopoverOpen(false);
    navigate(`/produtos?type=KIT`);
  };

  const handleHtml = (html) => {
    const cleanHTML = DOMPurify.sanitize(html);
    return cleanHTML;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(faixaTexto.textButton); // copia o cupom para a área de transferência
    setCopied(true); // define o estado como copiado
    setTimeout(() => setCopied(false), 2000); // redefine o estado após 2 segundos
  };

  const navigateToCategory = (categoryId) => {
    setNavigationPath((prevPath) => [...prevPath, categoryId]);
  };

  const navigateBack = () => {
    setNavigationPath((prevPath) => prevPath.slice(0, -1));
  };

  const getCurrentCategories = (path, categories) => {
    let currentCategories = categories;
    for (const id of path) {
      const category = currentCategories.find((cat) => cat.id === id);
      if (category && category.children && category.children.length > 0) {
        currentCategories = category.children.sort((a, b) =>
          a.name.localeCompare(b.name, "pt-BR")
        );
      } else {
        setOpen(false);
        setNavigationPath([]);
        navigate(`/produtos?category=${category.id}`);
      }
    }
    return currentCategories;
  };

  const firstName = (name) => {
    return name.split(" ")[0];
  };

  const currentCategories = getCurrentCategories(navigationPath, categories);

  return (
    <nav className="navbar-global-section z-40">
      {faixaTexto && faixaTexto.button ? (
        <div className="topbar-container">
          <div className="topbar-content">
            <span
              className="topbar-cupom-item"
              dangerouslySetInnerHTML={{
                __html: `${handleHtml(faixaTexto.text)}`,
              }}
            />
            <span className="topbar-cupom">
              Use o Cupom{" "}
              <span
                className="topbar-cupom-code"
                dangerouslySetInnerHTML={{
                  __html: `${handleHtml(faixaTexto.textButton)}`,
                }}
              />
              <button className="text-white ml-2" onClick={copyToClipboard}>
                {copied ? <MdFileDownloadDone /> : <FaRegCopy />}
              </button>
            </span>
          </div>
        </div>
      ) : (
        faixaTexto && (
          <div
            className="topbar-without-code"
            dangerouslySetInnerHTML={{
              __html: `${handleHtml(faixaTexto.text)}`,
            }}
          />
        )
      )}

      <div className="flex justify-evenly gap-4 md:hidden w-full">
        {/*Mobile */}
        <button
          type="button"
          className="rounded-md bg-transparent p-2 text-black  lg:hidden"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open menu</span>
          <Bars3Icon className="h-8 w-8 " aria-hidden="true" />
        </button>

        {/* Logo */}
        <div className="md:hidden flex justify-center items-center sm:h-full py-2 mr-[-2rem]">
          <Link to="/">
            <img src={logoHor} alt="Logo" className="w-44 h-full md:w-full" />
          </Link>
        </div>

        {/* Ícones e Botão de Logout */}
        <div className="flex items-center space-x-2 sm:mt-0 pl-2 ">
          {/* Profile */}
          {authenticated ? (
            <div className="flex items-center space-x-1">
              <IconButton onClick={toggleDrawer(true)}>
                <span className="text-base text-black">
                  {firstName(userSession.name)}
                </span>
              </IconButton>
            </div>
          ) : (
            <Link
              className="flex items-center text-base text-gray-700"
              to="/login"
            >
              <AiOutlineUser className="text-2xl mr-2" />
            </Link>
          )}
          <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
            <div
              className="w-screen"
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem>
                  <ListItemText primary={userSession.name} />
                  {authenticated && (
                    <button
                      onClick={handleLogout}
                      className="text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 rounded-lg text-sm px-4 py-1 md:text-sm md:px-3 md:py-0.5"
                    >
                      Sair
                    </button>
                  )}
                </ListItem>
                <Divider />
                <ListItemButton component={Link} to="/conta">
                  <ListItemText primary="Perfil" />
                </ListItemButton>
                <ListItemButton component={Link} to="/conta">
                  <ListItemText primary="Compras" />
                </ListItemButton>
                <ListItem>
                  <ListItemText primary="Favoritos" secondary="Em Breve" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Novidades" secondary="Em Breve" />
                </ListItem>
              </List>
            </div>
          </Drawer>

          {/* Favorites */}
          {/*<Link className="text-gray-700" to="/favoritos">
          <Aicon.AiOutlineHeart />
        </Link>*/}

          {/* Cart */}
          <div
            onClick={() => openCart(true)}
            id="view_cart"
            className="text-gray-700 hover:cursor-pointer"
          >
            <Badge badgeContent={numInCart} color="primary">
              <LocalMallOutlinedIcon fontSize="medium" />
            </Badge>
          </div>
        </div>
      </div>

      <Searchbar
        setLoaded={setLoaded}
        setUserSession={setUserSession}
        authenticated={authenticated}
        userSession={userSession}
        numInCart={numInCart}
        loaded={loaded}
        handleLogout={handleLogout}
        openCart={openCart}
      />
      <div className="navbar">
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-md flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                  <div className="flex px-4 pt-5 pb-2">
                    <button
                      type="button"
                      className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Links */}
                  {navigationPath.length > 0 && (
                    <button
                      onClick={navigateBack}
                      className="flex items-center gap-2 px-8 py-2 text-left text-gray-800"
                    >
                      <span className="pt-1">
                        <IoReturnDownBackOutline />{" "}
                      </span>
                      voltar
                    </button>
                  )}

                  <div className="px-4 py-4 h-screen">
                    {currentCategories.map((category, index) => (
                      <div key={index} className="pl-4 border-b">
                        <button
                          onClick={() => navigateToCategory(category.id)}
                          className="w-full text-left text-lg font-semibold pb-2"
                        >
                          {category.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
          <div className="flex h-full space-x-16  ">
            {categories.map((category) => {
              const categoryFeatured = featured?.filter(
                (item) => item.categoryId === category.id
              );
              return category.name === "Kits" ? (
                <p
                  onClick={() => {
                    handleCategoryType();
                  }}
                  className="border-transparent cursor-pointer text-black  hover:text-gray-700 hover:border-black relative z-9 -mb-px flex items-center border-b-2 pt-px uppercase font-bold transition-colors duration-200 ease-out"
                >
                  {category.name}
                </p>
              ) : (
                <Popover key={category.name} className="flex">
                  {({ open, close }) => (
                    <>
                      <div className="relative flex">
                        <Popover.Button
                          className={classNames(
                            open
                              ? "border-white text-black  outline-none"
                              : "border-transparent text-black  hover:text-gray-700 hover:border-black outline-none",
                            "relative z-9 -mb-px flex items-center border-b-2 pt-px text-base  transition-colors duration-200 ease-out uppercase font-bold"
                          )}
                        >
                          {category.name}
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500 z-10">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div
                            className="absolute inset-0 top-1/2 bg-white shadow"
                            aria-hidden="true"
                          />

                          <div className="relative bg-white">
                            <div className="mx-auto max-w-7xl px-8">
                              <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                                <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                  {categoryFeatured?.[0]?.viewProducts?.map(
                                    (products) => (
                                      <div
                                        key={products.name}
                                        className="group relative text-base sm:text-sm"
                                      >
                                        <div className="aspect-w-1 aspect-h-1 overflow-hidden flex justify-center rounded-lg bg-gray-50 group-hover:scale-[1.2]">
                                          <img
                                            src={products.imgSrc}
                                            alt={products.imgAlt}
                                            className="object-cover object-center h-56 w-56"
                                          />
                                        </div>
                                        <a
                                          href={products.href}
                                          className="mt-6 block font-medium text-black"
                                        >
                                          <span
                                            className="absolute inset-0 z-10 "
                                            aria-hidden="true"
                                          />
                                          {products.name}
                                        </a>
                                        <p aria-hidden="true" className="mt-1">
                                          {products.actionText}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div
                                  className={`text-sm row-start-1 ${
                                    category.name === "Linhas"
                                      ? "grid grid-cols-3"
                                      : "flex flex-col flex-wrap max-w-xl content-center"
                                  }`}
                                >
                                  {category.children
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name, "pt-BR")
                                    )
                                    .map((item) => (
                                      <div key={item.name} className="mb-2">
                                        {item.productIds &&
                                        item.productIds.length > 0 ? (
                                          // Se a subcategoria tem produtos, mostre um link clicável
                                          <p
                                            onClick={() => {
                                              close();
                                              handleCategoryClick(item.id);
                                            }}
                                            className="text-xs hover:text-gray-800 cursor-pointer font-bold text-black"
                                          >
                                            {item.name}
                                          </p>
                                        ) : (
                                          // Se não houver produtos, mostre um título e suas sub-subcategorias, se houver
                                          <>
                                            <h3 className="text-lg font-bold  text-black">
                                              {item.name}
                                            </h3>
                                            {/* Renderize as sub-subcategorias aqui se houver */}
                                            {item.children &&
                                              item.children
                                                .sort((a, b) =>
                                                  a.name.localeCompare(
                                                    b.name,
                                                    "pt-BR"
                                                  )
                                                )
                                                .map((subItem) => (
                                                  <div
                                                    key={subItem.name}
                                                    className="mb-2"
                                                  >
                                                    {subItem.productIds &&
                                                    subItem.productIds.length >
                                                      0 ? (
                                                      <p
                                                        onClick={() => {
                                                          close();
                                                          handleCategoryClick(
                                                            subItem.id
                                                          );
                                                        }}
                                                        className="text-xs hover:text-gray-800 font-bold cursor-pointer text-black"
                                                      >
                                                        {subItem.name}
                                                      </p>
                                                    ) : (
                                                      <span className="text-xs ">
                                                        {subItem.name}
                                                      </span>
                                                    )}
                                                  </div>
                                                ))}
                                          </>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              );
            })}
            <div className="flex">
              <Link
                to="https://wa.me/5544997331231?text=Ol%C3%A1,%20quero%20ser%20uma%20representante%20ZP%20Cosm%C3%A9ticos"
                target="_blank noopener"
                className="uppercase font-bold border-transparent relative z-9 -mb-px flex items-center border-b-2 pt-px text-base text-black hover:text-gray-800 hover:border-black transition-colors duration-200 ease-out"
              >
                SEJA UMA REVENDEDORA
              </Link>
            </div>
          </div>
        </Popover.Group>
      </div>
    </nav>
  );
};

export default Navbar;
