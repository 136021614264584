import React, { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };

  const handleClick = () => {
    window.open("https://wa.me/5544991787027", "_blank");
  };

  return (
    <div className="fixed bottom-4 right-4 flex items-center space-x-2 z-20">
      {showMessage && (
        <div className="relative flex items-center ">
          <div className="absolute top-1/2 left-full ml-[-1px] w-0 h-0 border-t-8 border-b-8 border-l-8 border-transparent border-l-[rgba(0,0,0,0.3)] transform -translate-y-1/2"></div>
          <div className="bg-white text-black p-2 rounded-3xl shadow-[0px_0px_10px_rgba(0,0,0,0.3)] relative">
            Estamos online
          </div>
        </div>
      )}
      <button
        className="bg-[#25d366] hover:bg-[#1A8F45] text-white p-2 rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.3)]"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FaWhatsapp size={42} />
      </button>
    </div>
  );
};

export default WhatsAppButton;
