import React, { useContext } from "react";
import noImage from "../../../../assets/noImage.jpg";
import GeneralContext from "../../../../Context/ProductsContext";

export const CardRecomendado = ({
  index,
  produto,
  recomendado,
  handleOpenSnackbar,
  setWarning,
  openCart,
  isCompany,
}) => {
  const itemsInCart = useContext(GeneralContext).itemsInCart;
  const setItemsInCart = useContext(GeneralContext).setItemsInCart;
  const numInCart = useContext(GeneralContext).numInCart;
  const setNumInCart = useContext(GeneralContext).setNumInCart;

  // Calculate discount price (assuming recomendado.price contains the final price)
  const totalPrice = produto.customerPrice + recomendado.customerPrice;
  const productsToAdd = [produto, recomendado];

  // Calculate installment (assuming 6 installments)
  const installment = totalPrice / 6;

  const addToCartMultiple = (productsToAdd) => {
    let currentProducts = [...itemsInCart]; // Clona o array para evitar mutações diretas

    productsToAdd.forEach((productToAdd) => {
      const productIndex = currentProducts.findIndex(
        (product) => product.id === productToAdd.id
      );

      if (productIndex !== -1) {
        // Se o produto existe, atualize a quantidade
        currentProducts[productIndex] = {
          ...currentProducts[productIndex],
          quantity: currentProducts[productIndex].quantity + 1,
        };
      } else {
        // Se o produto não existe, adicione-o ao array
        currentProducts.push({
          ...productToAdd,
          quantity: 1,
        });
      }
    });

    setItemsInCart(currentProducts); // Atualize o carrinho com o novo array
    setNumInCart(
      currentProducts.reduce((acc, product) => acc + product.quantity, 0)
    ); // Recalcule o número total de itens
    setWarning({ type: "success", message: "Produtos adicionados à sacola!" });
    handleOpenSnackbar();
    openCart(true);
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  return (
    <div
      key={index}
      className=" w-full md:w-[450px] overflow-hidden my-4 self-center"
    >
      <div className="flex items-center justify-center w-full p-4">
        {/* Images of the Products */}
        <img
          className="h-32 mr-4 object-center transform transition-transform duration-300 hover:scale-110"
          src={produto.imgUrl || noImage}
          alt={produto.name}
        />
        <span className="text-2xl font-bold">+</span>
        <img
          className="h-32 mr-4 object-center transform transition-transform duration-300 hover:scale-110"
          src={recomendado.imgUrl || noImage}
          alt={recomendado.name}
        />
      </div>
      <>
        {/* Product Details */}
        <div className="flex flex-col text-center md:justify-between">
          <h3 className="text-sm font-bold ">{`${produto.name} +`}</h3>
          <h3 className="text-sm font-bold ">{` ${recomendado.name} `}</h3>

          {/* Pricing Details */}
          {!isCompany ? (
            produto.customerPrice === 0 ? (
              <div className="mt-2 flex-col">
                <span className="text-md font-bold text-red">
                  Produtos disponíveis apenas para salão
                </span>
              </div>
            ) : (
              <>
                <span className="text-xl font-bold">{`${formatCurrency(
                  produto.customerPrice + recomendado.customerPrice
                )}`}</span>
                <span className="text-sm text-gray-600">{`até 4x de ${formatCurrency(
                  produto.customerPrice + recomendado.customerPrice / 4
                )}`}</span>
              </>
            )
          ) : isCompany &&
            produto.customerPrice === 0 &&
            recomendado.customerPrice === 0 &&
            produto.vendorPrice === 0 &&
            recomendado.vendorPrice ? (
            <span className="text-md font-bold">Produto indisponível</span>
          ) : (
            <>
              <span className="text-xl font-bold">{`${formatCurrency(
                isCompany
                  ? produto.vendorPrice + recomendado.vendorPrice
                  : produto.customerPrice + recomendado.customerPrice
              )}`}</span>
              <span className="text-sm text-gray-600">{`até 4x de ${formatCurrency(
                isCompany
                  ? produto.vendorPrice + recomendado.vendorPrice / 4
                  : produto.customerPrice + recomendado.customerPrice / 4
              )}`}</span>
            </>
          )}

          {/* Action Button */}
          <button
            onClick={() => addToCartMultiple(productsToAdd)}
            className={`mt-2 bg-black  text-sm w-1/2 self-center py-2 rounded text-white  ${
              isCompany
                ? produto.vendorPrice === 0
                  ? "cursor-not-allowed bg-gray-400"
                  : "hover:bg-blue-900"
                : produto.customerPrice === 0
                ? "cursor-not-allowed bg-gray-400"
                : "hover:bg-blue-900"
            }`}
            disabled={
              isCompany
                ? produto.vendorPrice === 0
                : produto.customerPrice === 0
            }
          >
            Comprar os 2 itens
          </button>
        </div>
      </>
    </div>
  );
};
