import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import ProductList from "../../components/ProductList";
import axios from "axios";
import ScrollToTop from "../../components/ScrollToTop";

const Produtos = () => {
  return (
    <section className="xs:pt-[167px] sm:pt-[152px] md:pt-[177px]">
      <SideBar />
      <ScrollToTop />
    </section>
  );
};

export default Produtos;
