import React from 'react'

const Sobre = () => {
  return (
    <div>
      Sobre
    </div>
  )
}

export default Sobre
