import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const QrPix = ({ isOpen, closeModal, pixDetails }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Pague seu PIX!
                </Dialog.Title>
                <div className="flex flex-col items-center space-y-4">
                  {/* Container para o QR Code e payload */}
                  <div className="p-2 bg-gray-100 rounded">
                    {/* Verifica se a imagem codificada está presente e é uma string válida */}
                    {pixDetails?.encodedImage ? (
                      <img
                        className="w-64 h-64" // Ajuste o tamanho conforme necessário
                        src={`data:image/png;base64,${pixDetails.encodedImage}`}
                        alt="QR Code de Pagamento"
                      />
                    ) : (
                      <p className="text-gray-500">
                        Imagem do QR Code não disponível
                      </p>
                    )}
                  </div>

                  {/* Container para o botão Copiar */}
                  <div className="text-sm">
                    <CopyToClipboard text={pixDetails?.payload}>
                      <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300">
                        Copiar Código Pix
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Já paguei!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QrPix;
