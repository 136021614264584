import React from "react";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Tooltip from "@mui/material/Tooltip";

const ShareToWhatsAppButton = ({ product }) => {
  const { name, shortDesc } = product;
  const handleShareToWhatsApp = () => {
    const productDescription =
      "enriquecido com vitaminas e minerais essenciais para revitalizar e fortalecer os fios, garantindo brilho e suavidade incomparáveis."; // Substitua pela descrição do seu produto
    const callToAction =
      "Descubra como ele pode transformar o tratamento capilar em seu salão."; // Adapte conforme necessário
    const url = window.location.href;

    const message = `Olá! Gostaríamos de apresentar o ${name}, ${productDescription} ${callToAction} Saiba mais: ${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, "_blank"); // Abre a URL em uma nova aba
  };

  return (
    <Tooltip title="Compartilhar via WhatsApp">
      <IconButton color="success" onClick={handleShareToWhatsApp}>
        <WhatsAppIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ShareToWhatsAppButton;
