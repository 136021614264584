import React, { useState, useContext, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../assets/Logo.svg";
import api from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alerts from "./Alerts";
import Loading from "./Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Importe os ícones de visualização de senha

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [underSend, setUnderSend] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [showPassword, setShowPassword] = useState(false); // Estado para controlar a visibilidade da senha
  const [showPassword2, setShowPassword2] = useState(false); // Estado para controlar a visibilidade da senha

  const handleTogglePasswordVisibility = (type) => {
    if (type === 1) setShowPassword(!showPassword); // Alterna o estado da visibilidade da senha
    if (type === 2) setShowPassword2(!showPassword2);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    setUnderSend(true);
    api
      .post("/users/recovery", formData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // 201 para Created
          setWarning({
            type: "success",
            message: "Verifique seu e-mail para redefinir sua senha!",
          });
          handleOpenSnackbar();
          setUnderSend(false);
        } else {
          // Alguma outra resposta de sucesso não esperada
          setWarning({
            type: "warning",
            message:
              "Ocorreu algum problema com sua solicitação, aguarde e tente novamente.",
          });
          handleOpenSnackbar();
          setUnderSend(false);
        }
      })
      .catch((error) => {
        let errorMessage = "Falha ao tentar alterar sua senha!";
        if (error.response) {
          // Adiciona detalhes específicos do erro se disponível
          errorMessage += ` Detalhes: ${
            error.response.data.message || error.response.status
          }`;
        } else if (error.request) {
          // A requisição foi feita mas não houve resposta

          errorMessage = "O servidor não respondeu ao pedido.";
        } else {
          // Algum erro aconteceu ao montar o pedido

          errorMessage = "Erro ao enviar o pedido para o servidor.";
        }
        setWarning({
          type: "error",
          message: errorMessage,
        });
        handleOpenSnackbar();
        setUnderSend(false);
      });
  };

  const submit = (formData) => {
    if (formData.password === formData.confirmPassword) {
      const reqData = {
        token: token,
        newPassword: formData.password,
      };

      api
        .post("/users/reset", reqData)
        .then((response) => {
          if (response.status === 200) {
            // 200 para Created
            setWarning({
              type: "success",
              message: "Sunha senha foi alterada com sucesso!",
            });
            handleOpenSnackbar();
            setUnderSend(false);
            navigate("/login");
          } else {
            // Alguma outra resposta de sucesso não esperada
            setWarning({
              type: "warning",
              message:
                "Ocorreu algum problema com sua solicitação, aguarde e tente novamente.",
            });
            handleOpenSnackbar();
            setUnderSend(false);
          }
        })
        .catch((error) => {
          let errorMessage = "Falha ao tentar alterar sua senha!";
          if (error.response) {
            // Adiciona detalhes específicos do erro se disponível
            errorMessage += ` Detalhes: ${
              error.response.data.message || error.response.status
            }`;
          } else if (error.request) {
            // A requisição foi feita mas não houve resposta

            errorMessage = "O servidor não respondeu ao pedido.";
          } else {
            // Algum erro aconteceu ao montar o pedido

            errorMessage = "Erro ao enviar o pedido para o servidor.";
          }
          setWarning({
            type: "error",
            message: errorMessage,
          });
          handleOpenSnackbar();
          setUnderSend(false);
        });
    }
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    setLoading(true);
    if (token) {
      api
        .get(`/users/validate-token?token=${token}`)
        .then((response) => {
          if (response.status === 200) {
            setIsValid(true);
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  return loading ? (
    <Loading />
  ) : isValid ? (
    <div className="bg-white">
      <main className="flex-col justify-center items-center h-screen mt-10">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8 m-auto text-center">
          <a href="/" className="flex justify-center">
            <img src={logo} className="w-half h-full" alt="Logo" />
          </a>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
            Recuperação de senha
          </h2>
          <form
            onSubmit={handleSubmit(submit)}
            className="space-y-4 max-w-xl mx-auto my-10"
          >
            <div className="flex flex-col align-middle items-center">
              <label
                htmlFor="password"
                className="mr-2 font-medium text-gray-700 self-center items-center"
              >
                Nova senha:
              </label>
              <input
                {...register("password", { required: true })}
                type={showPassword ? "text" : "password"}
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Digite sua nova senha"
              />
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer">
                {/* Ícone para visualização de senha */}
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => handleTogglePasswordVisibility(1)}
                  />
                ) : (
                  <Visibility
                    onClick={() => handleTogglePasswordVisibility(1)}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500">Campo obrigatório</p>
              )}
            </div>
            <div className="flex flex-col align-middle items-center">
              <label
                htmlFor="confirmPassword"
                className="mr-2 font-medium text-gray-700 self-center items-center"
              >
                Confirme sua senha:
              </label>
              <input
                {...register("confirmPassword", {
                  required: true,
                  validate: (value) => value === watch("password"), // Validação customizada para comparar com a senha
                })}
                type={showPassword2 ? "text" : "password"}
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Confirme sua nova senha"
              />
              <div className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer">
                {/* Ícone para visualização de senha */}
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => handleTogglePasswordVisibility(2)}
                  />
                ) : (
                  <Visibility
                    onClick={() => handleTogglePasswordVisibility(2)}
                  />
                )}
              </div>
              {errors.confirmPassword &&
                errors.confirmPassword.type === "required" && (
                  <p className="text-red-500">Campo obrigatório</p>
                )}
              {errors.confirmPassword &&
                errors.confirmPassword.type === "validate" && (
                  <p className="text-red-500">As senhas não coincidem</p>
                )}
            </div>
            <button
              type="submit"
              disabled={underSend}
              className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blackfocus:ring-offset-2"
            >
              {underSend ? (
                <CircularProgress size={24} thickness={4} />
              ) : (
                "Salvar nova senha!"
              )}
            </button>
          </form>
        </div>
      </main>
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  ) : error ? (
    <div className="bg-white">
      <main className="flex-col justify-center items-center h-screen mt-10">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8 m-auto text-center">
          <a href="/" className="flex justify-center">
            <img src={logo} className="w-half h-full" alt="Logo" />
          </a>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
            Parece que houve um problema ao tentar recuperar sua senha
          </h2>
        </div>
      </main>
    </div>
  ) : (
    <div className="bg-white">
      <main className="flex-col justify-center items-center h-screen mt-10">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8 m-auto text-center">
          <a href="/" className="flex justify-center">
            <img src={logo} className="w-half h-full" alt="Logo" />
          </a>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
            Recuperação de senha
          </h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 max-w-xl mx-auto my-10"
          >
            <div className="flex align-middle items-center">
              <label
                htmlFor="categoryId"
                className="mr-2 font-medium text-gray-700 self-center items-center"
              >
                Email:
              </label>
              <input
                {...register("email", { required: true })}
                type="text"
                className="mt-1 block w-full p-2 border rounded-md"
                placeholder="Ex: seuemail@exemplo.com"
              />
            </div>
            <button
              type="submit"
              disabled={underSend}
              className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blackfocus:ring-offset-2"
            >
              {underSend ? (
                <CircularProgress size={24} thickness={4} />
              ) : (
                "Recuperar senha"
              )}
            </button>
          </form>
        </div>
      </main>
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default ResetPassword;
