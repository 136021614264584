import dayjs from "dayjs";

export const calculateTimeLeft = (dueDate) => {
  const now = dayjs();
  const expirationDate = dayjs(dueDate);
  const diff = expirationDate.diff(now);

  return dayjs.duration(diff);
};

// Função para verificar se uma promoção está ativa
export const isPromotionActive = (dueDate) => {
  return dueDate === null || dayjs(dueDate).isAfter(dayjs());
};

export const expiredPromotions = (produtos) =>
  produtos.filter(
    (product) =>
      !isPromotionActive(product.discountCustomerDueDate) &&
      !isPromotionActive(product.discountVendorDueDate)
  );
