export const MenuItem = [
  {
    Title: "Ajuda e Suporte",
    items: [
      { Title: "Aviso legal", link: "/aviso-legal" },
      { Title: "Como Comprar", link: "/como-realizar-sua-compra" },
      { Title: "Entrega e Frete", link: "/politica-de-frete" },
      { Title: "Segurança", link: "/site-seguro" },
      { Title: "Perguntas Frequentes", link: "/perguntas-frequentes" },
    ],
  },
  {
    Title: "Serviços ao Consumidor",
    items: [
      { Title: "Politica de Cookies", link: "/politica-de-cookies" },
      { Title: "Politica de Privacidade", link: "/politica-de-privacidade" },
    ],
  },
];
