import React, { useEffect } from "react";
import noImage from "../../../../assets/noImage.jpg";

const UniqueOrder = ({ order, setUniqueOrder, isMobile }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return isMobile ? (
    <div className="m-2 p-2 bg-white rounded-3xl">
      <h1 className="text-lg md:text-xl font-bold mb-4">
        Compra em nome de <b>{order.user.name}</b>
      </h1>

      <button
        className="btn text-blue-900 mb-4"
        onClick={() => setUniqueOrder(null)}
      >
        Voltar
      </button>

      <div className="bg-white border rounded-lg p-4 mb-4">
        <p className="mb-2">
          Status do Pedido:{" "}
          <span className="font-medium">{gridOrderStatus(order)}</span>
        </p>
        <p className="mb-2">
          Status do Pagamento:{" "}
          <span className="font-medium">{gridPaymentStatus(order)}</span>
        </p>
        <p className="mb-2">
          Forma de Pagamento:{" "}
          <span className="font-medium">{paymentType(order)}</span>
        </p>
        <p className="mb-2">
          Status do Envio:{" "}
          <span className="font-medium">{gridShipStatus(order)}</span>
        </p>
      </div>

      {/* Items do pedido */}
      <div className="bg-white border rounded-lg p-4 mb-4">
        <h2 className="text-lg font-semibold mb-4">Produtos:</h2>
        {order.items.map((item) => (
          <div className="flex flex-col md:flex-row mb-6">
            <div className="flex justify-center mb-4 md:mb-0 md:w-1/3">
              <img
                src={item.imgUrl ? item.imgUrl : noImage}
                alt={item.name}
                className="w-full h-auto max-w-[200px]"
              />
            </div>

            <div className="md:w-2/3 md:ml-4">
              <h3 className="text-lg font-semibold">{item.name}</h3>
              <p className="my-2 font-semibold">
                Preço:{" "}
                <span className="font-normal">R${item.price.toFixed(2)}</span>
              </p>
              <p className="my-2 font-semibold">
                Qtd: <span className="font-normal">{item.quantity}</span>
              </p>
              <p className="my-2 font-semibold">
                SubTotal:{" "}
                <span className="font-normal">R${item.total.toFixed(2)}</span>
              </p>
              <p>{item.shortDesc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white border rounded-lg p-4">
        <h2 className="text-lg font-semibold pb-4 border-b border-gray-300">
          Dados de Cobrança e Entrega:
        </h2>

        <div className="rounded-sm p-4 border-b border-gray-300">
          <dl className="">
            <h3 className="text-md font-semibold mb-2">Endereço de Entrega</h3>
            <p className="text-sm leading-tight text-gray-500">
              {order.orderShippingAddress.street},{" "}
              {order.orderShippingAddress.number}
            </p>
            <p className="text-sm leading-tight text-gray-500">
              {order.orderShippingAddress.complement}
            </p>
            <p className="text-sm leading-tight text-gray-500">
              {order.orderShippingAddress.neighborhood} -{" "}
              {order.orderShippingAddress.city} -{" "}
              {order.orderShippingAddress.state}
            </p>
            <p className="text-sm leading-tight text-gray-500">
              {order.orderShippingAddress.country}
            </p>
            <p className="text-sm leading-tight text-gray-500">
              {order.orderShippingAddress.zipCode}
            </p>
          </dl>
        </div>
        <div className="rounded-sm p-4">
          <dl>
            <div className="mb-4">
              <dt className="font-semibold">Endereço de Cobrança</dt>
              <p className="text-sm leading-tight text-gray-500">
                {order.orderBillingAddress.street},{" "}
                {order.orderBillingAddress.number}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                {order.orderBillingAddress.complement}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                {order.orderBillingAddress.neighborhood} -{" "}
                {order.orderBillingAddress.city} -{" "}
                {order.orderBillingAddress.state}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                {order.orderBillingAddress.country}
              </p>
              <p className="text-sm leading-tight text-gray-500">
                {order.orderBillingAddress.zipCode}
              </p>
            </div>
          </dl>
        </div>
      </div>
    </div>
  ) : (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h1 className="underline">
        Compra em nome de <b>{order.user.name}</b>
      </h1>

      <button
        className="btn text-blue-900 mt-4 mb-4"
        onClick={() => setUniqueOrder(null)}
      >
        voltar
      </button>
      <div className="flex justify-evenly bg-white border rounded-lg p-4 mb-4">
        <p>
          Status do Pedido:{" "}
          <span className="font-medium">{gridOrderStatus(order)}</span>
        </p>
        <p>
          Status do Pagamento:{" "}
          <span className="font-medium">{gridPaymentStatus(order)}</span>
        </p>
        <p>
          Forma de Pagamento:{" "}
          <span className="font-medium">{paymentType(order)}</span>
        </p>
        <p>
          Status do Envio:{" "}
          <span className="font-medium">{gridShipStatus(order)}</span>
        </p>
      </div>

      {/* Items do pedido */}

      <div className="bg-white border rounded-lg p-4 mb-4">
        <h2 className="text-xl font-semibold">Produtos:</h2>
        <div>
          {order.items.map((item) => (
            <div className="grid grid-cols-12 grid-rows-1 md:gap-x-8 sm:gap-x-6 md:gap-y-0 mb-12 ">
              <div class="col-span-5 row-span-2 row-end-2 self-center">
                <div className="flex justify-center">
                  <img
                    src={item.imgUrl ? item.imgUrl : noImage}
                    className="w-[250px] h-full flex"
                  />
                </div>
              </div>

              <div className="md:col-span-7 mt-0 row-end-1">
                <h3 className="text-xl font-semibold">{item.name}</h3>
                <p>R${item.price.toFixed(2)}</p>
                <p>Qtd: {item.quantity}</p>
                <p>subTotal: R${item.total.toFixed(2)}</p>
                <p>{item.shortDesc}</p>
              </div>

              <div className="sm:col-span-12 md:col-span-7">
                <dl className="border-b border-solid border-gray-700 py-8">
                  <h3 className="text-md font-semibold mb-2">
                    Endereço de Entrega
                  </h3>
                  <p className="text-sm leading-tight text-gray-500">
                    {order.orderShippingAddress.street},{" "}
                    {order.orderShippingAddress.number}
                  </p>
                  <p className="text-sm leading-tight text-gray-500">
                    {order.orderShippingAddress.complement}
                  </p>
                  <p className="text-sm leading-tight text-gray-500">
                    {order.orderShippingAddress.neighborhood} -{" "}
                    {order.orderShippingAddress.city} -{" "}
                    {order.orderShippingAddress.state}
                  </p>
                  <p className="text-sm leading-tight text-gray-500">
                    {order.orderShippingAddress.country}
                  </p>
                  <p className="text-sm leading-tight text-gray-500">
                    {order.orderShippingAddress.zipCode}
                  </p>
                </dl>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white border rounded-lg p-4 mb-4">
        <h2 className="text-xl font-semibold ">Dados de cobrança:</h2>{" "}
        <div className="grid grid-cols-12 grid-rows-1 md:gap-x-8 sm:gap-x-6 bg-[#FAF9FB] rounded-sm p-8">
          <dl className="md:col-span-5 md:gap-x-8 grid-cols-2">
            <div>
              <dt>Endereço de Cobrança</dt>
              <dl>
                <p className="text-sm leading-tight text-gray-500">
                  {order.orderBillingAddress.street},{" "}
                  {order.orderBillingAddress.number}
                </p>
                <p className="text-sm leading-tight text-gray-500">
                  {order.orderBillingAddress.complement}
                </p>
                <p className="text-sm leading-tight text-gray-500">
                  {order.orderBillingAddress.neighborhood} -{" "}
                  {order.orderBillingAddress.city} -{" "}
                  {order.orderBillingAddress.state}
                </p>
                <p className="text-sm leading-tight text-gray-500">
                  {order.orderBillingAddress.country}
                </p>
                <p className="text-sm leading-tight text-gray-500">
                  {order.orderBillingAddress.zipCode}
                </p>
              </dl>
            </div>
            <div></div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UniqueOrder;

const gridPaymentStatus = (status) => (
  <button
    type="button"
    style={{
      background:
        status.paymentStatus === "PENDING"
          ? "#FFC107"
          : status.paymentStatus === "PAYMENT_CREATED"
          ? "#007BFF"
          : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_UPDATED"
          ? "#007BFF"
          : status.paymentStatus === "PAYMENT_CONFIRMED"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_RECEIVED"
          ? "#28A745"
          : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_ANTICIPATED"
          ? "#4C16E0"
          : status.paymentStatus === "PAYMENT_OVERDUE"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_DELETED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_RESTORED"
          ? "#ECDD00"
          : status.paymentStatus === "PAYMENT_REFUNDED"
          ? "#DC3545"
          : status.paymentStatus === "PAYMENT_UPDATED"
          ? "#DC3545"
          : "#3D3D3D",
    }}
    className="text-black py-2 px-4 capitalize rounded-2xl text-sm"
  >
    {status.paymentStatus === "PENDING"
      ? "Pendente"
      : status.paymentStatus === "PAYMENT_CREATED"
      ? "CRIADO"
      : status.paymentStatus === "PAYMENT_APPROVED_BY_RISK_ANALYSIS"
      ? "APROVADO PELA ANALISE DE RISCO"
      : status.paymentStatus === "PAYMENT_REPROVED_BY_RISK_ANALYSIS"
      ? "REPROVADO PELA ANALISE DE RISCO"
      : status.paymentStatus === "PAYMENT_UPDATED"
      ? "ATUALIZADO"
      : status.paymentStatus === "PAYMENT_CONFIRMED"
      ? "CONFIRMADO"
      : status.paymentStatus === "PAYMENT_RECEIVED"
      ? "RECEBIDO"
      : status.paymentStatus === "PAYMENT_CREDIT_CARD_CAPTURE_REFUSED"
      ? "Recusado pela Operadora do Cartão"
      : status.paymentStatus === "PAYMENT_ANTICIPATED"
      ? "Antecipado"
      : status.paymentStatus === "PAYMENT_OVERDUE"
      ? "Expirado"
      : status.paymentStatus === "PAYMENT_DELETED"
      ? "Deletado"
      : status.paymentStatus === "PAYMENT_RESTORED"
      ? "Restaurado"
      : status.paymentStatus === "PAYMENT_REFUNDED"
      ? "Extornado"
      : "Outros"}
  </button>
);

const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{
      background:
        props.orderStatus === "CRIADO"
          ? "#B3B3B3"
          : props.orderStatus === "AGUARDANDO_PAGAMENTO"
          ? "#F2F20C"
          : props.orderStatus === "PROBLEMAS_PAGAMENTO"
          ? "#F3C211"
          : props.orderStatus === "PAGAMENTO_RECUSADO"
          ? "#FF4D4D"
          : props.orderStatus === "PAGAMENTO_REALIZADO"
          ? "#FFD400"
          : props.orderStatus === "EM_SEPARACAO"
          ? "#FFAA00"
          : props.orderStatus === "PEDIDO_SEPARADO"
          ? "#F28B24"
          : props.orderStatus === "AGUARDANDO_COLETA"
          ? "#F28B24"
          : props.orderStatus === "NOTA_FISCAL_EMITIDA"
          ? "#22C5E5"
          : props.orderStatus === "PEDIDO_DESPACHADO"
          ? "#2681FF"
          : props.orderStatus === "PEDIDO_ENTREGUE"
          ? "#3DCC6D"
          : props.orderStatus === "ORDEM_FINALIZADA"
          ? "#3DCC6D"
          : props.orderStatus === "ORDEM_CANCELADA"
          ? "#FF4D4D"
          : "#FF4D4D",
    }}
    className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-sm "
  >
    {props.orderStatus === "CRIADO"
      ? "CRIADO"
      : props.orderStatus === "AGUARDANDO_PAGAMENTO"
      ? "AGUARDANDO PAGAMENTO"
      : props.orderStatus === "PROBLEMAS_PAGAMENTO"
      ? "PROBLEMAS COM PAGAMENTO"
      : props.orderStatus === "PAGAMENTO_RECUSADO"
      ? "PAGAMENTO RECUSADO"
      : props.orderStatus === "PAGAMENTO_REALIZADO"
      ? "PAGAMENTO REALIZADO"
      : props.orderStatus === "EM_SEPARACAO"
      ? "EM SEPRARAÇÃO"
      : props.orderStatus === "PEDIDO_SEPARADO"
      ? "PEDIDO SEPARADO"
      : props.orderStatus === "AGUARDANDO_COLETA"
      ? "AGUARDANDO COLETA"
      : props.orderStatus === "NOTA_FISCAL_EMITIDA"
      ? "NOTA FISCAL EMITIDA"
      : props.orderStatus === "PEDIDO_DESPACHADO"
      ? "PEDIDO DESPACHADO"
      : props.orderStatus === "PEDIDO_ENTREGUE"
      ? "PEDIDO ENTREGUE"
      : props.orderStatus === "ORDEM_FINALIZADA"
      ? "FINALIZADO"
      : props.orderStatus === "ORDEM_CANCELADA"
      ? "CANCELADO"
      : "OUTROS"}
  </button>
);

const gridShipStatus = (props) => (
  <button
    type="button"
    style={{
      background:
        props.shippingStatus === "PENDENTE"
          ? "#F2F20C"
          : props.shippingStatus === "EM_SEPARACAO"
          ? "#FFD400"
          : props.shippingStatus === "FRETE_COMPRADO"
          ? "#FFAA00"
          : props.shippingStatus === "AGUARDANDO_COLETA"
          ? "#F28B24"
          : props.shippingStatus === "COLETADO"
          ? "#A2C5E5"
          : props.shippingStatus === "EM_TRANSITO"
          ? "#22C5E5"
          : props.shippingStatus === "ENTREGUE"
          ? "#3DCC6D"
          : props.shippingStatus === "NAO_ENTREGUE"
          ? "#D941A6"
          : props.shippingStatus === "CANCELADO"
          ? "#FF4D4D"
          : "#FF4D4D",
    }}
    className="text-[#000000] py-2 px-4 capitalize rounded-2xl text-sm"
  >
    {props.shippingStatus === "PENDENTE"
      ? "PENDENTE"
      : props.shippingStatus === "EM_SEPARACAO"
      ? "EM SEPARAÇÃO"
      : props.shippingStatus === "FRETE_COMPRADO"
      ? "FRETE COMPRADO"
      : props.shippingStatus === "AGUARDANDO_COLETA"
      ? "AGUARDANDO COLETA"
      : props.shippingStatus === "COLETADO"
      ? "COLETADO"
      : props.shippingStatus === "EM_TRANSITO"
      ? "EM TRÂNSITO"
      : props.shippingStatus === "ENTREGUE"
      ? "ENTREGUE"
      : props.shippingStatus === "NAO_ENTREGUE"
      ? "NÃO ENTREGUE"
      : props.shippingStatus === "CANCELADO"
      ? "CANCELADO"
      : "OUTROS"}
  </button>
);

const paymentType = (props) => (
  <button
    type="button"
    style={{
      background:
        props.paymentMethod === "CREDIT_CARD"
          ? "#22C5E5"
          : props.paymentMethod === "PIX"
          ? "#3DCC6D"
          : props.paymentMethod === "BANK_SLIP"
          ? "#D941A6"
          : "#FF4D4D",
    }}
    className="text-white py-2 px-4 capitalize rounded-2xl text-sm"
  >
    {props.paymentMethod === "CREDIT_CARD"
      ? "CARTÃO DE CRÉDITO"
      : props.paymentMethod === "PIX"
      ? "PIX"
      : props.paymentMethod === "BANK_SLIP"
      ? "BOLETO"
      : "OUTROS"}
  </button>
);
