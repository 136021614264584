import React from "react";
import Countdown from "./Countdown";
import OrderSummary from "./OrderSummary";

const Order = ({ user, order, payment, setStep, step, cupom }) => {
  return order.paymentMethod === "CREDIT_CARD" ? (
    <OrderSummary
      order={order}
      cupom={cupom}
      payment={payment}
      setStep={setStep}
      step={step}
    />
  ) : order.paymentMethod === "BANK_SLIP" ? (
    <OrderSummary
      order={order}
      cupom={cupom}
      payment={payment}
      setStep={setStep}
      step={step}
    />
  ) : order.paymentMethod === "PIX" ? (
    <OrderSummary
      user={user}
      cupom={cupom}
      order={order}
      payment={payment}
      setStep={setStep}
      step={step}
    />
  ) : null;
};

export default Order;
