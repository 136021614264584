import React from "react";
import { useParams } from "react-router-dom";
import ProductPage from "../../components/ProductPage";
import ScrollToTop from "../../components/ScrollToTop";

const Produto = () => {
  const { name } = useParams();
  return (
    <>
      <ProductPage nameMod={name} />
      <ScrollToTop />
    </>
  );
};

export default Produto;
