import React, { useContext, useEffect, useState } from "react";
import Resume from "./Resume";
import GeneralContext from "../../Context/ProductsContext";
import Address from "./Address";
import api from "../../api/api";
import Stepper from "../Stepper";
import Home from "../../Pages/Home/Home";
import Alerts from "../Alerts";
import RegisterAddress from "./RegisterAddress";
import PaymentForm from "./PaymentForm";
import { Context as AuthContext } from "../../Context/AuthContext";
import Order from "./Order";

const Checkout = () => {
  const [step, setStep] = useState(0);
  const { authenticated } = useContext(AuthContext);
  const setselectedRate = useContext(GeneralContext).setselectedRate;
  const selectedRate = useContext(GeneralContext).selectedRate;
  const fretePrice = parseFloat(selectedRate?.price);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [pixDesc, setPixDesc] = useState(0);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState({
    paymentDetails: {
      paymentGateway: "ASAAS",
      paymentMethod: "",
      discountPercentage: 0,
      creditCardDetails: {
        holderName: "",
        number: "",
        expiryMonth: "",
        expiryYear: "",
        flag: "",
        ccv: "",
        holderInfo: {
          name: "",
          email: "",
          cpfCnpj: "",
          postalCode: "",
          addressNumber: "",
          addressComplement: "",
          phone: "",
        },
        CreditCardNumber: "",
        paymentInfo: {
          installmentCount: 0,
          installmentValue: 0,
        },
      },
    },
    shippingMethod: "",
    shippingPackage: {
      height: 17,
      width: 17,
      length: 21,
      weight: 0,
      insurance_value: 1,
    },
    billingAddressId: "",
    shippingAddressId: "",
    shippingTime: "",
    userId: "",
    status: "",
    items: [],
  });
  const [confirmedOrder, setConfirmedOrder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [underSend, setUnderSend] = useState(false);
  const {
    itemsInCart,
    clearItemsInCart,
    setItemsInCart,
    numInCart,
    setNumInCart,
    cupom,
    cupomApply,
    setCupomApply,
    setShippingInfo,
    user,
    setUser,
    clearCupom,
  } = useContext(GeneralContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  useEffect(() => {
    if (authenticated || step === 0 || step === 1) {
      api.get("me").then((res) => {
        setUser(res.data);
      });
    }
    scrollToTop();
  }, [authenticated, step]);

  useEffect(() => {
    // Calcula o subtotal baseado apenas no preço dos produtos e quantidade
    const subtotal = itemsInCart.reduce((acc, item) => {
      const price = user?.isCompany
        ? item.vendorPriceNew > 0
          ? item.vendorPriceNew
          : item.vendorPrice
        : item.customerPriceNew > 0
        ? item.customerPriceNew
        : item.customerPrice;
      return acc + price * item.quantity;
    }, 0);

    // Calcula o peso total
    const totalWeight = itemsInCart.reduce(
      (acc, item) => acc + item.weight * item.quantity,
      0
    );

    // Atualiza o estado do pacote de envio
    setOrder((prevOrder) => ({
      ...prevOrder,
      shippingPackage: {
        ...prevOrder.shippingPackage,
        weight: totalWeight / 1000,
      },
    }));

    // Inicializa o total com o subtotal
    let total = 0;
    total += subtotal;

    // Aplica desconto de 5% se o método de pagamento for "PIX"
    if (order.paymentDetails.paymentMethod === "PIX") {
      const pixDiscount = subtotal * 0.05;
      setPixDesc(pixDiscount.toFixed(2));
      total -= pixDiscount;
    }

    // Aplica desconto de cupom, se houver
    if (cupomApply.discount > 0) {
      const discount = subtotal * (cupomApply.discount / 100); // Assume que o valor do cupom é uma porcentagem
      setDesconto(discount.toFixed(2));
      total -= discount;
    }

    // Adiciona o frete ao total, se aplicável
    if (selectedRate && !isNaN(fretePrice)) {
      const minAmountForFreeShipping = user?.isCompany ? 399 : 199;
      if (total < minAmountForFreeShipping) {
        total += fretePrice;
      }
    }

    // Atualiza os estados do subtotal e total
    setSubtotal(subtotal.toFixed(2));
    setTotal(total.toFixed(2));

    console.log("Total dentro do useEffect", total);
  }, [
    itemsInCart,
    cupomApply,
    selectedRate,
    user?.isCompany,
    fretePrice,
    order.paymentDetails.paymentMethod,
  ]);

  useEffect(() => {
    // Mapeie cada itemInCart para a nova estrutura desejada
    const newItems = itemsInCart.map((item) => {
      const {
        id,
        vendorPrice,
        vendorPriceNew,
        customerPrice,
        customerPriceNew,
        ...itemWithoutId
      } = item;
      return {
        orderProductId: id,
        price:
          user && user.isCompany
            ? vendorPriceNew > 0
              ? vendorPriceNew
              : vendorPrice
            : customerPriceNew > 0
            ? customerPriceNew
            : customerPrice,
        ...itemWithoutId,
      };
    });

    // Atualize o estado order com a nova lista de itens usando setOrder
    setOrder((prevOrder) => ({
      ...prevOrder,
      items: newItems,
    }));
  }, [itemsInCart, user]);

  useEffect(() => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      paymentDetails: {
        ...prevOrder.paymentDetails,
        discountPercentage: cupomApply?.discount > 0 ? cupomApply.discount : 0,
      },
    }));
  }, [cupomApply]);

  useEffect(() => {
    setOrder((prevOrder) => {
      // Verifica se `user` e `user.isCompany` estão definidos
      const isCompany = user?.isCompany ?? false;

      // Determina o valor do shippingPrice com base nas condições
      const shippingPrice = isCompany
        ? subtotal <= 399
          ? fretePrice
          : 0
        : subtotal <= 199
        ? fretePrice
        : 0;

      // Retorna o estado atualizado
      return {
        ...prevOrder,
        shippingPrice,
      };
    });
  }, [fretePrice, subtotal, user?.isCompany]); // Atualiza as dependências

  useEffect(() => {
    if (user) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        userId: user.id,
      }));
      setOrder((prevOrder) => ({
        ...prevOrder,
        paymentDetails: {
          ...prevOrder.paymentDetails,
          creditCardDetails: {
            ...prevOrder.paymentDetails.creditCardDetails,
            holderInfo: {
              ...prevOrder.paymentDetails.creditCardDetails.holderInfo,
              name: user.name,
              email: user.email,
              cpfCnpj: user.docNumber,
              addressNumber: user.billingAddress?.number.toString(),
              postalCode: user.billingAddress?.zipCode.toString(),
              addressComplement: user.billingAddress?.complement,
              phone: user.phone,
            },
          },
        },
      }));
    }
  }, [user]);

  useEffect(() => {
    if (order.status === "ready") sendOrder();
  }, [order.status]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const sendOrder = async () => {
    setUnderSend(true);
    try {
      const fetchOrder = async () => {
        if (order.paymentDetails.paymentMethod === "CREDIT_CARD") {
          const { status, ...normalOrder } = order;
          try {
            const response = await api.post("/orders", normalOrder);
            if (response.status === 201) {
              setConfirmedOrder(response.data);
              clearItemsInCart();
              setShippingInfo([]);
              setselectedRate({});
              setWarning({
                type: "success",
                message: "Compra no Cartão de Crédito realizada com sucesso!",
              });
              handleOpenSnackbar();
              clearCupom();
              setUnderSend(false);
              setStep(step + 1);
            }
          } catch (error) {
            setWarning({
              type: "error",
              message:
                "Ocorreu um erro inesperado no processamento da sua compra no Cartão de Crédito. Tente novamente mais tarde.",
            });
            setUnderSend(false);
            handleOpenSnackbar();
            setOrder((prevOrder) => ({ ...prevOrder, status: "error" }));
          }
        } else {
          const orderPayload = {
            paymentDetails: {
              paymentGateway: order.paymentDetails.paymentGateway,
              paymentMethod: order.paymentDetails.paymentMethod,
              discountPercentage: order.paymentDetails.discountPercentage,
            },
            shippingMethod: order.shippingMethod,
            billingAddressId: order.billingAddressId,
            shippingAddressId: order.shippingAddressId,
            shippingPackage: order.shippingPackage,
            shippingPrice: order.shippingPrice,
            userId: order.userId,
            shippingTime: order.shippingTime,
            items: order.items,
          };

          try {
            const response = await api.post("/orders", orderPayload);
            if (response.status === 201) {
              setConfirmedOrder(response.data);
              clearItemsInCart();
              setShippingInfo([]);
              setselectedRate({});
              setWarning({
                type: "success",
                message: `Compra no ${
                  order.paymentDetails.paymentMethod === "PIX"
                    ? "PIX"
                    : "Boleto"
                } realizada com sucesso!`,
              });
              handleOpenSnackbar();
              setUnderSend(false);
              clearCupom();
              setStep(step + 1);
            }
          } catch (error) {
            setWarning({
              type: "error",
              message: `Ocorreu um erro inesperado na geração do seu código ${
                order.paymentDetails.paymentMethod === "PIX" ? "PIX" : "boleto"
              }. Tente novamente mais tarde.`,
            });
            handleOpenSnackbar();
            setUnderSend(false);
            setOrder((prevOrder) => ({ ...prevOrder, status: "error" }));
          }
        }
      };

      if (cupomApply.discount > 0) {
        const res = await api.put(`vouchers/${cupomApply.code}/usage`, {
          voucherCode: cupomApply.code,
          userId: user.id,
        });
        res.status === 200 && fetchOrder();
      }
      fetchOrder();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorMessage = error.response.data.message || error.message;

          if (
            errorMessage ===
            "Voucher is unique and has already been used by this user"
          ) {
            setWarning({
              type: "error",
              message: "O voucher é único e já foi utilizado por este usuário.",
            });
            setUnderSend(false);
            handleOpenSnackbar();
          } else {
            setWarning({
              type: "error",
              message:
                "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
            });
            setUnderSend(false);
            handleOpenSnackbar();
          }
        } else {
          setWarning({
            type: "error",
            message:
              "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
          });
          setUnderSend(false);
          handleOpenSnackbar();
        }
      } else if (error.request) {
        setWarning({
          type: "error",
          message:
            "Não foi possível se comunicar com o servidor. Por favor, tente novamente mais tarde.",
        });
        setUnderSend(false);
        handleOpenSnackbar();
      } else {
        setWarning({
          type: "error",
          message:
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.",
        });
        setUnderSend(false);
        handleOpenSnackbar();
      }
    }
  };

  const handleFrete = (selectedAddress) => {
    const pesoTotal = order?.items?.reduce((total, item) => {
      return total + item.weight * item.quantity;
    }, 0); // O segundo argumento (0) é o valor inicial do total

    try {
      api
        .post("me/shipping/calc", {
          to: {
            postal_code: selectedAddress.toString(),
          },
          package: {
            height: 17,
            width: 17,
            length: 21,
            weight: pesoTotal / 1000,
            insurance_value: 1,
          },
        })
        .then((res) => {
          setShippingInfo(res.data);
        });
    } catch (error) {}
  };

  return (
    <section className="flex flex-col items-center justify-center w-full mt-0 xs:pt-[167px] sm:pt-[152px] md:pt-[177px]">
      {step >= 0 && step <= 2 && (
        <Stepper
          className="w-full"
          steps={["Sacola", "Endereço", "Pagamento", "Recibo"]}
          currentStep={step}
          setStep={setStep}
        />
      )}

      {step === 0 ? (
        <Resume
          setItemsInCart={setItemsInCart}
          setStep={setStep}
          step={step}
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          setNumInCart={setNumInCart}
          numInCart={numInCart}
          itemsInCart={itemsInCart}
          cupom={cupom}
          cupomApply={cupomApply}
          setCupomApply={setCupomApply}
          total={total}
          desconto={desconto}
          setDesconto={setDesconto}
          subtotal={subtotal}
          setFrete={setselectedRate}
          frete={fretePrice}
          isCompany={user ? user.isCompany : false}
          user={user}
        />
      ) : step === 1 ? (
        user?.shippingAddress?.length > 0 ? (
          <div className="flex flex-col w-[70%]">
            <Address
              user={user}
              order={order}
              setOrder={setOrder}
              subtotal={subtotal}
              step={step}
              setStep={setStep}
              setFrete={setselectedRate}
              handleFrete={handleFrete}
            />
            <div className="flex items-center justify-center p-4">
              <button
                type="button"
                onClick={openModal}
                className="rounded-3xl bg-black w-[200px] h-[40px] px-4 py-2 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              >
                Cadastrar novo endereço
              </button>
            </div>
            <RegisterAddress
              user={user}
              setOrder={setOrder}
              step={step}
              setStep={setStep}
              setWarning={setWarning}
              handleOpenSnackbar={handleOpenSnackbar}
              closeModal={closeModal}
              isOpen={isOpen}
              handleFrete={handleFrete}
            />
          </div>
        ) : (
          <div className="flex flex-col w-[70%]">
            <div className="flex items-center justify-center mt-10">
              <button
                type="button"
                onClick={openModal}
                className="rounded-3xl bg-black w-[200px] h-[40px] px-4 py-2 text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
              >
                Cadastrar novo endereço
              </button>
            </div>
            <RegisterAddress
              user={user}
              setOrder={setOrder}
              step={step}
              setStep={setStep}
              setWarning={setWarning}
              handleOpenSnackbar={handleOpenSnackbar}
              closeModal={closeModal}
              isOpen={isOpen}
              handleFrete={handleFrete}
            />
          </div>
        )
      ) : step === 2 ? (
        <PaymentForm
          order={order}
          setOrder={setOrder}
          setStep={setStep}
          step={step}
          subtotal={subtotal}
          total={total}
          frete={fretePrice}
          setFrete={setselectedRate}
          cupom={cupom}
          desconto={desconto}
          underSend={underSend}
          setDesconto={setDesconto}
          setCupomApply={setCupomApply}
          cupomApply={cupomApply}
          pixDesc={pixDesc}
          user={user}
        />
      ) : step === 3 ? (
        <Order
          user={user}
          cupom={cupomApply}
          order={confirmedOrder.order}
          payment={confirmedOrder.order.asaasPaymentDetails}
          setStep={setStep}
          step={step}
        />
      ) : (
        <Home />
      )}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </section>
  );
};

export default Checkout;
