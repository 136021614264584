import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Alerts from "../../../Alerts";
import Edit from "./Edit";

const Endereço = ({ userId, billingAddress, shippingAddress }) => {
  const [billAddress, setBillAddress] = useState(
    billingAddress ? billingAddress : null
  );
  const [shipAddress, setShipAddress] = useState(
    shippingAddress ? shippingAddress : []
  );
  const [selectedAddress, setSelectedAddress] = useState("");
  const [address, setAddress] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});

  useEffect(() => {
    if (billingAddress) {
      setBillAddress(billingAddress);
    }
    if (shippingAddress) {
      setShipAddress(shippingAddress);
    }
  }, [billingAddress, shippingAddress]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  function closeModal() {
    setIsOpen(false);
  }
  const handleAddress = (address) => {
    setAddress(address);
  };
  function openModal() {
    setIsOpen(true);
  }

  return (
    <div
      className="space-y-4 overflow-auto"
      style={{ maxHeight: "calc(100vh - 30rem)" }}
    >
      <h2 className="text-xl font-bold mb-3">Endereço de Cobrança</h2>
      <AddressItem
        addressType="billing"
        openModal={openModal}
        handleAddress={handleAddress}
        setSelectedAddress={setSelectedAddress}
        address={billAddress}
      />
      <h2 className="text-xl font-bold mb-3">Endereços de Entrega</h2>
      {shipAddress.map((address, index) => (
        <AddressItem
          index={index}
          addressType="shipping"
          openModal={openModal}
          handleAddress={handleAddress}
          setSelectedAddress={setSelectedAddress}
          address={address}
        />
      ))}

      {address && userId && (
        <Edit
          address={address}
          userId={userId}
          addressType={selectedAddress.type}
          addressId={selectedAddress.id}
          setWarning={setWarning}
          handleOpenSnackbar={handleOpenSnackbar}
          closeModal={closeModal}
          isOpen={isOpen}
        />
      )}

      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />
    </div>
  );
};

export default Endereço;

const AddressItem = ({
  address,
  openModal,
  setSelectedAddress,
  index,
  handleAddress,
  addressType,
}) => (
  <div
    key={index ? index : address.id}
    className={`m-2 p-4 max-w-sm border-2 rounded-lg shadow-md flex flex-col`}
  >
    <div className="self-start mb-2 border-blue-600 bg-blue-700 px-3 py-1 rounded  ">
      {/* Adicionando classes do Tailwind CSS */}
      <div
        onClick={() => {
          setSelectedAddress({ id: address.id, type: addressType });
          handleAddress(address);
          openModal();
        }}
        className="text-white cursor-pointer"
      >
        Editar
      </div>
    </div>
    <div>
      <h3 className="text-lg font-semibold">{address.name}</h3>
      <p className="text-gray-600">
        <HomeIcon fontSize="small" className="align-bottom mr-1" />
        {address.street}, {"Nº "}
        {address.number} {address.complement && `- ${address.complement}`}
      </p>
      <p className="text-gray-600">
        <LocationCityIcon fontSize="small" className="align-bottom mr-1" />
        {address.city}, {address.state}, {address.neighborhood}
      </p>
      <p className="text-gray-600">
        <PublicIcon fontSize="small" className="align-bottom mr-1" />
        {address.country}
      </p>
      <p className="text-gray-600">
        <MailOutlineIcon fontSize="small" className="align-bottom mr-1" />
        CEP: {address.zipCode}
      </p>
    </div>
  </div>
);
