import { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Importing icon from MUI
import IconButton from "@mui/material/IconButton"; // Importing IconButton from MUI

const ScrollToTop = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="fixed bottom-4 left-4 z-20">
      {showScrollButton && (
        <IconButton
          onClick={scrollToTop}
          className="fixed bottom-4 left-4  text-white hover:bg-gray-800 rounded-full"
          aria-label="Scroll to top"
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ScrollToTop;
