import React from "react";
import {
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "tailwindcss/tailwind.css";

const PerguntasFrequentes = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center p-4">
      <Container className=" p-8 rounded  max-w-md w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-4">
          PERGUNTAS FREQUENTES
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>O SITE É CONFIÁVEL?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A loja ZP Cosméticos está a mais de 4 anos no mercado com milhares
              de pedidos entregues. Temos como valores o respeito aos nossos
              clientes e nosso compromisso é proporcionar ótimas experiências de
              compra para você, com credibilidade, segurança e satisfação.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>É SEGURO QUE VOU RECEBER MEUS PEDIDOS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Entregamos 100% dos nossos pedidos e além disso usamos o Mercado
              Pago e Cielo como mediadores de pagamento então a entrega é 100%
              garantida. Isso porque você pode recuperar o seu dinheiro caso
              tenha algum problema com a compra. Portanto, pode ficar tranquilo,
              sua compra será entregue ou terá seu dinheiro de volta.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};

export default PerguntasFrequentes;
