import React, { useContext, useEffect, useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";
import GeneralContext from "../../Context/ProductsContext";
import CircularProgress from "@mui/material/CircularProgress";

const ResumeCard = ({
  order,
  isCompany,
  pixDesc,
  total,
  subtotal,
  underSend,
  cupomApply,
  desconto,
  setDesconto,
  setCupomApply,
  triggerFormSubmit,
  cupom,
  validate,
}) => {
  const [inputCupom, setInputCupom] = useState("");

  const shippingInfo = useContext(GeneralContext).shippingInfo;
  const clearCupom = useContext(GeneralContext).clearCupom;
  const selectedRate = useContext(GeneralContext).selectedRate;
  const fretePrice = parseFloat(selectedRate?.price);
  const [allSet, setAllSet] = useState(false);

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  const checkCupom = () => {
    const foundCupom = cupom.find(
      (item) => item.code.toUpperCase() === inputCupom?.toUpperCase()
    );
    if (foundCupom) {
      setCupomApply(foundCupom);
    } else {
      setCupomApply({});
    }
  };

  useEffect(() => {
    selectedRate ? setAllSet(true) : setAllSet(false);
  }, [selectedRate]);

  const getHoverMessage = () => {
    if (!validate.pMethod && !validate.frete) {
      return "Selecione o método de pagamento e o frete.";
    } else if (!validate.pMethod) {
      return "Selecione o método de pagamento.";
    } else if (!validate.frete) {
      return "Selecione o frete.";
    }
    return "";
  };

  return (
    <div className="w-full md:w-[30%]  flex-col p-4">
      <div className="w-full p-4 bg-white rounded-lg shadow">
        <div className="flex justify-between">
          <span className="font-semibold">Subtotal</span>
          <span>{formatCurrency(subtotal)}</span>
        </div>

        {cupomApply.discount > 0 ? (
          <div className="flex flex-col">
            <div className="flex justify-between text-base text-gray-900">
              <p className="font-medium">Cupom </p>
              <p>{cupomApply.code}</p>
            </div>
            <div className="flex justify-between text-base text-rose-400">
              <p className="font-medium">Desconto </p>
              <p>{cupomApply.discount}%</p>
            </div>
            <div className="flex justify-between text-base text-rose-400">
              <p className="font-medium">Desconto Total</p>
              <p>{formatCurrency(desconto)}</p>
            </div>
            <button
              onClick={clearCupom}
              className="py-2 px-4 bg-red-500 text-black rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Limpar Cupom
            </button>
          </div>
        ) : (
          <Disclosure>
            {({ open }) => (
              <div
                className={`rounded-lg
                  ${
                    open ? "pb-4" : ""
                  } bg-blue-100 text-left text-sm font-medium text-blue-500 mb-2`}
              >
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-500 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 mb-2">
                  <span>Cupom?</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-blue-500 `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 text-sm text-gray-500 bg-blue-100">
                  <div className="flex flex-col p-2 justify-between ">
                    <input
                      type="text"
                      onChange={(e) => setInputCupom(e.target.value)}
                      value={inputCupom}
                      className="w-half border-2 mb-2 border-blue-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none focus:border-blue-500"
                      placeholder="Cupom"
                    />

                    <button
                      type="button"
                      className="flex items-center justify-center rounded-md border border-transparent bg-blue-600 hover:bg-blue-700 px-6 py-1 text-base font-medium text-white shadow-sm "
                      onClick={checkCupom}
                    >
                      Aplicar
                    </button>
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        )}

        {order.paymentDetails.paymentMethod === "PIX" && (
          <div className="flex justify-between mb-3 text-rose-400">
            <span className="font-semibold">Pix 5%</span>
            <span>-{formatCurrency(pixDesc)}</span>
          </div>
        )}

        {selectedRate ? (
          <div className="flex justify-between mb-3">
            <span className="font-semibold">Frete</span>
            <span>
              {isCompany
                ? subtotal < 399
                  ? formatCurrency(fretePrice)
                  : "Frete Grátis"
                : subtotal < 199
                ? formatCurrency(fretePrice)
                : "Frete Grátis"}
            </span>
          </div>
        ) : (
          <div className="flex justify-between mb-3">
            <span className="font-semibold">Frete</span>
            <span>R$ 0,00</span>
          </div>
        )}

        <div className="border-t mt-3 pt-3 flex justify-between">
          <span className="font-semibold">Total</span>
          <span className="font-semibold">{formatCurrency(total)}</span>
        </div>
      </div>
      <div className="px-8 py-4 rounded-b-lg justify-center flex flex-col text-center">
        <button
          className={`${
            underSend || !(validate.pMethod && validate.frete)
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-black hover:bg-gray-900"
          }  w-full max-w-md  text-white font-bold py-2 px-4 rounded-3xl transition-colors`}
          type="button"
          id="purchase"
          onClick={triggerFormSubmit}
          disabled={underSend || !(validate.pMethod && validate.frete)}
          title={getHoverMessage()}
        >
          {underSend ? <CircularProgress size={24} thickness={4} /> : "Comprar"}
        </button>
        <p className="text-sm text-red">{getHoverMessage()}</p>
      </div>
    </div>
  );
};

export default ResumeCard;
