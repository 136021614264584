import React, { useState, useContext } from "react";
import logo from "../assets/Logo.svg";
import api from "../api/api";
import { useNavigate, Link } from "react-router-dom";
import GeneralContext from "../Context/ProductsContext";
import { Context as AuthContext } from "../Context/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";

const LoginForm = () => {
  const { setUser } = useContext(GeneralContext);
  const { handleLogin } = useContext(AuthContext);
  const [underSend, setUnderSend] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setErrors([]); // Limpa erros anteriores
    try {
      setUnderSend(true);
      const response = await api.post("/store/sessions", {
        username,
        password,
      });

      if (response.status === 200 && response.data.token) {
        // Armazena o token JWT e configura o cabeçalho de autorização
        handleLogin(response.data.token);

        const userDataResponse = await api.get("/me");

        if (userDataResponse.status === 200) {
          localStorage.setItem("user", JSON.stringify(userDataResponse.data));
          localStorage.setItem(
            "userFav",
            JSON.stringify(userDataResponse.data.favoriteProductIds)
          );
          const intendedLocation = localStorage.getItem("intendedLocation");

          // Redireciona o usuário para a localização pretendida
          if (intendedLocation) {
            navigate(intendedLocation);
            // Limpa a localização pretendida do armazenamento local
            localStorage.removeItem("intendedLocation");
          } else {
            // Se nenhuma localização pretendida for encontrada, redireciona para a página inicial ou uma rota padrão
            navigate("/");
          }
        } else {
          // Tratar caso a resposta não seja 200 ou não contenha um token
          setErrors(["Erro de login inesperado"]);
        }
      } else {
        setErrors(["Erro de login inesperado"]);
      }
    } catch (error) {
      if (error.response) {
        // Erros de resposta da API
        setErrors(["Email ou senha incorretos, verifique e tente novamente!"]);
      } else {
        // Erros de rede ou desconhecidos
        console.error(error);
        setErrors(["Erro de conexão ou problema de rede"]);
      }
    } finally {
      setUnderSend(false);
    }
  };

  return (
    <div className="bg-white">
      <main className="flex-col justify-center items-center h-screen mt-10">
        <div className="max-w-2xl px-4 sm:px-6 lg:px-8 m-auto">
          <div className="text-center">
            <a href="/" className="flex justify-center">
              <img src={logo} className="w-half h-full" alt="Logo" />
            </a>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-dark-blue">
              Acesse sua conta
            </h2>
            <form className="space-y-6 mt-6" onSubmit={onSubmitHandler}>
              {errors.map((error, idx) => (
                <p style={{ color: "red" }} key={error + idx}>
                  {error}
                </p>
              ))}
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div>
                  <input
                    type="text"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    placeholder="email@provedor.com"
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div>
                  <input
                    type="password"
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="**************************"
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={underSend}
                className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
              >
                {underSend ? (
                  <CircularProgress size={24} thickness={4} />
                ) : (
                  "Login"
                )}
              </button>
            </form>
            <Link to="/cadastro">
              <h3 className="mt-6 text-sm font-medium text-dark-blue hover:text-gray-900">
                Novo Aqui? Crie sua conta &rarr;
              </h3>
            </Link>
            <Link to="/reset-password">
              <h3 className="mt-6 text-sm font-medium text-dark-blue hover:text-gray-900">
                Esqueceu sua senha? &rarr;
              </h3>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoginForm;
