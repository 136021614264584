import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "tailwindcss/tailwind.css";

const ComoRealizarSuaCompra = () => {
  return (
    <div className="min-h-screen  flex items-center justify-center p-4 mt-24">
      <Container className=" p-8 rounded  max-w-md w-full">
        <Typography variant="h4" component="h1" className="font-bold pb-6">
          COMO REALIZAR SUA COMPRA
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Veja abaixo como realizar sua compra em nosso site:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Escolha o produto que deseja comprar." />
          </ListItem>
          <ListItem>
            <ListItemText primary='Clique no botão de "Comprar". O seu produto será adicionado ao carrinho e você poderá seguir comprando, caso desejar.' />
          </ListItem>
          <ListItem>
            <ListItemText primary='Você pode seguir agregando outros produtos ao carrinho ou, então, clicar em "Finalizar Compra".' />
          </ListItem>
          <ListItem>
            <ListItemText primary="Complete com seus dados de contato e clique em 'Continuar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Coloque o endereço de onde você deseja receber o produto. Logo clique em 'Continuar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Selecione a forma de frete que desejar e clique em 'Continuar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Escolha o meio de pagamento. (Cartão de crédito em até 12x, Pix ou Boleto bancário)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Uma vez que você tenha escolhido o meio de pagamento, clique 'Continuar'." />
          </ListItem>
          <ListItem>
            <ListItemText primary='Finalmente na Confirmação da compra você pode revisar toda a informação sobre a mesma, inclusive os seus dados. Logo clique em "Continuar".' />
          </ListItem>
          <ListItem>
            <ListItemText primary="Você será redirecionado para outra tela para que complete os dados sobre a forma de pagamento escolhida. Depois de confirmar a compra receberá um email da nossa parte, esse não será um comprovante de pagamento." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Uma vez creditado o pagamento, faremos um envio correspondente dos produtos que você comprou." />
          </ListItem>
        </List>
        <Typography variant="body1" className="text-black mt-4">
          Equipe ZP Cosméticos.
        </Typography>
      </Container>
    </div>
  );
};

export default ComoRealizarSuaCompra;
