import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Box,
  Rating,
  Paper,
  IconButton,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ReportIcon from "@mui/icons-material/Report";
import { grey } from "@mui/material/colors";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Review = ({ photoUrl, name, rating, title, description }) => {
  const [commentsVoted, setCommentsVoted] = useState([]);

  const handleVote = (voteType) => {
    // Verifica se o voteType já existe no array commentsVoted
    if (!commentsVoted.includes(voteType)) {
      setCommentsVoted((actualVotes) => [...actualVotes, voteType]);
      // Aqui você pode adicionar a lógica para enviar o voto para o servidor ou fazer outras ações necessárias
    } else {
      // Remove o voteType do array commentsVoted
      setCommentsVoted((actualVotes) =>
        actualVotes.filter((type) => type !== voteType)
      );
      // Informe ao usuário que o voto foi removido, se necessário
    }
  };

  const isVoted = (voteType) => commentsVoted.includes(voteType);

  return (
    <Paper elevation={3} style={{ padding: "16px", marginBottom: "16px" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={photoUrl} alt={name} style={{ marginRight: "16px" }} />
        <Typography variant="subtitle1" component="span">
          {name}
        </Typography>
      </Box>
      <Box mb={2}>
        <Rating name="read-only" value={rating} readOnly />
      </Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{
          height: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {description}
      </Typography>

      <Box mt={2}>
        <IconButton
          aria-label="útil"
          onClick={() => handleVote("útil")}
          sx={{
            "&:hover": {
              backgroundColor: "#007BFF",
              color: "#FFFFFF",
            },
            "&:active": {
              color: "#0056b3",
            },
            color: isVoted("útil") ? "#0056b3" : grey[400],
          }}
        >
          <ThumbUpIcon />
        </IconButton>

        <IconButton
          aria-label="inútil"
          onClick={() => handleVote("inútil")}
          sx={{
            "&:hover": {
              backgroundColor: "#DC3545",
              color: "#FFFFFF",
            },
            "&:active": {
              color: "#bd2130",
            },
            color: isVoted("inútil") ? "#bd2130" : grey[400],
          }}
        >
          <ThumbDownIcon />
        </IconButton>

        <IconButton
          aria-label="reportar"
          onClick={() => handleVote("reportar")}
          sx={{
            "&:hover": {
              backgroundColor: "#FFC107",
              color: "#FFFFFF",
            },
            "&:active": {
              color: "#e0a800",
            },
            color: isVoted("reportar") ? "#e0a800" : grey[400],
          }}
        >
          <ReportIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

const ReviewCarousel = ({ reviews }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Desabilita as setas de navegação
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="p-4 w-full">
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="p-2">
            <Review
              photoUrl={review.photoUrl}
              name={review.name}
              rating={review.rating}
              title={review.title}
              description={review.description}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewCarousel;
