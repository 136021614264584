import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import api from "../../../api/api";
import * as TBicon from "react-icons/tb";
import GeneralContext from "../../../Context/ProductsContext";
import { Context as AuthContext } from "../../../Context/AuthContext";
import Alerts from "../../Alerts";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import defaultImage from "../../../assets/noImage.jpg";
import SlidingCart from "../../SlidingCart";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isPromotionActive } from "../../../utils/ValidatePromotion";

const CardProdutos = (props) => {
  const { authenticated } = useContext(AuthContext);
  const [userFav, setUserFav] = useState([]);
  const [userId, setUserId] = useState("");
  const products = props.data;
  const href = props.href;
  const key = props.key;
  const [isCompany, setIsCompany] = useState(false);
  const [item, setItem] = useState([]);
  const itemsInCart = useContext(GeneralContext).itemsInCart;
  const setItemsInCart = useContext(GeneralContext).setItemsInCart;
  const setNumInCart = useContext(GeneralContext).setNumInCart;
  const isHome = props.isHome;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const [slideOpen, setSlideOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const openCart = (slide) => {
    setLoaded(slide);
  };

  function formatUrl(string) {
    return string.trim().replace(/\s+/g, "-").toLowerCase();
  }

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  function calculateDiscountPercentage(originalPrice, discountedPrice) {
    if (originalPrice <= 0) return 0;
    return Math.round(
      ((originalPrice - discountedPrice) / originalPrice) * 100
    );
  }

  const formatPromo = (props) => {
    return isCompany &&
      props.vendorPriceNew > 0 &&
      isPromotionActive(props.discountVendorDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.vendorPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.vendorPrice,
              props.vendorPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.vendorPriceNew)} `}
          </span>
          <span className="text-sm text-black">{`ou 4x de ${formatCurrency(
            props.vendorPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : !isCompany &&
      props.customerPriceNew > 0 &&
      isPromotionActive(props.discountCustomerDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.customerPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.customerPrice,
              props.customerPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.customerPriceNew)} `}
          </span>
          <span className="text-sm text-black">{` ou 4x de ${formatCurrency(
            props.customerPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : (
      <div className="p-2">
        <span className="text-xl font-bold ">{`${formatCurrency(
          isCompany ? props.vendorPrice : props.customerPrice
        )}`}</span>
        <span className="text-sm text-black">{` ou 4 x de ${formatCurrency(
          isCompany ? props.vendorPrice / 4 : props.customerPrice / 4
        )}`}</span>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520);
    };

    // Executa uma vez para definir o estado inicial
    handleResize();

    // Adiciona um listener de resize para atualizar o estado quando a janela for redimensionada
    window.addEventListener("resize", handleResize);

    // Remove o listener de resize quando o componente é desmontado para evitar vazamentos de memória
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Carrega os favoritos do localStorage quando o componente é montado
  useEffect(() => {
    const userFavorites = JSON.parse(localStorage.getItem("userFav")) || [];
    setUserFav(userFavorites);
  }, []);

  useEffect(() => {
    setItem(products);
  }, [products]);

  useEffect(() => {
    if (authenticated) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUserId(user?.id);
      setIsCompany(user?.isCompany);
    }
  }, [authenticated]);

  const addToCart = (productToAdd) => {
    let currentProducts = itemsInCart;

    // Flag to check if product exists in the cart
    let productExists = false;

    // Check if the productToAdd is already in the cart
    currentProducts = currentProducts.map((product) => {
      if (product.id === productToAdd.id) {
        // Assuming 'id' is the unique identifier
        productExists = true;
        return { ...product, quantity: (product.quantity || 0) + 1 };
      }
      return product;
    });

    // If the product is not in the cart, add it
    if (!productExists) {
      currentProducts.push({ ...productToAdd, quantity: 1 });
    }

    // Update localStorage and state
    localStorage.setItem("itemsInCart", JSON.stringify(currentProducts));
    localStorage.setItem(
      "numInCart",
      currentProducts.reduce((acc, product) => acc + product.quantity, 0)
    );
    setItemsInCart(currentProducts);
    setNumInCart(
      currentProducts.reduce((acc, product) => acc + product.quantity, 0)
    );
    setWarning({ type: "success", message: "Produto adicionado a sacola!" });
    handleOpenSnackbar();
    openCart(true);
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ ...style, left: "15px", zIndex: 10 }}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          right: "35px",
          zIndex: 10,
        }}
      />
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Mostra as setas de navegação
    prevArrow: <PrevArrow />, // Componente ou ícone para a seta anterior
    nextArrow: <NextArrow />, // Componente ou ícone para a seta seguinte
  };

  return (
    <div className="my-5 max-w-7xl" key={key ? key : ""}>
      {/* Alertas */}
      <Alerts
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        type={warning.type}
        message={warning.message}
      />

      {/* Título da seção e link "Ver tudo" */}
      <div className="flex flex-row justify-between items-center px-2 md:px-4">
        <h1 className="text-2xl font-bold text-gray-800 break-words">
          {props.title}
        </h1>
        {isHome ? (
          <Link
            to={href && href !== "" ? `/${href}` : "/produtos"}
            className="text-black font-bold hover:text-white hover:bg-black transition duration-300 border border-black rounded-xl py-1 px-4 whitespace-nowrap mt-2 md:mt-0"
          >
            <span>Ver tudo</span>
          </Link>
        ) : null}
      </div>

      {/* Container para os cards de produtos */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {isMobile ? (
          <Slider {...settings}>
            {item.slice(0, 4).map((product, index) => (
              <div key={product.id} className="rounded-lg overflow-hidden">
                {/* Card de produto */}
                <div className="p-2 flex flex-col justify-center items-center">
                  {/* Botão de "Curtir" 
                  <div className="self-end">
                    <button
                      onClick={() => {
                        // Função para favoritar o produto
                        const favoriteProduct = async () => {
                          try {
                            let response;

                            // Verifica se o produto já está na lista de favoritos
                            if (userFav.includes(product.id)) {
                              // Remove o produto dos favoritos
                              response = await api.put(`users/${userId}`, {
                                disconnectProducts: [product.id],
                              });
                            } else {
                              // Adiciona o produto aos favoritos
                              response = await api.put(`users/${userId}`, {
                                connectProducts: [product.id],
                              });
                            }

                            // Verifica se a resposta foi bem-sucedida
                            if (
                              response.status !== 200 &&
                              response.status !== 204
                            ) {
                              throw new Error(
                                "Erro ao atualizar o produto nos favoritos"
                              );
                            }

                            const data = response.data;
                            console.log(
                              "Produto atualizado com sucesso nos favoritos:",
                              data
                            );

                            // Atualiza o estado de favoritos localmente
                            const updatedFavorites = [...userFav];
                            if (updatedFavorites.includes(product.id)) {
                              // Remove dos favoritos se já estiver favoritado
                              const index = updatedFavorites.indexOf(
                                product.id
                              );
                              updatedFavorites.splice(index, 1);
                            } else {
                              // Adiciona aos favoritos
                              updatedFavorites.push(product.id);
                            }
                            setUserFav(updatedFavorites);

                            // Atualiza o localStorage
                            localStorage.setItem(
                              "userFav",
                              JSON.stringify(updatedFavorites)
                            );
                          } catch (error) {
                            console.error(
                              "Erro ao atualizar o produto nos favoritos:",
                              error
                            );
                          }
                        };

                        // Chama a função para favoritar o produto
                        favoriteProduct();
                      }}
                      className="text-red hover:text-red transition duration-300"
                    >
                      {userFav.includes(product.id) ? (
                        <FavoriteIcon />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )}
                    </button>
                  </div>*/}
                  <div className="group block w-full  overflow-hidden transition-shadow duration-300">
                    {/* Imagem do Produto */}
                    <Link
                      to={`/produto/${formatUrl(product.nameMod)}`}
                      className="block w-full"
                    >
                      <img
                        src={product.imgUrl ? product.imgUrl : defaultImage}
                        alt={product.name}
                        className="w-full md:h-[300px] object-cover transform transition-transform duration-300 md:group-hover:scale-[1.3]"
                      />
                    </Link>

                    {/* Nome e preço do produto */}
                    <div className="mt-4 text-center relative ">
                      <Link to={`/produto/${formatUrl(product.nameMod)}`}>
                        <h4 className="text-lg font-bold line-clamp-2 md:h-14 bg-white">
                          {product.name}
                        </h4>
                      </Link>

                      <Link
                        to={`/produto/${formatUrl(product.nameMod)}`}
                        className="mt-1 flex items-center justify-center space-x-2"
                      >
                        {!isCompany && product.customerPrice === 0 ? (
                          <span className="text-md font-bold text-red">
                            Produto disponível apenas para salão
                          </span>
                        ) : isCompany && product.vendorPrice === 0 ? (
                          <span className="text-md font-bold">
                            Produto indisponível
                          </span>
                        ) : (
                          formatPromo(product)
                        )}
                      </Link>
                    </div>
                    {/* Descrição do Produto */}
                    <div className="p-2 flex justify-center align-middle">
                      {/* Botão "Adicionar a Sacola", visível apenas em hover */}
                      <button
                        onClick={() => addToCart(product)}
                        className={`mt-4 sm:mt-0 inline-flex items-center justify-center rounded-3xl border border-transparent ${
                          isCompany && product.vendorPrice === 0
                            ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                            : !isCompany && product.customerPrice === 0
                            ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                            : ""
                        }  px-1 py-2 text-nowrap text-base font-bold bg-black hover:bg-[#535353] w-[230px] h-[40px] text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2`}
                        disabled={
                          (isCompany && product.vendorPrice === 0) ||
                          (!isCompany && product.customerPrice === 0)
                        }
                      >
                        <ShoppingCartIcon
                          className="h-5 w-5 mr-1 text-nowrap"
                          aria-hidden="true"
                        />
                        Adicionar à sacola
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          item.slice(0, 4).map((product, index) => (
            <div key={product.id} className=" rounded-lg overflow-hidden ">
              {/* Card de produto */}
              <div className="p-2 flex flex-col justify-center items-center">
                {/* Botão de "Curtir" */}
                <div className="self-end">
                  <button
                    onClick={() => {
                      // Função para favoritar o produto
                      const favoriteProduct = async () => {
                        try {
                          let response;

                          // Verifica se o produto já está na lista de favoritos
                          if (userFav.includes(product.id)) {
                            // Remove o produto dos favoritos
                            response = await api.put(`users/${userId}`, {
                              disconnectProducts: [product.id],
                            });
                          } else {
                            // Adiciona o produto aos favoritos
                            response = await api.put(`users/${userId}`, {
                              connectProducts: [product.id],
                            });
                          }

                          // Verifica se a resposta foi bem-sucedida
                          if (
                            response.status !== 200 &&
                            response.status !== 204
                          ) {
                            throw new Error(
                              "Erro ao atualizar o produto nos favoritos"
                            );
                          }

                          const data = response.data;
                          console.log(
                            "Produto atualizado com sucesso nos favoritos:",
                            data
                          );

                          // Atualiza o estado de favoritos localmente
                          const updatedFavorites = [...userFav];
                          if (updatedFavorites.includes(product.id)) {
                            // Remove dos favoritos se já estiver favoritado
                            const index = updatedFavorites.indexOf(product.id);
                            updatedFavorites.splice(index, 1);
                          } else {
                            // Adiciona aos favoritos
                            updatedFavorites.push(product.id);
                          }
                          setUserFav(updatedFavorites);

                          // Atualiza o localStorage
                          localStorage.setItem(
                            "userFav",
                            JSON.stringify(updatedFavorites)
                          );
                        } catch (error) {
                          console.error(
                            "Erro ao atualizar o produto nos favoritos:",
                            error
                          );
                        }
                      };

                      // Chama a função para favoritar o produto
                      favoriteProduct();
                    }}
                    className="text-red hover:text-red transition duration-300"
                  >
                    {userFav.includes(product.id) ? (
                      <FavoriteIcon />
                    ) : (
                      <FavoriteBorderOutlinedIcon />
                    )}
                  </button>
                </div>

                <div className="group block w-full  overflow-hidden transition-shadow duration-300">
                  {/* Imagem do Produto */}
                  <Link
                    to={`/produto/${formatUrl(product.nameMod)}`}
                    className="block w-full"
                  >
                    <img
                      src={product.imgUrl ? product.imgUrl : defaultImage}
                      alt={product.name}
                      className="w-full h-[300px] object-cover transform transition-transform duration-300 group-hover:scale-[1.3]"
                    />
                  </Link>

                  {/* Nome e preço do produto */}
                  <div className="mt-4 text-center relative ">
                    <Link to={`/produto/${formatUrl(product.nameMod)}`}>
                      <h4 className="text-lg font-bold line-clamp-2 md:h-14 bg-white">
                        {product.name}
                      </h4>
                    </Link>

                    <Link
                      to={`/produto/${formatUrl(product.nameMod)}`}
                      className="mt-1 flex items-center justify-center px-4"
                    >
                      {!isCompany && product.customerPrice === 0 ? (
                        <span className="text-md font-bold text-red">
                          Produto disponível apenas para salão
                        </span>
                      ) : isCompany && product.vendorPrice === 0 ? (
                        <span className="text-md font-bold">
                          Produto indisponível
                        </span>
                      ) : (
                        formatPromo(product)
                      )}
                    </Link>
                  </div>
                  {/* Descrição do Produto */}
                  <div className="p-2 flex justify-center align-middle">
                    {/* Botão "Adicionar a Sacola", visível apenas em hover */}
                    <button
                      id="add_to_cart"
                      onClick={() => addToCart(product)}
                      className={`add_to_cart AddToCart mt-4 sm:mt-0 inline-flex items-center justify-center rounded-3xl border border-transparent ${
                        isCompany && product.vendorPrice === 0
                          ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                          : !isCompany && product.customerPrice === 0
                          ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                          : ""
                      }  px-1 py-2 text-nowrap text-base font-bold bg-black hover:bg-[#535353] w-[230px] h-[40px] text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2`}
                      disabled={
                        (isCompany && product.vendorPrice === 0) ||
                        (!isCompany && product.customerPrice === 0)
                      }
                    >
                      <ShoppingCartIcon
                        className="h-5 w-5 mr-1 text-nowrap"
                        aria-hidden="true"
                      />
                      Adicionar à sacola
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Carrinho deslizante */}
      <SlidingCart openCart={openCart} loaded={loaded} />
    </div>
  );
};

export default CardProdutos;
