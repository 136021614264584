import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import api from "../../api/api";
import UniqueOrder from "../Card/Profile/Compras/UniqueOrder";
import { Pagination } from "@mui/material";

const Orders = (props) => {
  const [rows, setRows] = useState([]);
  const [uniqueOrder, setUniqueOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calcula os itens a serem exibidos com base na página atual
  const paginatedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const QuantityTemplate = ({ items }) => {
    const totalQuantity = items.reduce((acc, item) => acc + item.quantity, 0);
    return <span>{totalQuantity}</span>;
  };

  // Event handler that sets the uniqueOrder state
  const handleRowClick = (order) => {
    setUniqueOrder(order);
  };

  useEffect(() => {
    try {
      api.get("/orders").then((response) => {
        const orders = response.data.orders;
        // Filtrando as ordens para obter apenas aquelas que correspondem ao userId
        const userOrders = orders.filter(
          (order) => order.userId === props.user.id
        );
        // Ordenando os pedidos filtrados com base em updatedAt
        const sortedUserOrders = userOrders.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        // Atualizar o estado com as ordens do usuário, ordenadas
        setRows(sortedUserOrders);
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  }, [props.user.id]);

  return props.isMobile ? (
    <>
      {uniqueOrder ? (
        <UniqueOrder
          isMobile={props.isMobile}
          order={uniqueOrder}
          setUniqueOrder={setUniqueOrder}
        />
      ) : (
        <div className="p-4 flex flex-col justify-center align-middle items-center gap-y-4">
          <Pagination
            count={Math.ceil(rows.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            className="mt-4"
          />
          {paginatedRows.map((row) => (
            <div
              key={row.id}
              className="border rounded-md p-4 mb-4 cursor-pointer hover:bg-gray-100"
              onClick={() => handleRowClick(row)}
            >
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-2">
                <div className="flex-1 py-2">
                  <h3 className="text-lg font-semibold">{row.user.name}</h3>
                  <p className="text-sm text-gray-600">
                    ID Pedido: {row.orderCode.toUpperCase()}
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                  <p className="text-sm text-gray-600">Status do Pedido:</p>
                  <StatusSpan status={row.orderStatus} statusType="order" />
                  <p className="text-sm text-gray-600">Status da Entrega:</p>
                  <StatusSpan
                    status={row.shippingStatus}
                    statusType="shipping"
                  />
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="flex-1">
                  <p className="text-sm">
                    Qtd: <QuantityTemplate items={row.items} />
                  </p>
                  <p className="text-sm">Total: R${row.total.toFixed(2)}</p>
                  <p className="text-sm">
                    Cidade de Entrega: {row.orderShippingAddress.city}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <Pagination
            count={Math.ceil(rows.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            className="mt-4"
          />
        </div>
      )}
    </>
  ) : (
    <>
      {uniqueOrder ? (
        <UniqueOrder
          order={uniqueOrder}
          setUniqueOrder={setUniqueOrder}
          isMobile={props.isMobile}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Pedidos">
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell align="right">Qtd</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Status do Pedido</TableCell>
                <TableCell align="right">Status da Entrega</TableCell>
                <TableCell align="right">ID Pedido</TableCell>
                <TableCell align="right">Cidade de Entrega</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover // optional: to highlight the row on mouse over
                  onClick={() => handleRowClick(row)} // Attach the event handler here
                  style={{ cursor: "pointer" }} // optional: to change the mouse to pointer on hover
                >
                  <TableCell component="th" scope="row">
                    {row.user.name}
                  </TableCell>
                  <TableCell align="right">
                    <QuantityTemplate items={row.items} />
                  </TableCell>
                  <TableCell align="right">R${row.total.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    <StatusSpan status={row.orderStatus} statusType="order" />
                  </TableCell>
                  <TableCell align="right">
                    <StatusSpan
                      status={row.shippingStatus}
                      statusType="shipping"
                    />
                  </TableCell>
                  <TableCell align="right">{row.orderCode}</TableCell>
                  <TableCell align="right">
                    {row.orderShippingAddress.city}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const getOrderStatusColor = (status) => {
  switch (status) {
    case "CRIADO":
      return { color: "#B3B3B3", label: "CRIADO" };
    case "AGUARDANDO_PAGAMENTO":
      return { color: "#F2F20C", label: "AGUARDANDO PAGAMENTO" };
    case "PROBLEMAS_PAGAMENTO":
      return { color: "#F3C211", label: "PROBLEMAS PAGAMENTO" };
    case "PAGAMENTO_RECUSADO":
      return { color: "#FF4D4D", label: "PAGAMENTO RECUSADO" };
    case "PAGAMENTO_REALIZADO":
      return { color: "#FFD400", label: "PAGAMENTO REALIZADO" };
    case "EM_SEPARACAO":
      return { color: "#FFAA00", label: "PEDIDO EM SEPARAÇÃO" };
    case "PEDIDO_SEPARADO":
      return { color: "#F28B24", label: "PEDIDO SEPARADO" };
    case "AGUARDANDO_COLETA":
      return { color: "#F28B24", label: "AGUARDANDO COLETA" };
    case "NOTA_FISCAL_EMITIDA":
      return { color: "#22C5E5", label: "NOTA FISCAL EMITIDA" };
    case "PEDIDO_DESPACHADO":
      return { color: "#2681FF", label: "PEDIDO DESPACHADO" };
    case "PEDIDO_ENTREGUE":
      return { color: "#3DCC6D", label: "PEDIDO ENTREGUE" };
    case "ORDEM_FINALIZADA":
      return { color: "#3DCC6D", label: "ORDEM FINALIZADA" };
    case "ORDEM_CANCELADA":
      return { color: "#FF4D4D", label: "ORDEM CANCELADA" };
    default:
      return { color: "#FF4D4D", label: "Desconhecido" };
  }
};

const getShippingStatusColor = (status) => {
  switch (status) {
    case "PENDENTE":
      return { color: "#F2F20C", label: "PENDENTE" };
    case "EM_SEPARACAO":
      return { color: "#FFD400", label: "EM SEPARAÇÃO" };
    case "AGUARDANDO_COLETA":
      return { color: "#F28B24", label: "AGUARDANDO COLETA" };
    case "COLETADO":
      return { color: "#A2C5E5", label: "COLETADO" };
    case "EM_TRANSITO":
      return { color: "#22C5E5", label: "EM TRÂNSITO" };
    case "ENTREGUE":
      return { color: "#3DCC6D", label: "ENTREGUE" };
    case "NAO_ENTREGUE":
      return { color: "#D941A6", label: "NÃO ENTREGUE" };
    case "CANCELADO":
      return { color: "#FF4D4D", label: "CANCELADO" };
    default:
      return { color: "#FF4D4D", label: "Desconhecido" };
  }
};

const StatusSpan = ({ status, statusType }) => {
  const { color, label } =
    statusType === "order"
      ? getOrderStatusColor(status)
      : getShippingStatusColor(status);

  const statusStyles = {
    backgroundColor: color,
    color: "black",
    borderRadius: "16px",
    padding: "5px 10px",
    display: "inline-block",
    fontSize: "0.875rem",
    fontWeight: "500",
    textAlign: "center",
    minWidth: "100px", // Garante que todos os status tenham a mesma largura
  };

  return <span style={statusStyles}>{label}</span>;
};

export default Orders;
