import { useState, useEffect, createContext } from "react";
import api from "../api/api";

const GeneralContext = createContext({});

export const GeneralProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [itemsInCart, setItemsInCart] = useState([]);
  const [numInCart, setNumInCart] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [orderSummary, setOrderSummary] = useState([]);
  const [cupom, setCupom] = useState([]);
  const [cupomApply, setCupomApply] = useState({});
  const [shippingInfo, setShippingInfo] = useState([]);
  const [selectedRate, setselectedRate] = useState({});

  function safelyParseJSON(json) {
    try {
      return JSON.parse(json);
    } catch (e) {
      console.error("Error parsing JSON:", e);
      return null;
    }
  }
  const clearItemsInCart = () => {
    setItemsInCart([]); // Limpa o array de itens no carrinho
    setNumInCart(0); // Reseta o número de itens no carrinho para 0
  };

  useEffect(() => {
    // Attempt to restore both numInCart and itemsInCart from localStorage in one go
    const numInCartData = safelyParseJSON(localStorage.getItem("numInCart"));
    const itemsInCartData = safelyParseJSON(
      localStorage.getItem("itemsInCart")
    );
    const cupomInStorage = safelyParseJSON(
      localStorage.getItem("cupomInStorage")
    );
    if (numInCartData && numInCartData > 0) {
      setNumInCart(numInCartData);
    }

    if (itemsInCartData && itemsInCartData.length > 0) {
      setItemsInCart(itemsInCartData);
    }

    if (cupomInStorage) {
      setCupom(cupomInStorage);
    }
  }, []);

  useEffect(() => {
    api.get("vouchers").then((res) => {
      setCupom(res.data.vouchers);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("itemsInCart", JSON.stringify(itemsInCart));
    localStorage.setItem("numInCart", JSON.stringify(numInCart));
  }, [itemsInCart, numInCart]);

  const clearCupom = () => {
    setCupomApply({});
  };

  return (
    <GeneralContext.Provider
      value={{
        itemsInCart,
        setItemsInCart,
        clearItemsInCart,
        numInCart,
        setNumInCart,
        shippingInfo,
        setShippingInfo,
        allProducts,
        setAllProducts,
        clearCupom,
        cupom,
        cupomApply,
        setCupomApply,
        orderSummary,
        setOrderSummary,
        user,
        setUser,
        selectedRate,
        setselectedRate,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContext;
