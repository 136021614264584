import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import pixInfo from "../../assets/pix-info.png";
import { FaCcVisa, FaCcMastercard, FaCcAmex } from "react-icons/fa6";
import ResumeCard from "./ResumeCard";
import QrPìx from "./QrPix";
import GeneralContext from "../../Context/ProductsContext";

const PaymentForm = ({
  order,
  setOrder,
  underSend,
  total,
  subtotal,
  setFrete,
  cupom,
  cupomApply,
  desconto,
  setDesconto,
  setCupomApply,
  setConfirmedOrder,
  pixDesc,
  user,
}) => {
  const setShippingInfo = useContext(GeneralContext).setShippingInfo;
  const shippingInfo = useContext(GeneralContext).shippingInfo;
  const [shippingSelected, setShippingSelected] = useState({});
  const [selectedFreteId, setSelectedFreteId] = useState(null);
  const setselectedRate = useContext(GeneralContext).setselectedRate;
  const selectedRate = useContext(GeneralContext).selectedRate;
  const fretePrice = parseFloat(selectedRate?.price);
  const [validate, setValidate] = useState({ pMethod: false, frete: false });

  const selectFrete = (id) => {
    setSelectedFreteId(id);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      holderName: "",
      number: "",
      expiryMonth: "",
      expiryYear: "",
      flag: "",
      ccv: "",
    },
  });
  const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year
  const currentMonth = new Date().getMonth() + 1; // getMonth() is 0-indexed

  useEffect(() => {
    register("number");
    register("CreditCardNumber");
    register("holderName");
    register("expiryMonth");
    register("expiryYear");
    register("installmentNumber");
    register("flag");
    register("ccv");
  }, [register]);

  const vFlag = watch("flag");
  const pMethod = watch("paymentMethod");
  const installment = watch("paymentInfo.installmentCount");

  useEffect(() => {
    setValue(
      "paymentInfo.installmentValue",
      parseFloat(subtotal / installment).toFixed(2)
    );
  }, [installment]);

  const onSubmit = (data) => {
    // Update order state with payment details
    const {
      paymentInfo: { installmentCount, installmentValue },
      ...restData
    } = data;
    const formData = { ...restData };
    setOrder((prevOrder) => ({
      ...prevOrder,
      paymentDetails: {
        ...prevOrder.paymentDetails,
        creditCardDetails: {
          ...prevOrder.paymentDetails.creditCardDetails,
          ...formData,
          paymentInfo: {
            installmentCount: parseInt(installmentCount, 10),
            installmentValue: parseFloat(installmentValue),
          },
        },
      },
    }));

    let apiShippingMethod;

    switch (shippingSelected.name) {
      case ".Com":
        apiShippingMethod = "JADLOG_EXPRESS";
        break;
      case ".Package":
        apiShippingMethod = "JADLOG";
        break;
      case "SEDEX":
        apiShippingMethod = "CORREIOS_SEDEX";
        break;
      case "PAC":
        apiShippingMethod = "CORREIOS_PAC";
        break;
      // Inclua outros casos conforme necessário
      default:
        apiShippingMethod = null; // Ou maneje conforme necessário para métodos desconhecidos
    }

    if (apiShippingMethod) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        shippingMethod: apiShippingMethod,
      }));
    }

    setOrder((prevOrder) => ({ ...prevOrder, status: "ready" }));
  };

  const handleCreditCardNumberChange = (e) => {
    formatCreditCardNumber(e.target.value);
  };

  const formatCreditCardNumber = (number) => {
    // Your existing logic here
    let onlyNums = number.replace(/[^\d]/g, "");
    setValue("number", onlyNums);
    setValue(
      "CreditCardNumber",
      onlyNums.replace(/(\d{4})(?=\d)/g, "$1 ").trim()
    );
    setValue("flag", detectCardFlag(number));
  };

  const detectCardFlag = (cardNumber) => {
    // Limpando o número do cartão para conter apenas dígitos
    const digits = cardNumber.replace(/\D/g, "");

    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(digits)) {
      return "visa";
    } else if (/^5[1-5][0-9]{14}$/.test(digits)) {
      return "mastercard";
    } else if (/^3[47][0-9]{13}$/.test(digits)) {
      return "amex";
    } else {
      return ""; // Retornar vazio ou algum valor padrão se não for identificado
    }
  };

  const setPaymentMethod = (value) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      paymentDetails: {
        ...prevOrder.paymentDetails,
        paymentMethod: value,
      },
    }));
    setValidate((prevValidate) => ({
      ...prevValidate,
      pMethod: true,
    }));
  };

  const triggerFormSubmit = () => {
    handleSubmit(onSubmit)(); // Note the double invocation
  };

  function getInstallmentOptions(total) {
    if (total < 179) {
      return [1, 2, 3, 4].map((n) => ({
        value: parseInt(n, 10),
        label: `${n}x sem juros`,
      }));
    } else if (total <= 1000) {
      return [1, 2, 3, 4, 5, 6].map((n) => ({
        value: parseInt(n, 10),
        label: `${n}x sem juros`,
      }));
    } else {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => ({
        value: parseInt(n, 10),
        label: `${n}x sem juros`,
      }));
    }
  }

  const installmentOptions = getInstallmentOptions(total);

  useEffect(() => {
    const selectedData = shippingInfo.find(
      (data) => data.id === selectedFreteId
    );
    if (selectedData) {
      setselectedRate(selectedData);
      setValidate((prevValidate) => ({
        ...prevValidate,
        frete: true,
      }));
    }
  }, [selectedFreteId, shippingInfo]); // Inclua shippingInfo nas dependências para reagir às suas mudanças também.

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  function FreteInfo(shippingData) {
    const isCompany = user.isCompany;
    return (
      <ul className="divide-y divide-gray-200">
        {shippingData
          ?.filter(
            (shippingOption) =>
              shippingOption.price != null &&
              shippingOption.price !== "" &&
              shippingOption.company.name === "Correios" &&
              shippingOption.name !== "PAC Centralizado" &&
              shippingOption.name !== "SEDEX Centralizado" &&
              shippingOption.name !== "Mini Centralizado"
          )
          .map((shippingOption) => (
            <li
              key={shippingOption.id}
              className={`py-4 px-4 rounded-sm ${
                selectedFreteId === shippingOption.id
                  ? "bg-blue-200"
                  : "bg-white"
              } cursor-pointer hover:bg-blue-100`}
              onClick={() => {
                setShippingSelected(shippingOption);
                selectFrete(shippingOption.id);
                setOrder((prevOrder) => ({
                  ...prevOrder,
                  shippingTime: shippingOption.delivery_time
                    ? shippingOption.delivery_time
                    : 0,
                }));
              }}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3
                    className={`text-sm font-medium ${
                      selectedFreteId === shippingOption.id
                        ? "text-blue-500"
                        : "text-gray-700"
                    }`}
                  >
                    {shippingOption.name ?? "N/A"} -{" "}
                    {shippingOption.company?.name ?? "N/A"}
                  </h3>
                  <p className="text-xs text-gray-500">
                    Tempo de entrega: {shippingOption.delivery_time ?? "N/A"}{" "}
                    dias
                  </p>
                </div>
                <div className="text-right">
                  <p
                    className={`text-sm font-semibold ${
                      selectedFreteId === shippingOption.id
                        ? "text-blue-600"
                        : "text-gray-900"
                    }`}
                  >
                    {shippingOption.name === "PAC"
                      ? (isCompany && subtotal <= 399) ||
                        (!isCompany && subtotal <= 199)
                        ? "Frete Grátis"
                        : formatCurrency(shippingOption.price)
                      : formatCurrency(shippingOption.price)}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col md:flex-row w-full justify-evenly max-w-[1200px] px-auto">
      <div className="flex-col max-w-lg w-full">
        <div className="max-h-[20%] mx-auto mt-2 bg-[#dff0d8] rounded-md p-4 text-center">
          <p>
            <span>Ganhe</span>{" "}
            <span className="text-[#00a470] font-bold">5%</span>{" "}
            <span>de desconto pagando via</span>{" "}
            <span className="text-[#00a470] font-bold">PIX!</span>
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 p-6 bg-white rounded shadow">
            <h2 className="text-xl font-semibold mb-4">Pagamento</h2>
            {/* Radio Buttons for Payment Method */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 mb-4">
              {["CREDIT_CARD", "BANK_SLIP", "PIX"].map((method) => (
                <label
                  key={method}
                  className="inline-flex items-center cursor-pointer p-2 rounded-md"
                >
                  <input
                    {...register("paymentMethod")}
                    type="radio"
                    value={method}
                    className="sr-only" // hide the default radio button
                    onChange={() => {
                      setValue("paymentMethod", method);
                      setPaymentMethod(method);
                    }}
                  />
                  <span
                    className={`px-4 py-2 ${
                      watch("paymentMethod") === method
                        ? "bg-black text-white hover:bg-gray-900"
                        : "text-gray-900 bg-gray-300"
                    } rounded-md transition-colors duration-200 ease-in-out`}
                  >
                    {method === "CREDIT_CARD" && "Cartão de Crédito"}
                    {method === "BANK_SLIP" && "Boleto"}
                    {method === "PIX" && "Pix"}
                  </span>
                </label>
              ))}
            </div>

            {pMethod === "CREDIT_CARD" && (
              <div className="w-full">
                {/* Credit Card Number */}
                <div className="mb-4 w-full">
                  <label className="block mb-2">Número do cartão</label>
                  <div className="flex items-center">
                    <div className="flex-col w-full">
                      <input
                        {...register("CreditCardNumber", {
                          required: "Número do cartão é obrigatório",
                          onChange: (e) => handleCreditCardNumberChange(e),
                        })}
                        type="text"
                        className="form-input w-full px-3 py-2 border rounded"
                        placeholder="0000 0000 0000 0000"
                        maxLength="19"
                      />
                      {errors.CreditCardNumber?.message && (
                        <p className="text-red">
                          {errors.CreditCardNumber.message}
                        </p>
                      )}
                    </div>
                    {vFlag && (
                      <div>
                        {(() => {
                          switch (vFlag) {
                            case "visa":
                              return (
                                <FaCcVisa className="w-12 h-12 ml-10 text-black" />
                              );
                            case "mastercard":
                              return (
                                <FaCcMastercard className="w-12 h-12 ml-10 text-black" />
                              );
                            case "amex":
                              return (
                                <FaCcAmex className="w-12 h-12 ml-10 text-black" />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </div>
                    )}
                  </div>
                </div>
                {/* Cardholder Name */}
                <div className="mb-4">
                  <label className="block mb-2">Nome no cartão</label>
                  <input
                    {...register("holderName", {
                      required: "Nome do Dono do cartão é obrigatório",
                    })}
                    type="text"
                    className="form-input w-full px-3 py-2 border rounded"
                    placeholder="NOME COMPLETO"
                  />
                  {errors.holderName?.message && (
                    <p className="text-red">{errors.holderName.message}</p>
                  )}
                </div>
                {/* Campo de Seleção de Parcelamento */}
                <div className="mb-4">
                  <label htmlFor="installment" className="block mb-2">
                    Parcelamento
                  </label>
                  <select
                    {...register("paymentInfo.installmentCount")}
                    id="installment"
                    className="form-select block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  >
                    {installmentOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Expiry Date */}
                <div className="flex flex-col justify-between">
                  <div className="mb-4 w-1/2 mr-2">
                    <label className="block mb-2">
                      Data de expiração (MM/AA)
                    </label>
                    <div className="flex">
                      <div className="flex-col">
                        <div className="flex">
                          <input
                            {...register("expiryMonth", {
                              required: "O mês é obrigatório",
                              validate: {
                                isNumber: (value) =>
                                  !isNaN(value) && value !== "",
                                isValidMonth: (value) =>
                                  parseInt(value) >= 1 && parseInt(value) <= 12,
                                isNotPast: (value) => {
                                  const expiryYear = getValues("expiryYear");
                                  const yearComparison =
                                    parseInt(expiryYear, 10) - currentYear;

                                  // If the expiry year is in the future, the month is automatically valid.
                                  if (yearComparison > 0) {
                                    return true;
                                  }

                                  // If the expiry year is the current year, check the month.
                                  if (yearComparison === 0) {
                                    return (
                                      parseInt(value, 10) >= currentMonth ||
                                      "Mês vencido!"
                                    );
                                  }

                                  // If the expiry year is in the past, the card is expired regardless of the month.
                                  if (yearComparison < 0) {
                                    return "Ano vencido!";
                                  }
                                },
                              },
                            })}
                            type="text"
                            className="form-input px-3 py-2 border rounded"
                            placeholder="MM"
                            maxLength="2"
                          />

                          <span className="mx-2 mt-2">/</span>
                        </div>
                        {/* Display the error message for expiryMonth if there is any */}
                        {errors.expiryMonth?.message && (
                          <p className="text-red">
                            {errors.expiryMonth.message}
                          </p>
                        )}
                      </div>
                      <div className="flex-col">
                        <div className="flex">
                          <input
                            {...register("expiryYear", {
                              required: "O ano é obrigatório",
                              validate: {
                                isNumber: (value) =>
                                  !isNaN(value) && value !== "",
                                isNotPast: (value) => {
                                  // Ensure year is not in the past
                                  if (parseInt(value) < currentYear) {
                                    return "Ano vencido!";
                                  }
                                  // If the year is this year, ensure the month is not in the past
                                  if (parseInt(value) === currentYear) {
                                    const expiryMonth = getValues("expiryYear");
                                    if (
                                      expiryMonth &&
                                      parseInt(expiryMonth) < currentMonth
                                    ) {
                                      return "Mês vencido!";
                                    }
                                  }
                                  return true;
                                },
                              },
                            })}
                            type="text"
                            className="form-input px-3 py-2 border rounded"
                            placeholder="AA"
                            maxLength="2"
                          />
                        </div>
                        {errors.expiryYear?.message && (
                          <p className="text-red">
                            {errors.expiryYear.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* CVC */}
                  <div className="mb-4 w-1/4">
                    <label className="block mb-2">CVC</label>
                    <input
                      {...register("ccv", {
                        required: "CVC é obrigatório",
                        validate: {
                          isNumber: (value) =>
                            !isNaN(value) && value.trim().length === 3,
                        },
                      })}
                      type="text"
                      className="form-input w-full px-3 py-2 border rounded"
                      placeholder="CVC"
                      maxLength="3"
                    />
                    {errors.ccv?.message && (
                      <p className="text-red">{errors.ccv.message}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {pMethod === "BANK_SLIP" && (
              <div className="max-w-lg">
                <h3 className="text-sm font-semibold mb-1">
                  instruções de pagamento via Boleto
                </h3>
                <ol className="list-decimal list-inside p-4 text-sm">
                  <li>
                    Clique no botão "Confirmar Pedido" para confirmar seu pedido
                    e imprimir seu boleto.
                  </li>
                  <li>
                    Imprima o boleto e pague na agência bancária de sua
                    preferência ou através dos serviços de Internet Banking.
                  </li>
                  <li>
                    Acompanhe o prazo de validade do seu boleto, ele não será
                    enviado pelos Correios.
                  </li>
                </ol>
              </div>
            )}

            {pMethod === "PIX" && (
              <div className="max-w-lg">
                <h3 className="text-sm font-semibold mb-1">
                  Instruções de pagamento via Pix
                </h3>
                <ol className="list-decimal list-inside p-4 text-sm">
                  <li>Clique em "Confirmar Pedido";</li>
                  <li>Na tela seguinte, copie o código;</li>
                  <li>
                    No App do seu banco, selecione a opção Pagar com o Pix e
                    cole o código que você copiou;
                  </li>
                  <li>Confira os dados e confirme o pagamento;</li>
                  <li>Pronto, o pagamento foi feito com sucesso!</li>
                </ol>
              </div>
            )}
          </div>
        </form>
        <div className="my-4 p-6 bg-white rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Selecione o seu Frete:</h2>
          {shippingInfo?.length > 0 && FreteInfo(shippingInfo)}
        </div>
      </div>
      <ResumeCard
        order={order}
        isCompany={user.isCompany}
        shippingSelected={shippingSelected}
        subtotal={subtotal}
        total={total}
        cupom={cupom}
        validate={validate}
        desconto={desconto}
        underSend={underSend}
        setDesconto={setDesconto}
        setCupomApply={setCupomApply}
        cupomApply={cupomApply}
        triggerFormSubmit={triggerFormSubmit}
        pixDesc={pixDesc}
      />
    </div>
  );
};

export default PaymentForm;
